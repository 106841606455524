import React from "react";

import ArtileEl, {
	Pink,
	White,
	Desc,
	Header,
	CustomImage,
} from "../../components/Elements/ArticleEl";

import cases from "../cases";

const componentName = "ShowcaseVirtualInfluencer";

const shorthand = cases.find((x) => x.component == componentName).shorthand;

if (typeof shorthand == "undefined")
	console.error("Component can't find shorthand: " + componentName);
export default () => (
	<>
		<div className="section-div" style={{}}>
			<div className="section-content">
				<div className="section-hero">
					<div
						className="section-title-sub grad-txt"
						style={{ paddingLeft: 0, paddingRight: 0 }}
					>
						"Influencer marketing meets the digital age"
					</div>
				</div>
			</div>
		</div>
		<div className="section-div no-p">
			<div
				className="section-img"
				style={{
					// maxWidth: "1920px",
					paddingTop: "0px",
					marginTop: "0px",
				}}
			>
				<img
					src={`/img/cases/${shorthand}/image/img_01.jpg`}
					style={{ marginTop: 40, borderRadius: 15, width: "100%" }}
				/>
			</div>
		</div>
		<div
			className="section-div"
			style={{
				// maxWidth: "1920px",
				// paddingTop: "0.8em",
				paddingTop: 0,
			}}
		>
			<div className="section-content">
				<div className="section-description">
					Virtual influencers are the future of marketing and are set
					to take the fashion and beauty industry by storm. These
					digital avatars, have the potential to become the ultimate
					trendsetters, tastemakers, and style icons of the next
					generation.
					<br />
					<br />
					They offer a unique opportunity to drive brand awareness and
					increase sales.
				</div>
			</div>
		</div>
		<div className="section-div">
			<div className="section-content">
				<div className="section-hero">
					<div className="section-title-sub grad-txt">
						Unreal Engine.
						<br />
						Real-time.
						<br />
						Social Media.
					</div>
				</div>
				<div className="section-description">
					By utilizing Unreal Engine we overcome the current
					limitations of content creation and produce stunning visuals
					quickly and efficiently, without the need for time-consuming
					rendering processes. <br />
					<br />
					Immediate feedback and adjustments, leading to faster
					project completion and turnaround times. This empowers
					brands to easily participate into the latest social trends
					and be the hype makers of the next-generation.
				</div>
			</div>
			<div
				className="section-img"
				style={{
					// maxWidth: "1920px",
					paddingTop: "0px",
					marginTop: "0px",
				}}
			>
				<img
					src={`/img/cases/${shorthand}/image/img_02.jpg`}
					style={{ marginTop: 40, borderRadius: 15, width: "100%" }}
				/>
			</div>
		</div>
	</>
);
