import React, { useRef, useState } from "react";

import { LandingPageMemo } from "./LandingPage";
import Universe from "./components/Elements/Universe";
import VideoModal from "./components/VideoModal";
import BtnParallax from "./components/Elements/BtnParallax";
import VideoElement from "./components/Elements/VideoElement";

import { isMobile } from "react-device-detect";

import useOnScreen from "./hooks/useOnScreen";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { gsap } from "gsap";

import welcomeVideoData from "./data/welcome-video";

import { useEffectOnce } from "./hooks/useEffectOnce";
import { sendGACustomEvent } from "./hooks/googleAnalytics";

import { White } from "./components/Elements/ArticleEl";

import "./webflow/home.css";

const Home = React.forwardRef((props, ref) => {
	const coverVideoRef = useRef(null);
	const videoModalRef = useRef(null);
	const isCoverVideoVisible = useOnScreen(coverVideoRef);
	let location = useLocation();

	const curWelcomeVideoData = useState(
		welcomeVideoData.find(
			(x) => x.shorthand === location?.pathname.replaceAll("/", "")
		) || welcomeVideoData.find((x) => x.shorthand === "default")
	);

	const handleChangeHeaderState = (newState, curCase = null) => {
		//console.log(curCase?.name);

		switch (newState) {
			case "typo":
				props.changeHeaderState("typo");
				break;
			case "player":
				props.changeHeaderState("logo", {
					hideNav: true,
					customBack: {
						icon: isMobile ? "cross" : "cross",
						text: " ",
						fn: () => {
							handleVideoModalOpen(false);
						},
					},
				});
				break;
		}
	};

	const handleVideoModalOpen = (bool) => {
		if (!videoModalRef.current?.open) return;

		videoModalRef.current.open(bool, () => {
			handleVideoModalOpen(false);
		});

		if (bool) {
			handleChangeHeaderState("player");
			sendGACustomEvent(
				props.globalContext,
				"fs_welcome",
				curWelcomeVideoData?.[0]?.["name"]
			);
		} else {
			handleChangeHeaderState("typo");
			sendGACustomEvent(
				props.globalContext,
				"fe_welcome",
				curWelcomeVideoData?.[0]?.["name"]
			);
		}
	};

	useEffectOnce(() => {
		handleChangeHeaderState("typo");
	});

	return (
		<>
			<div className="page-content scroll">
				<div
					className="landing-page scroll"
					ref={coverVideoRef}
					style={{
						visibility: isCoverVideoVisible ? "visible" : "hidden",
					}}
				>
					<LandingPageMemo
						key={props.cKey + "-landing"}
						currentPathname={props.currentPathname}
						window={props.window}
						initLoad={props.initLoad}
						changeCursorState={props.changeCursorState}
						changeHeaderState={props.changeHeaderState}
						closeSearch={props.closeSearch}
						isSearchOpen={props.isSearchOpen}
						changeView={props.changeView}
						appView={props.appView}
						deviceType={props.deviceType}
						forcehold={!isCoverVideoVisible}
						ref={ref}
					/>
				</div>

				<div className="landing-scroll-el">
					<div className="section-wrapper">
						<div className="section-div intro">
							<div
								id="welcome-video"
								style={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									width: "100%",
								}}
							>
								<div className="section-content">
									<div className="section-hero intro-headline">
										The real-time animation studio.
										<br />
									</div>
									<div className="section-tag-container">
										<div className="slider-tag-new">
											<div className="slider-tag-new-txt">
												Unreal Engine
											</div>
										</div>
										<div className="slider-tag-new">
											<div className="slider-tag-new-txt">
												Motion Capture
											</div>
										</div>
										<div className="slider-tag-new">
											<div className="slider-tag-new-txt">
												Real-time
											</div>
										</div>
										<div className="slider-tag-new no-bg">
											<div className="slider-tag-new-txt">
												#Advertising
											</div>
										</div>
										<div className="slider-tag-new no-bg">
											<div className="slider-tag-new-txt">
												#Social
											</div>
										</div>
										<div className="slider-tag-new no-bg">
											<div className="slider-tag-new-txt">
												#Events
											</div>
										</div>
										<div className="slider-tag-new no-bg">
											<div className="slider-tag-new-txt">
												#Automotive
											</div>
										</div>
										<div className="slider-tag-new no-bg">
											<div className="slider-tag-new-txt">
												#Concert
											</div>
										</div>
										<div className="slider-tag-new no-bg">
											<div className="slider-tag-new-txt">
												#VirtualCamera
											</div>
										</div>
									</div>
								</div>
								<div
									className="section-img"
									id="welcome-video"
									style={{ pointerEvents: "all" }}
									onClick={handleVideoModalOpen}
								>
									<VideoElement
										round
										src={
											curWelcomeVideoData[0][
												"landscape-pr-720p"
											]
										}
										poster={`/cases/welcome-video/poster/${curWelcomeVideoData[0]["poster-landscape-1600p"]}.jpg`}
									/>
									<div className="section-vid-overlay-play">
										<div className="vid-btn-play" />
									</div>
								</div>
							</div>
						</div>
						<div className="section-div no-p">
							<div className="section-content">
								<div className="section-description">
									<White>
										Stunnıng. is a Hamburg-based virtual
										production studio.
									</White>{" "}
									Focusing on real-time content creation, we
									use the latest and most extraordinary powers
									of AI, Machine Learning, and Real-time
									graphics to make human creativity shine. Our
									approach is to create a collaborative and
									creative working environment by reducing
									turn-around times.
								</div>
							</div>
						</div>

						<div className="section-div">
							<div className="section-content">
								<div className="section-hero">
									<div className="section-title-sub grad-txt">
										Stunningly modular.
										<br />
										Remarkably cost-effective.
										<br />
										Increasingly sustainable.
										<br />
									</div>
								</div>
								<div className="section-description">
									We believe in empowering brands and
									marketers to explore their content needs
									without risks. Our modular approach ensures
									that you get the most value for your
									investment and enables you to instantly
									participate on all social channels and
									engage your audience.
								</div>
							</div>
							<div
								className="section-img"
								style={{
									// maxWidth: "1920px",
									paddingTop: "0px",
									marginTop: "0px",
								}}
							>
								<div className="article-video-cnt">
									<VideoElement
										src={
											"https://player.vimeo.com/progressive_redirect/playback/804480659/rendition/720p/file.mp4?loc=external&signature=b4185203cdb375e4566096dc2a0df57ad7c70730361d8e7eef1f52a38d0480cf"
										}
									/>

									<div
										className="article-video-overlay"
										style={{
											position: "absolute",
											width: "100%",
											height: "100%",

											top: 0,
										}}
									></div>
								</div>
							</div>
						</div>

						<div className="section-div grow-universe">
							<div className="section-content">
								<div className="section-hero">
									<div className="section-title-sub grad-txt">
										A content pipeline that scales with you.
									</div>
								</div>
							</div>
						</div>
						<div
							className="section-div full-width"
							style={
								{
									// maxWidth: "1920px",
									// paddingTop: "0.8em",
								}
							}
						>
							<Universe />
						</div>
						<div
							className="section-div"
							style={{
								// maxWidth: "1920px",
								// paddingTop: "0.8em",
								paddingTop: 0,
							}}
						>
							<div className="section-content">
								<div className="section-description">
									Every new product or campaign asset builds
									up your library and enables you to grow your
									very own brand universe. Engage your
									audience with seasonal packs or custom
									activations. Ranging from classical
									advertising to AR-VR-XR and live
									experiences, we can offer you a wide range
									of executions. <br />
									<br />
									By leveraging your pre-existing assets and
									augmenting them with fresh functionalities,
									you can foster sustainability in both
									production and perception. This approach
									allows you to create a memorable impact on
									your audience, expanding your reach to
									existing and new target groups.
								</div>
							</div>
						</div>
						<div className="section-div bigger">
							<div className="router-wrapper">
								<div className="router-row">
									<div className="router-item left">
										<div className="section-description router">
											Learn more
										</div>
										<div className="section-title-sub router">
											Get an insight into the solutions we
											offer.
											<br />
										</div>

										<Link
											to={{
												pathname: "/cases/solutions",
											}}
											state={{
												from: location?.pathname || "/",
											}}
											style={{
												pointerEvents: "auto",
											}}
										>
											<BtnParallax text={"Solutions"} />
										</Link>

										<div className="section-router-spacer" />
										<div className="router-img" />
									</div>
									<div className="router-item right">
										<div className="section-description router">
											See more
										</div>
										<div className="section-title-sub router">
											Lean back and explore our work.
										</div>

										<Link
											to={{
												pathname: "/cases/work",
											}}
											state={{
												from: location?.pathname || "/",
											}}
											style={{
												pointerEvents: "auto",
											}}
										>
											<BtnParallax text={"Work"} />
										</Link>

										<div className="section-router-spacer" />
										<div className="router-img work" />
									</div>
								</div>
								<div className="router-item full">
									<div className="section-description router">
										Let's talk
									</div>
									<div className="section-title-sub router">
										Ready to dive in?
									</div>

									<Link
										to={{
											pathname: "/get-in-touch",
										}}
										state={{
											from: location?.pathname || "/",
										}}
										style={{
											pointerEvents: "auto",
										}}
									>
										<BtnParallax text={"Get in Touch"} />
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<VideoModal
				ref={videoModalRef}
				changeCursorState={props.changeCursorState}
				src={curWelcomeVideoData[0]["film-1080p"]}
			/>
		</>
	);
});

export default React.memo(Home);
