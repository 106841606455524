import React from "react";

import ArtileEl, {
	Pink,
	White,
	Desc,
	Header,
	CustomImage,
} from "../../components/Elements/ArticleEl";

import cases from "../cases";

const componentName = "KontraKNextToYou";

const shorthand = cases.find((x) => x.component == componentName).shorthand;

if (typeof shorthand == "undefined")
	console.error("Component can't find shorthand: " + componentName);
export default () => (
	<>
		<div className="section-div" style={{}}>
			<div className="section-content">
				<div className="section-hero">
					<div
						className="section-title-sub grad-txt"
						style={{ paddingLeft: 0, paddingRight: 0 }}
					>
						"A True Detective music video game"
					</div>
				</div>
			</div>
		</div>
		<div className="section-div no-p">
			<div
				className="section-img"
				style={{
					// maxWidth: "1920px",
					paddingTop: "0px",
					marginTop: "0px",
				}}
			>
				<img
					src={`/img/cases/${shorthand}/image/img_01.jpg`}
					style={{ marginTop: 40, borderRadius: 15, width: "100%" }}
				/>
			</div>
		</div>
		<div
			className="section-div"
			style={{
				// maxWidth: "1920px",
				// paddingTop: "0.8em",
				paddingTop: 0,
			}}
		>
			<div className="section-content">
				<div className="section-description">
					Kontra K's "Next to You" is a captivating interactive music
					video that immerses viewers into the scene of a crime, where
					they can investigate alongside a fisherman to uncover what
					happened the night before. The experience is designed to
					engage viewers from the outset; with just a tap of the
					screen on their mobile device or a press of the spacebar on
					their computer, they are drawn into the story and able to
					switch between the past and present, as if using a time
					machine.
				</div>
			</div>
		</div>
		<div className="section-div">
			<div className="section-content">
				<div className="section-hero">
					<div className="section-title-sub grad-txt">
						Award winning.
						<br />
						21.9 Mio views.
						<br />
						Accessible on all devices.
					</div>
				</div>
				<div className="section-description">
					We crafted the creative concept, film-directed both
					storylines, and created the initial tech approach including
					a functional prototype.
					<br />
					<br />
					Kontra K was joined by a highly talented international cast
					and filmed in a prestigious location previously featured in
					the James Bond movie "Skyfall" to craft a visually stunning
					and emotionally resonant narrative. <br />
					<br />
					As the centerpiece of Kontra K's album release, the video
					generated a significant impact, captivating fans and new
					audiences with its innovative and compelling storytelling.
					The interactive nature of the experience creates a powerful
					emotional connection with the audience, making it an
					unforgettable and unique addition to the music video genre.
				</div>
			</div>
			<div
				className="section-img"
				style={{
					// maxWidth: "1920px",
					paddingTop: "0px",
					marginTop: "0px",
				}}
			>
				<img
					src={`/img/cases/${shorthand}/image/img_02.jpg`}
					style={{ marginTop: 40, borderRadius: 15, width: "100%" }}
				/>
			</div>
		</div>
	</>
);
