import React, {
	useRef,
	useEffect,
	useState,
	useContext,
	useImperativeHandle,
} from "react";
import { gsap } from "gsap";
const items = [
	[
		{ color: "purple" },
		{ color: "black" },
		{ color: "purple" },
		{ color: "purple" },
		{ color: "black" },
		{ color: "pink" },
		{ color: "black" },
		{ color: "purple" },
		{ color: "purple" },
		{ color: "black" },
		{ color: "purple" },
	],
	[
		{ color: "purple" },
		{ color: "black" },
		{ color: "purple" },
		{ color: "black" },
		{ color: "pink" },
		{ color: "pink" },
		{ color: "black" },
		{ color: "purple" },
		{ color: "black" },
		{ color: "purple" },
	],
	[
		{ color: "purple" },
		{ color: "black" },
		{ color: "purple" },
		{ color: "black" },
		{ color: "pink" },
		{ color: "pink" },
		{ color: "pink" },
		{ color: "black" },
		{ color: "purple" },
		{ color: "black" },
		{ color: "purple" },
	],
	[
		{ color: "purple" },
		{ color: "black" },
		{ color: "purple" },
		{ color: "black" },
		{ color: "pink" },
		{ color: "pink" },
		{ color: "black" },
		{ color: "purple" },
		{ color: "black" },
		{ color: "purple" },
	],
	[
		{ color: "purple" },
		{ color: "black" },
		{ color: "purple" },
		{ color: "purple" },
		{ color: "black" },
		{ color: "pink" },
		{ color: "black" },
		{ color: "purple" },
		{ color: "purple" },
		{ color: "black" },
		{ color: "purple" },
	],
];

const frameRate = 2;
const minScale = 0.3;
const maxScale = 1.1;
const scaleRange = maxScale - minScale;
let lastFrame;

const dynamicSort = (property) => {
	let sortOrder = 1;

	if (property[0] === "-") {
		sortOrder = -1;
		property = property.substr(1);
	}

	return function (a, b) {
		let result =
			a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
		return result * sortOrder;
	};
};

const Universe = React.forwardRef((props, ref) => {
	const universeContainer = useRef(null);
	const universeWrapper = useRef(null);
	const centerItemRef = useRef(null);

	const childrenRef = useRef(null);

	useEffect(() => {
		const rows = universeWrapper.current.children;

		let children = [];
		rows.forEach((row) => {
			Array.from(row.children).forEach((item) => {
				children.push(item);
			});
		});

		childrenRef.current = children;
		resetUniverse(true);
	}, []);

	const animateChildren = (origin) => {
		const rows = universeWrapper.current.children;

		let children = childrenRef.current;
		// rows.forEach((row) => {
		// 	Array.from(row.children).forEach((item) => {
		// 		children.push(item);
		// 	});
		// });
		const childrenWithDistances = [];

		children.map((child) => {
			const r = child.getBoundingClientRect();
			const childX = r.right - r.width / 2;
			const childY = r.bottom - r.height / 2;
			const distanceY =
				Math.max(origin.y, childY) - Math.min(origin.y, childY);
			const distanceX =
				Math.max(origin.x, childX) - Math.min(origin.x, childX);
			const hypot = Math.hypot(distanceX, distanceY);

			child.distance = Math.round(hypot);

			childrenWithDistances.push(child);
		});

		childrenWithDistances.sort(dynamicSort("distance")).reverse();

		childrenWithDistances.map((child, index) => {
			const relativeAmt = (index / children.length) * scaleRange;
			child.style.setProperty("--scale", minScale + relativeAmt);
		});
	};

	const UniverseItem = (props) => {
		const isCenterItem = props.row == 2 && props.item == 5;
		return (
			<div
				className="universe-item"
				style={{
					// backgroundColor: `${
					// 	{
					// 		purple: "#935DEA",
					// 		pink: "#F10388",
					// 		black: "#2E2E2E",
					// 	}[props.color]
					// }`,

					backgroundPosition:
						props.row > 0 && props.row < 4
							? `${props.item * 10}% ${(props.row - 1) * 50}%, ${
									{
										purple: "100% 0%",
										pink: isCenterItem
											? `${100 / 3}% 0%`
											: "0% 0%",
										black: `${(100 / 3) * 2}% 0%`,
									}[props.color]
							  }`
							: `-10% -10%, ${
									{
										purple: "100% 0%",
										pink: isCenterItem
											? `${100 / 3}% 0%`
											: "0% 0%",
										black: `${(100 / 3) * 2}% 0%`,
									}[props.color]
							  }`,
				}}
				ref={isCenterItem ? centerItemRef : null}
			/>
		);
	};

	const handleMouseMove = (e) => {
		requestAnimationFrame((thisFrame) => {
			//wrapper.style.transition = ".4s ease-out";
			// e = Mouse click event.
			var rect = universeContainer.current.getBoundingClientRect();
			var x = 0;
			var y = 0;

			let isTouch = false;
			if (
				e.type == "touchstart" ||
				e.type == "touchmove" ||
				e.type == "touchend" ||
				e.type == "touchcancel"
			) {
				var touch =
					e.nativeEvent.touches[0] || e.nativeEvent.changedTouches[0];
				x = touch.pageX;
				y = touch.pageY;
				isTouch = true;
			} else if (
				e.type == "mousedown" ||
				e.type == "mouseup" ||
				e.type == "mousemove" ||
				e.type == "mouseover" ||
				e.type == "mouseout" ||
				e.type == "mouseenter" ||
				e.type == "mouseleave"
			) {
				x = e.clientX;
				y = e.clientY;
			}

			if (thisFrame - lastFrame > frameRate) {
				const screenCenter = {
					x: rect.width / 2,
					y: rect.height / 2,
				};
				const moveX = x - rect.left - screenCenter.x;
				const moveY = y - rect.top - screenCenter.y;

				const moveYPercent = moveY / rect.height;
				const moveXPercent = moveX / rect.width;

				// universeWrapper.current.style.setProperty(
				// 	"--x",
				// 	moveX / 10 + "%"
				// );
				// universeWrapper.current.style.setProperty(
				// 	"--y",
				// 	moveYPercent * 50 + "%"
				// );

				gsap.to(universeWrapper.current, {
					yPercent: moveYPercent * (isTouch ? 0 : -25),
					xPercent: moveXPercent * (isTouch ? -23 : -23),
				});
				const origin = {
					x: x,
					y: y,
				};
				animateChildren(origin);
			}

			lastFrame = thisFrame;
		});
	};

	const handleMouseLeave = (e) => {
		requestAnimationFrame((thisFrame) => {
			resetUniverse();
		});
	};

	const handleMouseEnter = (e) => {
		gsap.killTweensOf(universeWrapper.current);
	};

	const resetUniverse = (instant = false) => {
		var rect = centerItemRef.current.getBoundingClientRect();
		const origin = {
			x: rect.left + rect.width / 2,
			y: rect.top + rect.height / 2,
		};

		animateChildren(origin);

		gsap.killTweensOf(universeWrapper.current);
		gsap.to(universeWrapper.current, {
			duration: instant ? 0 : 2,
			yPercent: 0,
			xPercent: 0,
		});
	};

	return (
		<div
			className="universe-cnt"
			ref={universeContainer}
			onMouseEnter={handleMouseEnter}
			onMouseMove={handleMouseMove}
			onTouchMove={handleMouseMove}
			onTouchEnd={handleMouseLeave}
			onMouseLeave={handleMouseLeave}
		>
			<div className="universe-wrapper" ref={universeWrapper}>
				<div className="first-row">
					{items[0].map((item, i) => (
						<UniverseItem
							key={"uni-item-0-" + i}
							row={0}
							item={i}
							color={item.color}
						/>
					))}
				</div>
				<div className="second-row">
					{items[1].map((item, i) => (
						<UniverseItem
							key={"uni-item-1-" + i}
							row={1}
							item={i}
							color={item.color}
						/>
					))}
				</div>
				<div className="third-row">
					{items[2].map((item, i) => (
						<UniverseItem
							key={"uni-item-2-" + i}
							row={2}
							item={i}
							color={item.color}
						/>
					))}
				</div>
				<div className="fourth-row">
					{items[3].map((item, i) => (
						<UniverseItem
							key={"uni-item-3-" + i}
							row={3}
							item={i}
							color={item.color}
						/>
					))}
				</div>
				<div className="fith-row">
					{items[4].map((item, i) => (
						<UniverseItem
							key={"uni-item-4-" + i}
							row={4}
							item={i}
							color={item.color}
						/>
					))}
				</div>
			</div>
		</div>
	);
});

export default React.memo(Universe);
