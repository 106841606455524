import React, {
	useEffect,
	useLayoutEffect,
	useRef,
	useState,
	useImperativeHandle,
} from "react";

import { gsap } from "gsap";

import { Expo } from "gsap/all";
import { FpsView } from "react-fps";
import SolutionSlide from "./components/SolutionSlide";
import FullscreenSolution from "./components/FullscreenSolution";
import BtnVideoSelectorParallax from "./components/Elements/BtnVideoSelectorParallax";
import { useParams } from "react-router";
import caseData from "./data/cases";
import { useNavigate } from "react-router-dom";
import { isMobile, isTablet } from "react-device-detect";
import VideoModal from "./components/VideoModal";
import BtnParallax from "./components/Elements/BtnParallax";
import { useEffectOnce } from "./hooks/useEffectOnce";

import { sendGACustomEvent } from "./hooks/googleAnalytics";
import useLocalStorage from "./hooks/useLocalStorage";

const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
const lerp = (f0, f1, t) => {
	return (1 - t) * f0 + t * f1;
};

const getWindowSize = () => {
	const vw = Math.max(
		document.documentElement.clientWidth || 0,
		window.innerWidth || 0
	);
	const vh = Math.max(
		document.documentElement.clientHeight || 0,
		window.innerHeight || 0
	);
	return { vw: vw, vh: vh };
};

function useTraceUpdate(props) {
	const prev = useRef(props);
	useEffect(() => {
		const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
			if (prev.current[k] !== v) {
				ps[k] = [prev.current[k], v];
			}
			return ps;
		}, {});
		if (Object.keys(changedProps).length > 0) {
			console.log("Changed props:", changedProps);
		}
		prev.current = props;
	});
}
const Solutions = React.forwardRef((props, ref) => {
	const wildcardPath = useParams()["*"];

	const getCaseFromWildcardPath = () => {
		const wildCardSplit = wildcardPath
			.split("/")
			.filter((str) => str !== "");
		const caseFiltered =
			wildCardSplit.length > 0
				? wildCardSplit.length > 1
					? wildCardSplit[1]
					: wildCardSplit[0]
				: "";

		const slideIndex = cases?.[0]?.findIndex(
			(x) => x.shorthand === caseFiltered
		);

		return slideIndex;
	};

	const getFilterFromWildcardSplit = () => {
		const wildCardSplit = wildcardPath
			.split("/")
			.filter((str) => str !== "");

		return wildCardSplit?.[0] || "";
	};

	const validateFilter = (filter) => {
		if (filter === "solution" || filter === "work") {
			return filter;
		} else if (filter === "solutions") {
			const out = filter.slice(0, -1);
			return out;
		}

		return "all";
	};

	const [fullAccess, setFullAccess] = useLocalStorage("full-access", false);
	const [isEnabled, setIsEnabled] = useState(false);

	const getFilteredCases = (filter) => {
		const caseDataPrefilter = {
			// User has limited access, filter out all cases with "full-access"
			false: caseData.filter(
				(caseItem) => caseItem["full-access"] !== "full-access"
			),
			// If user has full-access filter out all cases that have set "none-full-access"
			true: caseData.filter(
				(caseItem) => caseItem["full-access"] !== "none-full-access"
			),
		}[fullAccess];

		if (filter === "all") {
			return caseDataPrefilter.slice(1);
		} else {
			const filteredCases = caseDataPrefilter.filter(
				(x) => x.type === filter
			);
			if (filter == "work") {
				return filteredCases.slice(1);
			} else {
				return filteredCases;
			}
			// return caseData.filter((x) => x.type === filter);
		}
	};

	const [caseFilter, setCaseFilter] = useState(
		validateFilter(getFilterFromWildcardSplit())
	);
	const caseFilterRef = useRef(validateFilter(getFilterFromWildcardSplit()));
	const [cases, setCases] = useState([
		getFilteredCases(caseFilter),
		Date.now(),
	]);
	const refreshCases = () => {
		const filteredCases = getFilteredCases(caseFilter);

		setCases([filteredCases, Date.now()]);
	};

	const setNewFilter = (newFilter) => {
		setCaseFilter(newFilter);
		caseFilterRef.current = newFilter;
	};
	useImperativeHandle(
		ref,
		() => {
			return {
				setFilter(newFilter) {
					setNewFilter(newFilter);
				},
			};
		},
		[]
	);

	const navigate = useNavigate();

	const [preloaded, setPreloaded] = useState(false);
	// const [draggingState, setDraggingState] = useState(false);
	const [activeSlide, setActiveSlide] = useState(-1);

	const nextLiveSlideRef = useRef(-1);
	const [nextLiveSlide, setNextLiveSlide] = useState(-1);

	const activeSlideRef = useRef(-1);

	const activeCase = useRef(null);

	const overflowScrolling = useRef(
		typeof document.body.style["-webkit-overflow-scrolling"] !== "undefined"
	);

	const handleSetActiveCase = (curActiveCase) => {
		activeCase.current = curActiveCase;
	};
	const handleSetActiveSlide = (next) => {
		if (activeSlideRef.current === next) return;
		activeSlideRef.current = next;
		setActiveSlide(next);
		setNextLiveSlide(next);

		if (next === -1) {
			navigate(
				`/cases${
					caseFilterRef.current !== "all"
						? "/" +
						  (caseFilterRef.current === "solution"
								? caseFilterRef.current + "s"
								: caseFilterRef.current)
						: ""
				}`,
				{ replace: true }
			);

			return;
		} else {
			navigate(
				`/cases/${
					caseFilterRef.current !== "all"
						? caseFilterRef.current + "/"
						: ""
				}${cases?.[0]?.[next].shorthand}`,
				{ replace: true }
			);

			// window.history.pushState(
			// 	{},
			// 	undefined,
			// 	`/cases/${
			// 		caseFilterRef.current !== "all"
			// 			? caseFilterRef.current + "/"
			// 			: ""
			// 	}${cases?.[0]?.[next].shorthand}`
			// );
		}
	};

	const [activeVideo, setActiveVideo] = useState(-1);

	const init = useRef(false);
	const initedSlider = useRef(false);

	const [isPortraitMode, setIsPortraitMode] = useState(false);
	const isPortraitModeRef = useRef(null);

	const slideWidthPercentage = useRef(0.3654843);
	const slidesPerPage = useRef(1 / slideWidthPercentage);

	const slideWidth = useRef(-1);
	const wrapWidth = useRef(-1);

	const sliderRef = useRef(null);
	const blackOverlayRef = useRef(null);
	const blackOverlayGradientRef = useRef(null);
	const blackOverlayGradientTopRef = useRef(null);
	const sliderWrapperRef = useRef(null);
	const progressBar = useRef(0);

	const contents = useRef(null);
	const slides = useRef([[], []]);

	//scroll
	const progress = useRef(0);
	const handleProgress = (caller, newProgress) => {
		//console.log("handleProgress: " + caller, newProgress);
		progress.current = newProgress;
	};
	const xProgress = useRef(0);
	const playrate = useRef(0);
	const requestRef = React.useRef();
	const raf = useRef(null);
	const dragging = useRef(false);
	const dragStartTime = useRef(null);
	const dragElapsedTime = useRef(null);

	const onscroll = useRef(false);
	const startX = useRef(0);
	const startY = useRef(0);
	const startProgress = useRef(-1);

	const scrollDeltaYTarget = useRef(0);
	const scrollDeltaYCurrent = useRef(0);

	const startDrag = useRef({ x: 0, y: 0 });

	const lockScroll = useRef(false);

	//click/drag difference
	const wasDrag = useRef(false);
	const wasSearchClose = useRef(false);
	const wheelTimeout = useRef(null);
	const dragDirection = useRef(-1);
	const isLongPress = useRef(false);
	const longPressTimeout = useRef(null);

	//windowSize
	const windowSize = useRef({ vw: -1, vh: -1 });

	//fullscreenText

	const fullscreenText_v2 = useRef(null);

	const [fsTextScrollEnabled, setFsTextScrollEnabled] = useState(false);
	const fsTextScrollEnabledRef = useRef(false);

	const currentOpacityBlackOverlay = useRef(-1);

	//transition fs Txt

	const overlayInTransRef = useRef(false);
	const [overlayInTrans, setOverlayInTrans] = useState(false);

	const overlayCoolDown = useRef(1000);
	const overlayTransitionTimer = useRef(null);
	const fsTxtEnabledTimer = useRef(null);

	//center title
	const titleContainer = useRef(0);

	const titleDragEvent = useRef(null);
	const titleVisible = useRef(false);

	const centerTitleIndex = useRef(null);
	const centerTitleName = useRef(null);

	//interactive hint
	// const hintWrapper = useRef(null);
	// const hintTxt = useRef(null);

	//hover activation
	const isCurrentlyHoveredRef = useRef(false);
	const [isCurrentlyHovered, setIsCurrentlyHovered] = useState(false);
	const hoverTimeout = useRef(null);

	//txtVisibility
	const textVisibility = useRef(false);
	const fullscreenSolutionRef = useRef(null);

	const scrollForMore = useRef(null);
	const scrollForMoreInner = useRef(null);
	const scrollForMoreTl = useRef(null);

	const scrollDirection = useRef(0);
	const prevScroll = useRef(0);

	//autoplay

	const [autoPlay, setAutoplay] = useState(null);

	//scale
	const [curScale, setCurScale] = useState(0);

	const videoModalRef = useRef(null);

	const handleOverlayInTransChange = (inTrans) => {
		overlayInTransRef.current = inTrans;
		setOverlayInTrans(inTrans);
	};
	const handleFsTextScrollEnabledChange = (nextEnabled) => {
		fsTextScrollEnabledRef.current = nextEnabled;
		setFsTextScrollEnabled(fsTextScrollEnabledRef.current);
	};
	const handleVideoModalOpen = (bool) => {
		if (!videoModalRef.current?.open) return;

		videoModalRef.current.open(bool, () => {
			handleVideoModalOpen(false);
			if (!isPortraitModeRef.current) handleSlideExit();
		});

		if (bool) {
			requestAnimationFrame(() => {
				gsap.set(sliderRef.current, {
					autoAlpha: 0,
				});
			});
			handleFsTextScrollEnabledChange(false);
			handleChangeHeaderState("player");
			sendGACustomEvent(
				props.globalContext,
				"fs",
				activeCase?.current?.shorthand
			);
		} else {
			sendGACustomEvent(
				props.globalContext,
				"fe",
				activeCase?.current?.shorthand
			);
			requestAnimationFrame(() => {
				gsap.set(sliderRef.current, { autoAlpha: 1 });
			});
			handleFsTextScrollEnabledChange(true);
			handleChangeHeaderState("active_slide");
		}
	};

	const setSlidePercentage = (mobile) => {
		if (mobile) {
			slideWidthPercentage.current = 1;
		} else {
			slideWidthPercentage.current = 0.3654843;
		}

		slidesPerPage.current = 1 / slideWidthPercentage.current;
	};

	const animate = (time) => {
		if (raf.current) raf.current();
		// The 'state' will always be the initial value here
		requestRef.current = requestAnimationFrame(animate);
	};

	const move = (clampOutput = true, instant = false) => {
		// const lineHeightInitial = txtLineHeight.current * 3 * 1.0;
		if (clampOutput) {
			handleProgress(
				"move calmped",
				clamp(
					progress.current,
					0,
					wrapWidth.current -
						(cases?.[0]?.length < slidesPerPage.current
							? cases?.[0]?.length
							: slidesPerPage.current) *
							slideWidth.current
				)
			);
		}
		if (instant) {
			xProgress.current = progress.current;
			sliderRef.current.style.transform = `translate3d(${-xProgress.current}px,0,0)`;
		}
	};

	const setOnDragging = (bool) => {
		dragging.current = bool;
		// setDraggingState(bool);
	};

	const lastEventTouchStart = useRef(null);
	const lastEventTouchStartTime = useRef(0);

	const handleTouchStart = (e) => {
		let shouldBeHandled = false;
		if (lastEventTouchStart.current !== e.type) {
			var time = Date.now() - lastEventTouchStartTime.current;
			if (time > 250) {
				lastEventTouchStartTime.current = Date.now();
				lastEventTouchStart.current = e.type;
				shouldBeHandled = true;
			}
		} else {
			lastEventTouchStart.current = e.type;
			lastEventTouchStartTime.current = Date.now();
			shouldBeHandled = true;
		}

		if (!shouldBeHandled) {
			e.preventDefault();
			return;
		}

		nextLiveSlideRef.current = -1;
		e.stopImmediatePropagation();

		scrollDeltaYCurrent.current = 0; // stop scroll animation

		wasDrag.current = false;
		wasSearchClose.current = false;
		isLongPress.current = false;
		dragStartTime.current = Date.now();
		setLongPressTimeout();

		dragDirection.current = -1;

		setOnDragging(true);
		onscroll.current = true;
		startProgress.current = progress.current;
		startX.current = e.clientX || e.touches?.[0]?.clientX;
		startY.current = e.clientY || e.touches?.[0]?.clientY;
		startDrag.current = {
			y: e.clientY || e.touches?.[0]?.clientY,
			x: e.clientX || e.touches?.[0]?.clientX,
		};

		let wasSearchBarClick = false;

		e.target.classList?.forEach((x) => {
			if (x.startsWith("searchbar")) wasSearchBarClick = true;
		});

		if (!wasSearchBarClick) {
			if (props.isSearchOpen()) {
				e.preventDefault();
				wasSearchClose.current = true;
			}
			props.closeSearch();
		} else {
			//handleFilterChange();
		}

		const pageX = e.clientX || e.touches?.[0]?.clientX || e.pageX;
		if (pageX > 20 && pageX < windowSize.current.vw - 20) return;
		e.preventDefault();
		// prevent swipe to navigate back gesture
	};

	const handleTouchMove = (e) => {
		e.preventDefault();
		e.stopPropagation();
		// console.log(e.target);

		if (!dragging.current) return false;

		if (dragDirection.current === -1) {
			const threshold = isTextScrolledUp() ? 15 : 15;
			const endX = e.clientX || e.touches?.[0]?.clientX || e.pageX;
			const endY = e.clientY || e.touches?.[0]?.clientY || e.pageY;
			const diffX = endX - startDrag.current.x;
			const diffY = endY - startDrag.current.y;
			const diffXAbs = Math.abs(diffX);
			const diffYAbs = Math.abs(diffY);

			if (diffYAbs > diffXAbs) {
				if (diffYAbs > threshold) {
					dragDirection.current = 1;
				}
			} else {
				if (diffXAbs > threshold) {
					dragDirection.current = 0;
				}
			}
		}

		if (dragDirection.current === -1 || isLongPress.current) {
			return;
		}

		clearLongPressTimeout();

		wasDrag.current = true;

		if (dragDirection.current === 0) {
			// disableDraggable();
			//horizontal swipe

			const x = e.clientX || e.touches?.[0]?.clientX || e.pageX;
			const curDelta = startDrag.current.x - x;
			let deltaMulti = 1;
			if (curDelta < 0) {
				deltaMulti = -1;
			}
			let newProgress = startProgress.current;

			if (isPortraitModeRef.current) {
				refreshBlackOverlay("handleTouchMove - horizontal", 3);
				setSolutionOverlay(null);

				// fadeTitle(true);

				const nextSlide = getNextLiveSlide(true);

				if (nextSlide !== nextLiveSlideRef.current) {
					nextLiveSlideRef.current = nextSlide;
					setNextLiveSlide(nextSlide);
				}

				newProgress =
					startProgress.current +
					deltaMulti *
						clamp(Math.abs(curDelta) * 4, 0, slideWidth.current);
			} else {
				newProgress = startProgress.current + curDelta * 2;
			}

			const progressRight =
				newProgress + slidesPerPage.current * slideWidth.current;
			if (newProgress < 0) {
				newProgress = -100;
			} else if (progressRight > wrapWidth.current) {
				newProgress =
					wrapWidth.current -
					(cases?.[0]?.length < slidesPerPage.current
						? cases?.[0]?.length
						: slidesPerPage.current) *
						slideWidth.current +
					100;
			}
			handleProgress("handleTouchMove", newProgress);
		} else if (dragDirection.current === 1) {
			// 	scrollDeltaYCurrent.current +=
			// 		deltaMulti * clamp(Math.abs(e.deltaY), 0, 200);
			// }

			if (
				!overflowScrolling.current &&
				!isMobile &&
				fsTextScrollEnabledRef.current &&
				!overlayInTransRef.current
			) {
				// console.log("vertical Touchmove");
				const y = e.clientY || e.touches?.[0]?.clientY || e.pageY;
				const curDelta = startY.current - y;

				scrollDeltaYCurrent.current += curDelta * 2;
				startY.current = y;
				refreshBlackOverlay("handleTouchMove - vertical");
			}
		}

		move(false);
	};

	const clearOverlayTransitionTimer = () => {
		if (overlayTransitionTimer.current) {
			clearTimeout(overlayTransitionTimer.current);
		}

		if (fsTxtEnabledTimer.current) {
			clearTimeout(fsTxtEnabledTimer.current);
		}
	};

	const resetActiveVideo = () => {
		if (!isPortraitModeRef.current) {
			setActiveVideo(-1);
		} else {
			setActiveVideo(2);
		}
	};

	const handleToogleClassInTrans = (item, bool) => {
		const curInTrans = item.classList.contains("in-trans");

		if (bool) {
			if (!curInTrans) item.classList.add("in-trans");
		} else {
			if (curInTrans) item.classList.remove("my-class");
		}
	};
	const setOverlayTransitionTimer = (solution, cooldown) => {
		fsTxtEnabledTimer.current = setTimeout(() => {
			handleFsTextScrollEnabledChange(true);
		}, cooldown / 2);

		overlayTransitionTimer.current = setTimeout(() => {
			resetActiveVideo();

			handleOverlayInTransChange(true);

			clearOverlayTransitionTimer();

			refreshBlackOverlay("setOverlayTransitionTimer", -1);

			const playFilmBtns = document.querySelectorAll(
				".btn-parallax-sol-pseudo"
			);
			const videoSelectorBtns = document.querySelectorAll(
				".sol-fs-btn-parallax-cnt"
			);

			if (videoSelectorBtns.length)
				gsap.killTweensOf(".sol-fs-btn-parallax-cnt");

			if (playFilmBtns.length)
				gsap.killTweensOf(".btn-parallax-sol-pseudo");

			[
				...(playFilmBtns.length ? playFilmBtns : []),
				...(videoSelectorBtns.length ? videoSelectorBtns : []),
			].forEach((item) => {
				if (item) {
					gsap.fromTo(
						item,
						{ duration: 0.6, ease: Expo.easeOut, yPercent: +100 },
						{
							// onStart: () => {
							// 	handleToogleClassInTrans(item, true);
							// },
							// onComplete: () => {
							// 	handleToogleClassInTrans(item, false);
							// },
							yPercent: 0,
							autoAlpha: 1,
							visibility: "visible",
							stagger: { each: 0.05, ease: Expo.easeOut },
						}
					);
				}
			});

			const btnTimelineSpacer = document.querySelectorAll(
				".sol-fs-btn-timeline-spacer"
			);

			if (btnTimelineSpacer.length > 0) {
				gsap.fromTo(
					btnTimelineSpacer,
					{ duration: 0.6, yPercent: +250, ease: Expo.easeOut },
					{
						yPercent: 0,
						xPercent: 0,
						autoAlpha: 1,
						stagger: { each: 0.05, ease: Expo.easeOut },
					}
				);
			}
			// was to late before
			//init text

			fullscreenSolutionRef.current.initText();
			handleOverlayInTransChange(false);
			refreshBlackOverlay("setOverlayTransitionTimer", -1);
			gsap.to(
				fullscreenText_v2.current,

				{
					duration: 0.6,
					ease: Expo.easeOut,
					autoAlpha: 1,
					onComplete: () => {
						refreshBlackOverlay("setOverlayTransitionTimer", -1);
					},
				}
			);
		}, cooldown);
	};

	const handleTxtVisibility = (bool) => {
		if (textVisibility.current !== bool) {
			textVisibility.current = bool;
			fullscreenSolutionRef.current.textVisibility(bool);
			if (autoPlay) {
			}
			resetActiveVideo();
		}
	};

	const handleChangeHeaderState = (newState) => {
		const curCase = activeCase.current;

		switch (newState) {
			case "default":
				break;
			case "player":
				props.changeHeaderState("logo", {
					hideNav: true,
					customBack: {
						icon: isMobile ? null : "cross",
						text: " ",
						fn: () => {
							handleVideoModalOpen(false);
							handleSlideExit();
						},
					},
				});
				break;
			case "slider_overview":
				if (isPortraitModeRef.current) {
					props.changeHeaderState("logo", {
						searchBar: {
							active: true,
						},
					});
				} else {
					props.changeHeaderState("logo", {
						searchBar: {
							active: true,
						},
					});
				}

				break;
			case "active_slide":
				if (isPortraitModeRef.current) {
					props.changeHeaderState("logo", {
						searchBar: {
							active: true,
						},
					});
				} else {
					props.changeHeaderState("logo", {
						customBack: {
							icon: "arrow",
							text: `See more ${
								caseFilterRef.current == "all"
									? "Cases"
									: caseFilterRef.current == "work"
									? "Work"
									: "Solutions"
							}`,
							fn: handleSlideExit,
						},
					});
				}
				break;
			case "active_hover":
				if (isPortraitModeRef.current) {
					props.changeHeaderState("logo", {
						customBack: {
							icon: "arrow",
							text: curCase?.name,

							fn: setHoverTimeout,
						},
					});
				} else {
					if (isTextScrolledUp()) {
						props.changeHeaderState("logo", {
							customBack: {
								icon: "arrow",
								color: "active",
								text: curCase?.name,
								textPrev: `${
									caseFilterRef.current == "all"
										? "Cases"
										: caseFilterRef.current == "work"
										? "Work"
										: "Solutions"
								}`,
								fn: handleSlideExit,
							},
						});
					}
				}
				break; //if not portraitmode -> do active title
			case "active_title":
				if (isPortraitModeRef.current) {
					props.changeHeaderState("logo", {
						customBack: {
							color: "active",
							caller: "solution_active_title",
							text: curCase?.name,
						},
					});
				} else {
					props.changeHeaderState("logo", {
						customBack: {
							icon: "arrow",
							color: "active",
							text: curCase?.name,
							textPrev: `${
								caseFilterRef.current == "all"
									? "Cases"
									: caseFilterRef.current == "work"
									? "Work"
									: "Solutions"
							}`,
							fn: handleSlideExit,
						},
					});
				}
				break;
		}
	};

	const handleSetAutoplay = (bool, caller = "") => {
		if (bool !== autoPlay) {
			setAutoplay(bool);
		}
	};

	const setSolutionOverlay = (
		solution,
		cooldown = overlayCoolDown.current,
		instant = false
	) => {
		//console.log("set overlay");
		if (fullscreenText_v2.current) fullscreenText_v2.current.scrollTop = 0;
		clearOverlayTransitionTimer();
		handleOverlayInTransChange(true);

		handleFsTextScrollEnabledChange(false);

		// disableDraggable();

		const playFilmBtns = document.querySelectorAll(
			".btn-parallax-sol-pseudo"
		);
		const videoSelectorBtns = document.querySelectorAll(
			".sol-fs-btn-parallax-cnt"
		);

		if (videoSelectorBtns.length)
			gsap.killTweensOf(".sol-fs-btn-parallax-cnt");

		if (playFilmBtns.length) gsap.killTweensOf(".btn-parallax-sol-pseudo");
		[
			...(playFilmBtns.length ? playFilmBtns : []),
			...(videoSelectorBtns.length ? videoSelectorBtns : []),
		].forEach((item) => {
			if (item) {
				gsap.to(item, {
					// onStart: () => {
					// 	handleToogleClassInTrans(item, true);
					// },
					// onComplete: () => {
					// 	handleToogleClassInTrans(item, false);
					// },
					autoAlpha: 0,
					visibility: "hidden",
					overwrite: "auto",
				});
			}
		});

		const btnTimelineSpacer = document.querySelectorAll(
			".sol-fs-btn-timeline-spacer"
		);

		if (btnTimelineSpacer.length > 0) {
			gsap.to(btnTimelineSpacer, instant ? 0 : 0.125, {
				autoAlpha: 0,
				overwrite: "auto",
			});
		}

		if (!isPortraitModeRef.current)
			gsap.to(fullscreenText_v2.current, instant ? 0 : 0, {
				autoAlpha: 0,
				overwrite: "auto",
				onComplete: () => {
					//overlayInTransRef.current = false;
					if (fullscreenText_v2.current)
						fullscreenText_v2.current.scrollTop = 0;
				},
			});

		if (solution) {
			handleSetActiveCase(solution);
			handleChangeHeaderState("active_slide");

			handleSetAutoplay(true, "setSolutionOverlay");

			setOverlayTransitionTimer(solution, cooldown);
			if (!isPortraitModeRef.current) {
				gsap.to(progressBar.current, { autoAlpha: 0 });
			}
		} else {
			handleSetActiveCase(null);
			handleChangeHeaderState("slider_overview");
			//props.changeHeaderState("logo_no_back");
			handleHover(false, true, -1, true);
			handleTxtVisibility(false);

			props.changeCursorState("idle");
			gsap.to(progressBar.current, { autoAlpha: 1 });
		}
	};

	const getNextLiveSlide = (title = false) => {
		const curProgress = title
			? progress.current + slideWidth.current / 2
			: progress.current;
		return clamp(
			parseInt(curProgress / slideWidth.current),
			0,
			cases?.[0]?.length - 1
		);
	};

	const handleTouchEnd = (e) => {
		//console.log("touchend");
		e.stopPropagation();

		// e.preventDefault();

		//if (!dragging.current) return;

		// if (!blockDragInstance.current) {
		// 	dragInstance.current?.[0]?.enable();
		// }
		dragElapsedTime.current = Date.now() - dragStartTime.current;

		//drag or click?
		const delta = 6;
		const endX = e.clientX || e.touches?.[0]?.clientX || e.pageX;
		const endY = e.clientY || e.touches?.[0]?.clientY || e.pageY;
		const diffX = Math.abs(endX - startDrag.current.x);
		const diffY = Math.abs(endY - startDrag.current.y);
		const swipeLeft = endX - startDrag.current.x < 0;

		if (diffX < delta && diffY < delta) {
			wasDrag.current = false;
		}

		setOnDragging(false);
		onscroll.current = false;

		//sliderWrapperRef.current.classList.remove("dragging");

		if (dragDirection.current === 1) {
		}
		if (isPortraitModeRef.current) {
			handleProgress(
				"handleTouchEnd",
				gsap.utils.snap(
					slideWidth.current,
					clamp(
						progress.current +
							(diffX > slideWidth.current / 8
								? slideWidth.current
								: 0) *
								(swipeLeft ? 1 : -1),
						xProgress.current - slideWidth.current / 2,
						xProgress.current + slideWidth.current / 2
					)
				)
			);

			const nextActiveSlide = getNextLiveSlide(); //clamp for overshoot

			if (activeSlideRef.current !== nextActiveSlide) {
				//refreshBlackOverlay(0);
				//activeSlideRef.current = nextActiveSlide;

				// if (
				// 	titleDragEvent.current &&
				// 	Date.now() - titleDragEvent.current < 500
				// ) {
				// 	setNextLiveSlide(nextActiveSlide)
				// 	updateCenterTitle(nextActiveSlide);
				// 	// gsap.set(titleMover.current, {
				// 	// 	y:
				// 	// 		-nextActiveSlide *
				// 	// 		gsap.getProperty(titleContainer.current, "height"),
				// 	// });
				// }
				setNextLiveSlide(nextActiveSlide);
				//updateCenterTitle(nextActiveSlide);
				// gsap.killTweensOf(titleMover.current);

				// gsap.to(titleMover.current, {
				// 	duration: 0,
				// 	autoAlpha: 1,
				// 	ease: Power0.linear,
				// 	y:
				// 		-nextActiveSlide *
				// 		gsap.getProperty(titleContainer.current, "height"),
				// 	overwrite: "auto",
				// });

				handleSetActiveSlide(nextActiveSlide);

				setSolutionOverlay(cases?.[0]?.[nextActiveSlide]);
			} else {
				// fadeTitle(false);
				//refreshBlackOverlay();
				if (dragDirection.current === 0) {
					const nextSlide = getNextLiveSlide();

					setSolutionOverlay(cases?.[0]?.[nextSlide]);
				}
			}

			move();
		} else {
			move(); //clamp back
		}
	};

	const initSlide = (slide, i, duration = 0, onComplete = () => {}) => {
		const height = (slideWidth.current * 9) / 16;
		slide.setAttribute("data-slide-id", i);
		slide.setAttribute("data-flip-id", i);

		gsap.killTweensOf(slide);
		const x = slideWidth.current * i;
		if (!isPortraitModeRef.current) {
			gsap.to(slide, {
				duration: duration,
				x: x,
				width: `${slideWidthPercentage.current * 100}%`,
				height: height + "px",
				top: "50%",
				marginTop: `-${height / 2}px`,
				onComplete: onComplete,
				ease: Expo.easeOut,
				scale: 1,
			});
		} else {
			gsap.to(slide, {
				duration: duration,
				x: x,
				width: `${slideWidthPercentage.current * 100}%`,
				height: "100%",
				overflow: "hidden",
				top: "0%",
				marginTop: `0px`,
				onComplete: onComplete,
				ease: Expo.easeOut,
				scale: 1,
			});
		}
		return x;
	};

	const refreshWindowSizeAndPortrait = (forced = false) => {
		windowSize.current = getWindowSize();

		const newIsPortrait =
			windowSize.current.vh > windowSize.current.vw && isTablet;
		const orientationHasChanged =
			newIsPortrait !== isPortraitModeRef.current;

		if (orientationHasChanged || forced) {
			if (initedSlider.current && isMobile && !isTablet && !forced)
				return;

			initedSlider.current = true;
			isPortraitModeRef.current =
				isMobile && !isTablet
					? true
					: !isMobile && !isTablet
					? false
					: newIsPortrait;
			setIsPortraitMode(isPortraitModeRef.current);
			resetActiveVideo();

			handleSlideExit();
			initImages();
		}
	};
	const calculate = (e = null, initPosters = false, exclude = -1) => {
		refreshWindowSizeAndPortrait();

		const isVertical =
			windowSize.current.vw / windowSize.current.vh < 1.7777777778;

		const scale = isVertical
			? windowSize.current.vh / 1080
			: windowSize.current.vw / 1920;

		setCurScale(scale);

		setSlidePercentage(isPortraitModeRef.current);

		slides.current[0] = document.querySelectorAll(".slide");
		const $posters = document.querySelectorAll(".poster");
		contents.current = document.querySelectorAll(".content");

		slideWidth.current =
			windowSize.current.vw * slideWidthPercentage.current; //change for mobi
		wrapWidth.current = slides.current[0].length * slideWidth.current;

		if (!lockScroll.current || exclude !== -1) {
			slides.current[0].forEach((slide, i) => {
				if (i !== exclude) {
					const xSlideCur = initSlide(slide, i);
					slides.current[1][i] = xSlideCur;
				}
			});
			const wildcardSlide = getCaseFromWildcardPath();
			let wildcardAnimation = wildcardSlide;
			if (wildcardAnimation + 2 > slides.current[0].length - 1) {
				wildcardAnimation = slides.current[0].length - 2;
			} else if (wildcardAnimation === 0 || wildcardAnimation === -1) {
				wildcardAnimation = 1;
			}

			if (initPosters) {
				setPreloaded(true);
				// gsap.killTweensOf(contents.current);
				if (isPortraitModeRef.current) {
					const activeSlide = wildcardSlide > -1 ? wildcardSlide : 0;
					handleSetActiveSlide(activeSlide);
					setSolutionOverlay(cases?.[0]?.[activeSlide]);
				}

				gsap.delayedCall(1.5, () => {
					// gsap.set(contents.current, { autoAlpha: 1 });
					onscroll.current = false;
					setIsEnabled(true);
					//activate first slide
					if (isPortraitModeRef.current) {
						// const activeSlide =
						// 	wildcardSlide > -1 ? wildcardSlide : 0;
						// handleSetActiveSlide(activeSlide);
						// setSolutionOverlay(cases?.[0]?.[activeSlide]);
					} else {
						if (wildcardSlide !== -1) {
							lockScroll.current = true;
							handleSlideEnter(wildcardSlide);
						}
					}
				});
			}

			handleProgress("calculate", playrate.current * wrapWidth.current);
			move();
		}
	};

	const clearLongPressTimeout = () => {
		if (longPressTimeout.current) {
			clearTimeout(longPressTimeout.current);
		}
	};

	const setLongPressTimeout = () => {
		clearLongPressTimeout();
		longPressTimeout.current = setTimeout(
			() => (isLongPress.current = true),
			1000
		);
	};

	const clearWheelTimeout = () => {
		if (wheelTimeout.current) {
			clearTimeout(wheelTimeout.current);
		}
	};
	const setWheelTimeout = () => {
		clearWheelTimeout();
		wheelTimeout.current = setTimeout(
			() => (onscroll.current = false),
			300
		);
	};

	const handleMouseWheel = (e) => {
		onscroll.current = true;

		e.preventDefault();
		e.stopPropagation();

		let maxDelta = 0;

		const deltaX = e.deltaX;

		if (Math.abs(e.deltaY) > Math.abs(deltaX)) {
			maxDelta = e.deltaY;
		} else {
			maxDelta = deltaX;
		}

		let deltaMulti = 1;
		if (maxDelta < 0) {
			deltaMulti = -1;
		}
		maxDelta = deltaMulti * clamp(Math.abs(maxDelta), 0, 200);

		progress.current += isPortraitModeRef.current
			? clamp(Math.abs(e.deltaX), 0, 200)
			: maxDelta;

		if (fsTextScrollEnabledRef.current && !overlayInTransRef.current) {
			// scrollDeltaYCurrent.current +=
			// 	deltaMulti * clamp(Math.abs(e.deltaY), 0, 200);
			fullscreenText_v2.current.scrollTop += e.deltaY;
			// deltaMulti * clamp(Math.abs(e.deltaY), 0, 200);
			// deltaMulti * clamp(Math.abs(e.deltaY), 0, 200);
		}

		setWheelTimeout();

		move();
	};

	const isTextScrolledUp = () => {
		const curScrollTop = fullscreenText_v2.current?.scrollTop || 0;

		scrollDirection.current = curScrollTop - prevScroll.current;

		prevScroll.current = curScrollTop;

		return (
			curScrollTop >
			(scrollDirection.current > -200 && curScrollTop > 30 ? 10 : 10)
		);
	};

	const blackZeroValue = 0;
	const refreshBlackOverlay = (
		caller,
		forcedValue = -1,
		hoverOverwrite = false
	) => {
		//console.log(caller, forcedValue);
		if (isCurrentlyHoveredRef.current && !hoverOverwrite) return;

		//if (overlayInTransRef.current) return;
		// console.log({
		// 	overlayInTrans: overlayInTransRef.current,
		// 	forcedValue,
		// 	hoverOverwrite,
		// 	caller,
		// });

		let desiredOpacityBlack = -1;
		let desiredScrollForMore = -1;

		if (forcedValue === -1) {
			//if (gsap.getProperty(fullscreenText.current, "y") + 50 < 0) return;

			if (isTextScrolledUp()) {
				desiredOpacityBlack = 1;
				desiredScrollForMore = 0;
				handleChangeHeaderState("active_title");
				handleTxtVisibility(true);
			} else {
				desiredOpacityBlack = blackZeroValue;
				desiredScrollForMore = 1;
				handleChangeHeaderState("active_slide");
				handleTxtVisibility(false);
			}
		} else if (forcedValue === 1 || forcedValue === 2) {
			desiredOpacityBlack = 1;
		} else if (forcedValue === 0) {
			desiredOpacityBlack = 0;
		} else if (forcedValue === 3) {
			//horizontal swipe mobile

			desiredOpacityBlack = 0;
		}

		if (activeSlideRef.current === -1) {
			// gsap.to(scrollForMore.current, 0.3, { autoAlpha: 0 });
			//handleSetAutoplay(false, "refreshBlackOverlay");
		} else if (desiredScrollForMore === 1) {
			if (scrollForMoreTl.current) {
				scrollForMoreTl.current.restart(true);
			}
			handleSetAutoplay(true, "refreshBlackOverlay");

			// gsap.to(scrollForMore.current, 0.3, { autoAlpha: 1 });
		} else if (desiredScrollForMore === 0) {
			// gsap.to(scrollForMore.current, 0.3, { autoAlpha: 0 });
			handleSetAutoplay(false, "refreshBlackOverlay");
		}

		if (currentOpacityBlackOverlay.current !== desiredOpacityBlack) {
			if (desiredOpacityBlack == 1) {
				sendGACustomEvent(
					props.globalContext,
					"r",
					activeCase?.current?.shorthand
				);
			}
			currentOpacityBlackOverlay.current = desiredOpacityBlack;

			const blackOverlayOn = currentOpacityBlackOverlay.current === 1;

			if (blackOverlayOn) {
				const allSlideVideos =
					document.querySelectorAll(".slide video");
				allSlideVideos.forEach((video) => {
					video.pause();
				});
			} else {
				const activeSlideEl = document.querySelectorAll(
					`[data-slide-id='${activeSlideRef.current}'] video`
				);

				activeSlideEl.forEach((video) => {
					var playPromise = video.play();
					if (playPromise !== undefined) {
						playPromise
							.then(function () {
								// Automatic playback started!
							})
							.catch(function (error) {
								// Automatic playback failed.
								// Show a UI element to let the user manually start playback.
							});
					}
				});
			}
			gsap.killTweensOf(blackOverlayRef.current);

			gsap.to(blackOverlayGradientRef.current, 0.3, {
				autoAlpha: blackOverlayOn ? 1 : 0,
			});
			gsap.to(blackOverlayGradientTopRef.current, 0.3, {
				autoAlpha: blackOverlayOn ? 1 : 0,
			});

			gsap.to(blackOverlayRef.current, 0.3, {
				autoAlpha: currentOpacityBlackOverlay.current,
				overwrite: "auto",
				onStart: () => {
					if (currentOpacityBlackOverlay.current < 1) {
						gsap.set(sliderRef.current, {
							autoAlpha: 1,
						});
					}
				},
				onComplete: () => {
					gsap.set(sliderRef.current, {
						autoAlpha:
							currentOpacityBlackOverlay.current === 1 ? 0 : 1,
					});
				},
			});
		}
	};

	const initSlides = (indexStartSlide) => {
		calculate(null, true);

		handleChangeHeaderState("slider_overview");
		//props.changeHeaderState("logo_no_back");
		props.changeView("cases");
		props.initLoad();

		raf.current = () => {
			if (!lockScroll.current) {
				//console.log("raf");

				xProgress.current = lerp(
					xProgress.current,
					progress.current,
					isPortraitModeRef.current ? 0.25 : 0.125
				);

				playrate.current = xProgress.current / wrapWidth.current;

				if (sliderRef.current)
					sliderRef.current.style.transform = `translate3d(${-xProgress.current}px,0,0)`;

				const additionalPlayRate =
					xProgress.current /
					(wrapWidth.current -
						slidesPerPage.current * slideWidth.current);

				const offsetMulti = clamp(1 - 1 / cases?.[0]?.length, 0, 0.9);
				if (progressBar.current)
					progressBar.current.style.transform = `translate3d(${clamp(
						(additionalPlayRate - 1) * 100 * offsetMulti,
						-100 * offsetMulti,
						0
					)}%,0,0)`;

				if (isPortraitModeRef.current) {
					slides.current?.[0]?.forEach((slide, i) => {
						//console.log(progress.current, slides.current?.[1][i]);

						const slideX = slides.current?.[1][i];

						//const scale = 1 - slideX / progress.current;
						const distance =
							(progress.current - slideX) / slideWidth.current;

						const scale = 1 - clamp(Math.abs(distance) / 4, 0, 1);

						gsap.to(slide, {
							//skewX: -scrollSpeed * 0.2,
							//rotate: scrollSpeed * 0.01,
							scale: scale,
							//x: slideX + slideWidth.current * distance * scale,
							//transformOrigin: `${progress.current + distance}px 50%`,
						});
						//console.log(progress.current);
						// const centerOffset =
						// 	(slideWidth.current * slidesPerPage.current) / 2 -
						// 	slideWidth.current / 2;
						//console.log(gsap.getProperty(slide, "x"));
						// gsap.to(slide, {
						// 	//skewX: -scrollSpeed * 0.2,
						// 	//rotate: scrollSpeed * 0.01,
						// 	scale: 1 - Math.min(100, Math.abs(scrollSpeed)) * 0.001,
						// });
					});
				}
			} else {
				handleProgress("raf", xProgress.current);
			}

			scrollDeltaYTarget.current = lerp(
				0,
				scrollDeltaYCurrent.current,
				0.15
			);

			scrollDeltaYCurrent.current -= scrollDeltaYTarget.current;

			if (Math.abs(scrollDeltaYTarget.current) > 0.00025) {
				fullscreenText_v2.current.scrollTop +=
					scrollDeltaYTarget.current;
			}
		};

		if (indexStartSlide !== -1) {
			const centerOffset =
				(slideWidth.current * slidesPerPage.current) / 2 -
				slideWidth.current / 2;

			handleProgress(
				"initSlides",
				slideWidth.current * indexStartSlide - centerOffset
			);
			move(true, true);
		}
	};

	const initImages = () => {
		const loadImage = (image) => {
			const portraitMode = isPortraitModeRef.current;
			return new Promise((resolve, reject) => {
				const loadImg = new Image();
				loadImg.src = `/img/cases/${image["shorthand"]}/poster/${
					image["shorthand"]
				}-${
					portraitMode
						? "poster-portrait-900p"
						: "poster-landscape-1600p"
				}.jpg`;

				if (portraitMode) {
					image["poster-portrait-900p"] = loadImg.src;
				} else {
					image["poster-landscape-1600p"] = loadImg.src;
				}
				// wait 2 seconds to simulate loading time
				loadImg.onload = () =>
					setTimeout(() => {
						resolve(image.url);
					}, 0);

				loadImg.onerror = (err) => reject(err);
			});
		};

		const startInit = () => {
			// setPreloaded(true);
			const startSlideIndex = getCaseFromWildcardPath();
			initSlides(startSlideIndex);
			// if (isPortraitModeRef.current) {
			// 	setSolutionOverlay(
			// 		cases[startSlideIndex > -1 ? startSlideIndex : 0]
			// 	);
			// }
		};

		Promise.all(caseData.map((solution) => loadImage(solution)))
			.then(() => {
				// console.log("preload done");
				startInit();
			})
			.catch((err) => {
				startInit();
				console.log("Failed to load images", err);
			});
	};

	const handleScrollRefresh = () => {
		if (activeSlideRef.current === -1) return;
		refreshBlackOverlay("scroll throttle");
	};

	useEffect(() => {
		// if (init.current) return;
		// init.current = true;
		console.log("useEffectOnce mount solutions");

		const _fullscreenText_v2 = fullscreenText_v2.current;

		_fullscreenText_v2.addEventListener("scroll", handleScrollRefresh);
		requestRef.current = requestAnimationFrame(animate);
		window.addEventListener("touchstart", handleTouchStart, {
			passive: false,
		});

		window.addEventListener("wheel", handleMouseWheel, {
			passive: false,
		});

		window.addEventListener("resize", calculate);

		window.addEventListener("touchmove", handleTouchMove);
		window.addEventListener("touchend", handleTouchEnd);
		// //
		window.addEventListener("mousedown", handleTouchStart);
		window.addEventListener("mousemove", handleTouchMove);
		window.addEventListener("mouseup", handleTouchEnd);
		//window.addEventListener("mouseleave", handleTouchEnd);

		return () => {
			if (_fullscreenText_v2) {
				_fullscreenText_v2.removeEventListener(
					"scroll",
					handleScrollRefresh
				);
			}
			cancelAnimationFrame(requestRef.current);

			window.removeEventListener("touchstart", handleTouchStart, {
				passive: false,
			});
			window.removeEventListener("wheel", handleMouseWheel, {
				passive: false,
			});
			window.removeEventListener("resize", calculate);

			window.removeEventListener("touchmove", handleTouchMove);
			window.removeEventListener("touchend", handleTouchEnd);
			// //
			window.removeEventListener("mousedown", handleTouchStart);
			window.removeEventListener("mousemove", handleTouchMove);
			window.removeEventListener("mouseup", handleTouchEnd);
			// window.removeEventListener("pointermove", (e) => {
			// 	e.preventDefault();
			// });
			console.log("useEffectOnce unmount solutions");
		};
	}, []); // Make sure the effect runs only once

	useEffect(() => {
		console.log("useEffect caseFilter");
		setSolutionOverlay(null, 0, true);
		gsap.to(".slide", {
			duration: 0.3,

			onComplete: () => {
				progress.current = 0;
				xProgress.current = 0;
				handleSetActiveSlide(-1);
				handleSetActiveCase(null);
				setActiveVideo(-1);
				handleHover(false, true);
				setIsEnabled(false);

				setPreloaded(false);
				clearHoverTimeout();
				refreshCases();
				navigate(
					`/cases${
						caseFilter !== "all"
							? "/" +
							  (caseFilterRef.current === "solution"
									? caseFilterRef.current + "s"
									: caseFilterRef.current)
							: ""
					}`,
					{ replace: true }
				);
			},
		});
	}, [caseFilter]);

	useLayoutEffect(() => {
		refreshWindowSizeAndPortrait(true);
	}, [cases]); // Make sure the effect runs only once

	const handleSlideClick = (id) => {
		//console.log("handleSlideClick");
		if (
			wasSearchClose.current ||
			wasDrag.current ||
			isPortraitModeRef.current ||
			isLongPress.current
		)
			return false;
		lockScroll.current = true;

		//const target = findAncestor(e.target, "slide");

		const isActive = activeSlideRef.current === id;

		if (!isActive) {
			handleSlideEnter(id);
		} else {
			handleSlideExit();
		}
	};

	const handleSlideEnter = (id) => {
		const activeSlideEl =
			document.querySelectorAll(`[data-slide-id='${id}']`)?.[0] || null;
		const allOtherSlides = document.querySelectorAll(
			`.slide:not([data-slide-id='${id}'])`
		);

		const center = xProgress.current;

		const curWidth = slideWidth.current;
		const curHeight = curWidth * 0.5625;

		const isVertical =
			windowSize.current.vw / windowSize.current.vh < 1.7777777778;

		let scale = isVertical
			? windowSize.current.vh / curHeight
			: windowSize.current.vw / curWidth;

		handleSetActiveSlide(id);
		setSolutionOverlay(cases?.[0]?.[id], 500);
		allOtherSlides.forEach((slide) => {
			slide.style.zIndex = 0;
		});
		//target.style.transform = `translate3d(${center}px,0,0)`;
		activeSlideEl.style.zIndex = 10;

		gsap.killTweensOf(activeSlideEl);

		gsap.to(activeSlideEl, 0.75, {
			x: center + windowSize.current.vw / 2 - curWidth / 2,

			ease: Expo.easeInOut,
			scale: scale,
			onComplete: () => {
				//console.log(gsap.getProperty(activeSlideEl, "scale"));

				setTimeout(() => {
					gsap.set(activeSlideEl, {
						top: 0,
						marginTop: 0,
						x: center,
						backgroundColor: "rgba(0,0,0,1)",
						scale: 1,
						width: "100%",
						height: "100%",
					});
				}, 50);
			},
		});
	};

	const handleSlideExit = () => {
		const activeSlideId = activeSlideRef.current;
		const activeSlideEl =
			document.querySelectorAll(
				`[data-slide-id='${activeSlideId}']`
			)?.[0] || null;

		if (!activeSlideEl) return;

		handleSetActiveSlide(-1);
		handleSetActiveCase(null);

		setActiveVideo(-1);
		handleHover(false, true);
		clearHoverTimeout();

		setSolutionOverlay(null);

		// disableDraggable();

		refreshBlackOverlay("handleSlideExit", 0);

		calculate(null, false, activeSlideId);
		lockScroll.current = false;
		initSlide(activeSlideEl, activeSlideId, 0.666, () => {
			//activeSlideEl.classList.remove("active");
			activeSlideEl.style.zIndex = 1;
		});
	};

	const handleIsCurrentlyHovered = (bool) => {
		setIsCurrentlyHovered(bool);
		isCurrentlyHoveredRef.current = bool;
	};

	const handleHover = (
		active,
		blockFadeIn = false,
		activeVideo = -1,
		forced = false
	) => {
		if ((!isEnabled || activeSlide === -1) && !forced) return;

		handleIsCurrentlyHovered(active);

		//console.log("This is forced? " + forced);
		if (active) {
			sendGACustomEvent(
				props.globalContext,
				"h",
				activeCase?.current?.shorthand + "-" + activeVideo
			);
			handleChangeHeaderState("active_hover");
			refreshBlackOverlay("handleHover", 0, true);
			// gsap.to(scrollForMore.current, { autoAlpha: 0 });
			// gsap.to(hintWrapper.current, 0.333, { autoAlpha: 1 });

			// hintTxt.current.textContent = [
			// 	cases?.[0]?.[activeSlide]["vid-title-00"],
			// 	cases?.[0]?.[activeSlide]["vid-title-01"],
			// 	cases?.[0]?.[activeSlide]["vid-title-02"],
			// ][activeVideo];

			gsap.to(fullscreenText_v2.current, 0.333, { autoAlpha: 0 });
			gsap.to(".sol-fs-progressbar", 0.333, { scaleX: 0 });
		} else {
			//gsap.to(scrollForMore.current, { autoAlpha: 1 });
			// gsap.to(hintWrapper.current, 0.333, { autoAlpha: 0 });
			gsap.to(".sol-fs-progressbar", 0.333, { scaleX: 1 });
			if (blockFadeIn) return;

			refreshBlackOverlay("handleHover", -1, true);

			gsap.to(fullscreenText_v2.current, 0.333, { autoAlpha: 1 });
		}
	};

	const clearHoverTimeout = () => {
		if (hoverTimeout.current) {
			clearTimeout(hoverTimeout.current);
			hoverTimeout.current = null;
		}
	};

	const setHoverTimeout = (instant) => {
		clearHoverTimeout();
		hoverTimeout.current = setTimeout(
			() => {
				scrollDeltaYCurrent.current = 0; //stop scroll animation
				if (!isTextScrolledUp()) {
					props.changeCursorState("idle");
				} else {
					props.changeCursorState("idle");
				}

				if (!textVisibility.current) {
					resetActiveVideo();
				}

				//setActiveVideo(3); // set back to preview video
				handleHover(false);
				//refreshBlackOverlay(2);
			},
			instant ? 0 : 300
		);
	};

	const handleMoveSlide = (direction) => {};

	return (
		<div>
			<div className="slider-wrapper" ref={sliderWrapperRef}>
				<div
					className="slider"
					ref={sliderRef}
					style={{ opacity: preloaded ? 1 : 0 }}
				>
					{cases?.[0]?.map((solution, i) => {
						return (
							<SolutionSlide
								preloaded={preloaded}
								type={solution?.type}
								tags={solution?.tags}
								isEnabled={isEnabled}
								activeVideo={activeVideo}
								curScale={curScale}
								isSmallScreen={windowSize.current.vw < 768}
								activeSlide={activeSlide}
								key={
									"slide_" +
									solution?.shorthand +
									cases?.[1] +
									props.cKey
								}
								cKey={
									"slide_" +
									solution?.shorthand +
									cases?.[1] +
									props.cKey
								}
								index={i}
								solution={solution}
								title={solution.name || "N/A"}
								isPortraitMode={isPortraitMode}
								preview={
									isPortraitMode
										? null
										: solution["landscape-pr-720p"]
								}
								main={
									isPortraitMode
										? solution?.type === "work"
											? solution["portrait-pr-1080p"]
											: solution["portrait-1080p"]
										: solution["landscape-1440p"]
								}
								poster={
									isPortraitMode
										? solution["poster-portrait-900p"]
										: solution["poster-landscape-1600p"]
								}
								onclick={() => {
									handleSlideClick(i);
								}}
								onscroll={onscroll}
								// ondragging={draggingState}
							/>
						);
					})}
				</div>

				<div
					className="sol-title-wrapper"
					style={{
						opacity: 0,
						visibility: "hidden",
						display: "none",
					}}
					ref={titleContainer}
				>
					<div className="sol-title-slider-item" key={"title_center"}>
						<div
							className="sol-index prevent-select"
							ref={centerTitleIndex}
						>
							{nextLiveSlide + 1 < 10
								? "0" + (nextLiveSlide + 1)
								: nextLiveSlide + 1}
						</div>
						<div
							className="sol-main-el prevent-select"
							ref={centerTitleName}
						>
							{cases?.[0]?.[nextLiveSlide]?.name}
						</div>
					</div>
					<div
						className={`slider-tag-new ${cases?.[0]?.[nextLiveSlide]?.type}`}
					>
						<div
							className={`div-block-45${
								cases?.[0]?.[nextLiveSlide]?.type === "work"
									? " work"
									: ""
							}`}
						/>
						<div className="slider-tag-new-txt">
							{cases?.[0]?.[nextLiveSlide]?.type === "solution"
								? "solution"
								: "work"}
						</div>
					</div>
				</div>

				<div
					className="black-overlay"
					ref={blackOverlayRef}
					style={{ opacity: 0, visibility: "hidden" }}
				/>
			</div>
			<div className="black-overlay-g" ref={blackOverlayGradientRef} />

			<div
				className="black-overlay-g top"
				ref={blackOverlayGradientTopRef}
			/>
			<div className="black-overlay-g bottom-bg" />
			<div
				className="sol-fs-txt-wrapper-v2"
				onClick={(e) => {
					if (isCurrentlyHoveredRef.current) {
						setHoverTimeout(true);
					}

					if (
						wasSearchClose.current ||
						wasDrag.current ||
						isPortraitModeRef.current ||
						isLongPress.current
					)
						return false;
					if (e.target.className === "sol-fs-txt-v2")
						handleSlideExit();
				}}
				style={{
					pointerEvents:
						activeSlide !== -1 && fsTextScrollEnabled
							? "auto"
							: "none",
				}}
			>
				<div
					className="sol-fs-txt-v2"
					ref={fullscreenText_v2}
					style={{ opacity: 0, visibility: "hidden" }}
					onClick={(e) => {
						if (
							wasSearchClose.current ||
							wasDrag.current ||
							isPortraitModeRef.current ||
							isLongPress.current
						)
							return false;
						if (e.target.className === "top-filler")
							handleSlideExit();
					}}
				>
					<FullscreenSolution
						fullscreenText_v2={fullscreenText_v2}
						index={nextLiveSlide}
						fsTextScrollEnabled={fsTextScrollEnabled}
						key={"fullscreen_solution"}
						ref={fullscreenSolutionRef}
						windowSize={windowSize.current}
						isPortraitMode={isPortraitMode}
						solution={cases?.[0]?.[nextLiveSlide]}
						activeSlide={nextLiveSlide}
						handleSlideExit={handleSlideExit}
						setNewFilter={setNewFilter}
						overlayInTrans={overlayInTrans}
					/>
				</div>
			</div>
			<div
				className="solution-fs-wrapper"
				style={{
					pointerEvents: "none",
				}}
			>
				<div className="sol-fs-content-wrapper">
					<div className="sol-fs-more-cnt">
						<div
							className="sol-fs-scrollmore-cnt"
							style={{ display: "none" }}
						>
							<div
								className="sol-fs-scrollmore"
								ref={scrollForMore}
								style={{ visibility: "hidden", opacity: 0 }}
							>
								<div
									className="sol-fs-scrollmore-inner"
									ref={scrollForMoreInner}
								>
									Scroll
								</div>
							</div>
						</div>
					</div>

					<div className="sol-fs-spacer-cnt">
						{!isPortraitMode ? null : (
							<div
								className={`sol-fs-btn-parallax-cnt-arrow left${
									activeSlide > 0 &&
									!isCurrentlyHoveredRef.current
										? " active"
										: ""
								}`}
							>
								<div className="sol-fs-btn-hover arrow">
									<div
										className="hover-btn-circle arrow"
										style={{ display: "none" }}
									>
										&lt;
									</div>
								</div>
							</div>
						)}

						<div className="sol-fs-progressbar">
							<div
								className="sol-fs-progressbar-fill"
								ref={progressBar}
							></div>
						</div>
						{!isPortraitMode ? null : (
							<div
								// onClick={() => {
								// 	handleMoveSlide(1);
								// 	alert("right");
								// }}
								// style={{
								// 	pointerEvents: "auto",
								// }}
								className={`sol-fs-btn-parallax-cnt-arrow right${
									activeSlide < cases?.[0]?.length - 1 &&
									!isCurrentlyHoveredRef.current
										? " active"
										: ""
								}`}
							>
								<div className="sol-fs-btn-hover arrow">
									<div
										className="hover-btn-circle arrow"
										style={{ display: "none" }}
									>
										&gt;
									</div>
								</div>
							</div>
						)}
					</div>

					<div
						className={`sol-fs-btn-cnt${
							isPortraitMode ? "" : " landscape"
						}`}
						style={{ pointerEvents: "none" }}
					>
						{/*						{cases?.[0]?.[activeSlide]?.type !== "work" ? null : (
							<div
								className="sol-fs-scrollmore active play-film"
								style={{
									visibility: "hidden",
									opacity: 0,

									pointerEvents: "all",
								}}
								onClick={() => {
									handleVideoModalOpen(true);
								}}
							>
								<div className="circle-x play-film-icon" />
								<div>Watch Film</div>
							</div>
						)}*/}

						{cases?.[0]?.[activeSlide]?.type !== "work" ? null : (
							<>
								<div className="sol-fs-btn-timeline-spacer hidden" />
								<BtnParallax
									disabled={overlayInTrans}
									invert={true}
									text={"Watch Film"}
									filmicon={true}
									defaulthide={true}
									addclass={"btn-parallax-sol-pseudo"}
									small
									onclick={() => {
										handleVideoModalOpen(true);
									}}
								/>
								{cases?.[0]?.[activeSlide]?.url?.length ? (
									<>
										<div className="sol-fs-btn-timeline-spacer hidden compress" />
										<BtnParallax
											invert={true}
											disabled={overlayInTrans}
											text={"Visit Site"}
											defaulthide={true}
											addclass={"btn-parallax-sol-pseudo"}
											small
											onclick={() => {
												window
													.open(
														cases?.[0]?.[
															activeSlide
														]?.url,
														"_blank"
													)
													.focus();
											}}
										/>
									</>
								) : null}
								<div className="sol-fs-btn-timeline-spacer hidden" />
							</>
						)}

						{cases?.[0]?.[activeSlide]?.type === "work"
							? null
							: [...Array(2)].map((x, i) => (
									<React.Fragment
										key={"btn_seleect_wrapper_" + i}
									>
										{i !== 0 ? (
											<div
												key={"spacer_select_" + i}
												className="sol-fs-btn-timeline-spacer"
												style={{
													opacity: 0,
													visibility: "hidden",
												}}
											/>
										) : null}
										<BtnVideoSelectorParallax
											key={"btn_select_" + i}
											// onclick={() => {
											// 	setActiveVideo(i);
											// }}
											index={i}
											autoplay={autoPlay}
											activeSlide={activeSlide}
											activeVideo={activeVideo}
											hovered={isCurrentlyHovered}
											onclick={() => {
												props.changeCursorState(
													"active"
												);
												clearHoverTimeout();
												setActiveVideo(i);
												handleHover(true, false, i);
											}}
											onmousemove={() => {
												props.changeCursorState(
													"active"
												);
												clearHoverTimeout();
												setActiveVideo(i);
												handleHover(true, false, i);
											}}
											onmouseleave={() => {
												setHoverTimeout();
											}}
										/>
									</React.Fragment>
							  ))}
					</div>
				</div>
			</div>
			{true ? null : (
				<div
					className="debug-overlay"
					style={{ pointerEvents: "none" }}
				>
					<FpsView />
					<div className="debug-txt">
						<div className="debug-item">
							{`autoPlay ` + autoPlay}
						</div>
						<div className="debug-item">
							{`activeVideo ` + activeVideo}
						</div>
						<div className="debug-item">
							{`isCurrentlyHovered ` + isCurrentlyHovered}
						</div>
						<div className="debug-item">
							{`scrollDirection.current ` +
								scrollDirection.current}
						</div>
					</div>
				</div>
			)}
			<div
				className="rotate-screen-wrapper"
				style={{ display: isMobile ? "" : "none", zIndex: 20000 }}
			>
				<div className="rotate-phone-icon"></div>
				<div className="rotate-phone-text">Rotate your Phone</div>
			</div>
			<VideoModal
				src={cases?.[0]?.[activeSlide]?.["film-1080p"] || ""}
				changeCursorState={props.changeCursorState}
				ref={videoModalRef}
			/>
		</div>
	);
});

export default React.memo(Solutions);
