import React from "react";

import ArtileEl, {
	Pink,
	White,
	Desc,
	Header,
	CustomImage,
} from "../../components/Elements/ArticleEl";

import cases from "../cases";

const componentName = "MotionCapture";

const shorthand = cases.find((x) => x.component == componentName).shorthand;

if (typeof shorthand == "undefined")
	console.error("Component can't find shorthand: " + componentName);
export default () => (
	<>
		<div className="section-div">
			<div className="section-content">
				<div className="section-hero">
					<div
						className="section-title-sub grad-txt"
						style={{ paddingLeft: 0, paddingRight: 0 }}
					>
						"With mocap you can create realistic and engaging
						digital characters that will grab the attention of the
						audience."
					</div>
				</div>
			</div>
		</div>

		<ArtileEl type={"text-right-half"}>
			<Header customType="header">Rich animations. Fast.</Header>
			<Desc customType="desc">
				Wave goodbye to the days of tedious key-frame animation and
				hello to the world of motion capture!{" "}
				<White>Create hyper-realistic characters and movements</White>{" "}
				to leave a lasting impression on the audience. Join the future
				of animation and give motion capture a try. Your characters (and
				your sanity) will thank you.
			</Desc>
			<CustomImage
				customType="image"
				src={`/img/cases/${shorthand}/image/img_01.png`}
			/>
		</ArtileEl>

		<ArtileEl type={"text-left-onethird"}>
			<Header customType="header">Move. Free.</Header>
			<Desc customType="desc">
				Our mocap suits record highly precise and accurate data - they
				have state-of-the-art sensors placed throughout the suit to
				track the movement of the actors' joints.{" "}
				<White>No need for markers and dozens of cameras</White>, just
				wear the suit. Location independent. In and Outdoors.
			</Desc>
			<CustomImage
				customType="image"
				src={`/img/cases/${shorthand}/image/img_02.png`}
			/>
		</ArtileEl>

		<ArtileEl type={"text-right-onethird"}>
			<Header customType="header">Say Cheese!</Header>
			<Desc customType="desc">
				Using lightweight camera rigs, we can{" "}
				<White>
					record the actor's expressions simultaneously or separately{" "}
				</White>
				from the body capture. This creates flexibility and scalability
				for every project. Adding a smile, not only on your characters
				face.
			</Desc>
			<CustomImage
				customType="image"
				src={`/img/cases/${shorthand}/image/img_03.png`}
			/>
		</ArtileEl>

		<ArtileEl type={"text-left-half"}>
			<Header customType="header">
				Now. <br />
				Nower. <br />
				<Pink>Real-time</Pink>.
			</Header>
			<Desc customType="desc">
				The motion-capture data can be displayed on a screen as it is
				being captured. This allows a more efficient and flexible
				animation process, as{" "}
				<White>
					actors and directors can see the results of their work in
					real-time
				</White>{" "}
				and make adjustments as needed.
			</Desc>
			<CustomImage
				customType="image"
				src={`/img/cases/${shorthand}/image/img_04.png`}
			/>
		</ArtileEl>
	</>
);
