import React from "react";

import ArtileEl, {
	Pink,
	White,
	Desc,
	Header,
	CustomImage,
} from "../../components/Elements/ArticleEl";

import cases from "../cases";

const componentName = "VodafoneEltonJohn";

const shorthand = cases.find((x) => x.component == componentName).shorthand;

if (typeof shorthand == "undefined")
	console.error("Component can't find shorthand: " + componentName);
export default () => (
	<>
		<div className="section-div" style={{}}>
			<div className="section-content">
				<div className="section-hero">
					<div
						className="section-title-sub grad-txt"
						style={{ paddingLeft: 0, paddingRight: 0 }}
					>
						"Elton John meets Augumented Reality"
					</div>
				</div>
			</div>
		</div>
		<div className="section-div no-p">
			<div
				className="section-img"
				style={{
					// maxWidth: "1920px",
					paddingTop: "0px",
					marginTop: "0px",
				}}
			>
				<img
					src={`/img/cases/${shorthand}/image/img_03.jpg`}
					style={{ marginTop: 40, borderRadius: 15, width: "100%" }}
				/>
			</div>
		</div>
		<div
			className="section-div"
			style={{
				// maxWidth: "1920px",
				// paddingTop: "0.8em",
				paddingTop: 0,
			}}
		>
			<div className="section-content">
				<div className="section-description">
					Elton John's final concert tour provided the stage for a
					unique and tech-led experience, paying tribute to the
					service workers who kept the world safe and running during
					the COVID pandemic. During his impressive concert in Hide
					Park, the audience had the unique opporunity to experience
					an AR enhanced performance of Elton's legandary song "I'm
					still standing", featuring virtual dancers from the NHS,
					Royal Mail, and public transportation sector.
				</div>
			</div>
		</div>
		<div className="section-div">
			<div className="section-content">
				<div className="section-hero">
					<div className="section-title-sub grad-txt">
						A legacy enhanced with AR.
					</div>
				</div>
				<div className="section-description">
					The performance perfectly matches the spirit of Elton John's
					iconic "I'm still standing" music video, paying homage to
					its legacy. Therefore we produced a custom-made dance
					performance and captured over 300 minutes of exclusive
					motion capture data, utilizing the impressive talents of two
					professional dancers.
				</div>
			</div>
			<div
				className="section-img"
				style={{
					// maxWidth: "1920px",
					paddingTop: "0px",
					marginTop: "0px",
				}}
			>
				<img
					src={`/img/cases/${shorthand}/image/img_02.jpg`}
					style={{ marginTop: 40, borderRadius: 15, width: "100%" }}
				/>
			</div>
		</div>
		<div className="section-div">
			<div className="section-content">
				<div className="section-hero">
					<div className="section-title-sub grad-txt">
						Elton John.
						<br />
						Live Concert.
						<br />
						Augumented Reality.
					</div>
				</div>
				<div className="section-description">
					The return of live music is a special moment for everyone,
					and it's crucial that we acknowledge those who made it
					possible. "I'm Still Standing" is a song about overcoming
					adversity and coming back stronger.
				</div>
			</div>
			<div
				className="section-img"
				style={{
					// maxWidth: "1920px",
					paddingTop: "0px",
					marginTop: "0px",
				}}
			>
				<img
					src={`/img/cases/${shorthand}/image/img_08.jpg`}
					style={{ marginTop: 40, borderRadius: 15, width: "100%" }}
				/>
			</div>
		</div>
	</>
);
