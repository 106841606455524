import React from "react";
import { gsap, Power2 } from "gsap";

import { isTouchDevice } from "../../hooks/utils";

export const Pink = (props) => {
	return <span className="text-pink">{props.children}</span>;
};
export const White = (props) => {
	return <span className="text-white">{props.children}</span>;
};

export const Desc = (props) => {
	return props.children;
};

export const Header = (props) => {
	return props.children;
};

export const CustomImage = (props) => {
	return props.children;
};

const ArticleEl = (props) => {
	//validate input

	const header =
		React.Children.toArray(props.children).filter(
			(x) => x.props?.customType == "header"
		)?.[0] || "";

	const desc =
		React.Children.toArray(props.children).filter(
			(x) => x.props?.customType == "desc"
		)?.[0] || "";

	const image =
		(
			React.Children.toArray(props.children).filter(
				(x) => x.props?.customType == "image"
			)?.[0] || ""
		)?.props?.src || null;

	const subheader = (subheaderText) => {
		return (
			<div
				className="ar-item-subheader"
				style={{ pointerEvents: "none" }}
			>
				{subheaderText}
			</div>
		);
	};

	const subheaderNew = (subheaderText) => {
		return (
			<div
				className="section-title-sub grad-txt"
				style={{ pointerEvents: "none" }}
			>
				{subheaderText}
			</div>
		);
	};
	const description = (descText) => {
		return (
			<div className="ar-item-desc" style={{ pointerEvents: "none" }}>
				{descText}
			</div>
		);
	};
	const descriptionNew = (descText) => {
		return (
			<div
				className="section-description"
				style={{ pointerEvents: "none" }}
			>
				{descText}
			</div>
		);
	};
	const imageRender = (url) => {
		return (
			<div className="ar-item-grow" style={{ pointerEvents: "none" }}>
				<div
					className="ar-item-img"
					style={{ backgroundImage: `url("${url}")` }}
				/>
			</div>
		);
	};

	const imageRenderNew = (url) => {
		return (
			<div
				className="section-content-item-grow"
				style={{ pointerEvents: "none" }}
			>
				<div
					className="section-item-img"
					style={{ backgroundImage: `url("${url}")` }}
				/>
			</div>
		);
	};

	const Article = (props) => (
		<div className="article-el">{props.children}</div>
	);

	const ArticleNew = (props) => (
		<div className="section-div">{props.children}</div>
	);

	const Element = () => {
		let articleOut = null;

		switch (props.type) {
			case "text-left-onethird":
				articleOut = (
					<ArticleNew>
						<div
							className="section-content bigger left"
							style={{ pointerEvents: "none" }}
						>
							<div className="section-content-item-half left one-third">
								{subheaderNew(header)}
								{descriptionNew(desc)}
							</div>

							{imageRenderNew(image)}
						</div>
					</ArticleNew>
				);
				break;
			case "text-right-onethird":
				articleOut = (
					<ArticleNew>
						<div
							className="section-content bigger"
							style={{ pointerEvents: "none" }}
						>
							{imageRenderNew(image)}
							<div className="section-content-item-half-copy right one-third">
								{subheaderNew(header)}
								{descriptionNew(desc)}
							</div>
						</div>
					</ArticleNew>
				);
				break;
			case "text-left-half":
				articleOut = (
					<ArticleNew>
						<div
							className="section-content bigger left"
							style={{ pointerEvents: "none" }}
						>
							<div className="section-content-item-half left">
								{subheaderNew(header)}
								{descriptionNew(desc)}
							</div>

							{imageRenderNew(image)}
						</div>
					</ArticleNew>
				);
				break;
			case "text-right-half":
				articleOut = (
					<ArticleNew>
						<div
							className="section-content bigger"
							style={{ pointerEvents: "none" }}
						>
							{imageRenderNew(image)}
							<div className="section-content-item-half right">
								{subheaderNew(header)}
								{descriptionNew(desc)}
							</div>
						</div>
					</ArticleNew>
				);
				break;
			default:
				break;
		}
		return articleOut;
	};

	return <Element />;
};

export default React.memo(ArticleEl);
