import React, { useEffect, useState, useLayoutEffect } from "react";

const Tag = ({ type, txt }) => {
	return (
		<span
			className={`${
				{
					noBg: "tag-span no-bg",
					more: "tag-span more no-bg",
					moreAbsolute: "tag-span more no-bg absolute",
					bg: "tag-span",
					work: "tag-span icon work",
					solution: "tag-span icon",
				}[type]
			}`}
			style={{ pointerEvents: "none" }}
		>
			{txt}
			{type == "more" || type == "moreAbsolute" ? (
				<span className={"more"} style={{ opacity: 0.5 }}>
					{" "}
					more
				</span>
			) : null}
		</span>
	);
};

const TagContainer = React.forwardRef((props, ref) => {
	const [refresh, setRefresh] = useState(Date.now);

	useEffect(() => {
		window.addEventListener("resize", () => {
			setRefresh(Date.now());
		});

		return () => {
			window.removeEventListener("resize", () => {
				setRefresh(Date.now());
			});
		};
	}, []);

	useLayoutEffect(() => {
		let allChildren = [];
		ref?.current?.children?.forEach((x) => {
			allChildren.push(x);
		});
		if (allChildren.length > 0) {
			let nextLine = 0;
			let lastTop = -1;

			allChildren.forEach((x, i) => {
				const rect = x.getBoundingClientRect();

				const top = rect.top;
				if (top > lastTop) {
					nextLine++;
					lastTop = top;
				}
				x.setAttribute("data-line", nextLine);
				x.setAttribute("data-width", rect.width);

				if (i === allChildren.length - 1) {
					x.setAttribute("data-more", "absolute"); // more button is last item, set data-line -1
				} else if (i === allChildren.length - 2) {
					x.setAttribute("data-more", "inline"); // more button is last item, set data-line -1
				}
			});
		}
		props.refreshVisibility();
	}, [refresh, props.solution]);

	return (
		<div
			key={`tag-container-${props.solution?.name}`}
			className="section-tag-container article"
			ref={ref}
		>
			<Tag
				key={`tag-type-${props.solution?.name}`}
				type={props.solution?.type}
				txt={props.solution?.type == "solution" ? "solution" : "work"}
			/>
			{props.solution?.tags?.split(",").map((x, i) => {
				return (
					<Tag
						key={`tag-${i}-${x}`}
						type={x?.trimStart().startsWith("#") ? "noBg" : "bg"}
						txt={x}
					/>
				);
			})}
			<Tag
				key={`tag-more-${props.solution?.shorthand}-relative`}
				type={"more"}
				txt={"..."}
			/>
			<Tag
				key={`tag-more-${props.solution?.shorthand}-absolute`}
				type={"moreAbsolute"}
				txt={"..."}
			/>
		</div>
	);
});

export default TagContainer;
