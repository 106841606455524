import React, { useRef, useEffect } from "react";
import { gsap, TimelineMax } from "gsap";

const LandingBtn = React.forwardRef((props, ref) => {
	const tl = useRef();
	const elOne = useRef();
	const elTwo = useRef();
	const elThree = useRef();

	useEffect(() => {
		gsap.killTweensOf([elOne.current, elTwo.current, elThree.current]);
		// console.log("landing-btn mount");
		gsap.set([elOne.current, elTwo.current], { opacity: 0, yPercent: -50 });

		tl.current = new TimelineMax({ paused: true, repeat: -1, delay: 2 })
			.to(elThree.current, 0.5, { delay: 2, opacity: 0, yPercent: 50 })
			.to(elOne.current, 1, { opacity: 1, yPercent: 0 }, "-=0.5")
			.to(elOne.current, 0.5, { delay: 2, opacity: 0, yPercent: 50 })
			.to(elTwo.current, 1, { opacity: 1, yPercent: 0 }, "-=0.5")
			.to(elTwo.current, 0.5, { delay: 2, opacity: 0, yPercent: 50 })
			.to(
				elThree.current,
				1,
				{
					startAt: { yPercent: -50, opacity: 0 },
					opacity: 1,
					yPercent: 0,
				},
				"-=0.5"
			);

		gsap.set([elThree.current], {
			opacity: 1,
			yPercent: 0,
			onComplete: () => {
				tl.current.play();
			},
		});
	}, []);

	return (
		<div ref={ref} className="landing-btn w-clearfix">
			<div className="landing-btn-fixed">Real-time for</div>
			<div className="lading-btn-float-container">
				<div ref={elOne} className="landing-btn-float-el">
					Filmmakers
				</div>

				<div ref={elTwo} className="landing-btn-float-el">
					Marketeers
				</div>
				<div ref={elThree} className="landing-btn-float-el">
					Creatives
				</div>
			</div>
		</div>
	);
});

export default React.memo(LandingBtn);
