import React, { Component, createRef } from "react";
import { useEffectOnce, destroyVideo } from "../hooks/useEffectOnce";
import "video-react/dist/video-react.css";
export default class PlayerControlExample extends Component {
  constructor(props, context) {
    super(props, context);
    this.newRef = React.createRef();

    this.state = { video: null };

    this.onResize = this.onResize.bind(this);
    this.panVideo = this.panVideo.bind(this);
    this.videoPlayer = createRef();

    this.onDurationChange = this.onDurationChange.bind(this);
    this.onTimeUpdate = this.onTimeUpdate.bind(this);
    this.onSuspend = this.onSuspend.bind(this);

    this.scale = { w: -1, h: -1 };
    this.curView = -1;
    this.duration = -1;
    this.initPlay = false;

    this.lowBat = false;
  }

  componentDidMount() {
    console.log("debug mount videoPlayer");

    /*    window.addEventListener("resize", this.onResize);
    this.onResize();*/
    const isFirefox = typeof InstallTrigger !== "undefined";
    console.log("isFirefox", isFirefox);
    setTimeout(() => {
      this.videoPlayer.current.src = !isFirefox
        ? //default use 4k download file from vimeo.com
          "https://player.vimeo.com/external/475426634.source.mp4?s=d506204ce83cd5c26fa07f38cc5dd7eb5bc4173d&download=1"
        : //if firefox use HD streaming file
          "https://player.vimeo.com/external/475426634.hd.mp4?s=4a32a185c092bd442d0c37d3eeb02c0311f103bf&profile_id=175";
    }, 500);
  }

  onTimeUpdate(e) {
    const curTime = this.videoPlayer.current.currentTime;
    if (this.duration > 0 && curTime + 0.5 > this.duration) {
      // console.log("loop", curTime);
      this.videoPlayer.current.currentTime = 0;
    }
    if (!this.initPlay) {
      this.initPlay = true;
      this.props.initPlay();
    }
  }

  onDurationChange(e) {
    const duration = e.target.duration;
    if (!isNaN(duration) && duration > 2) {
      this.duration = duration;
    }
  }

  onSuspend() {
    this.lowBat = true;
  }
  panVideo() {
    const scaleW = this.scale.w;
    const scaleH = this.scale.h;

    switch (this.curView) {
      case 1: {
        this.videoPlayer.current.style.transform = `translateX(+${
          scaleW / 2
        }px) translateY(+${scaleH / 2}px)`;
        this.videoPlayer.current.style.webkitTransform = `translateX(+${
          scaleW / 2
        }px) translateY(+${scaleH / 2}px)`;
        break;
      }
      case 2: {
        this.videoPlayer.current.style.transform = `translateX(-${
          scaleW / 2
        }px) translateY(+${scaleH / 2}px)`;
        this.videoPlayer.current.style.webkitTransform = `translateX(-${
          scaleW / 2
        }px) translateY(+${scaleH / 2}px)`;
        break;
      }
      case 3: {
        this.videoPlayer.current.style.transform = `translateX(+${
          scaleW / 2
        }px) translateY(-${scaleH / 2}px)`;
        this.videoPlayer.current.style.webkitTransform = `translateX(+${
          scaleW / 2
        }px) translateY(-${scaleH / 2}px)`;
        //statements;
        break;
      }
      case 4: {
        this.videoPlayer.current.style.transform = `translateX(-${
          scaleW / 2
        }px) translateY(-${scaleH / 2}px)`;
        this.videoPlayer.current.style.webkitTransform = `translateX(-${
          scaleW / 2
        }px) translateY(-${scaleH / 2}px)`;
        break;
      }
      default: {
        //statements;
        break;
      }
    }
  }

  onResize() {
    const el = this.videoPlayer.current;
    // window size
    const ww = this.props.windowProps.ww;
    const wh = this.props.windowProps.wh;

    // video size
    const vw = 1920;
    const vh = 1080;
    // scale favouring width or height, whatever is smaller
    let scale = ww / vw;
    if (vh * scale < wh) scale = wh / vh;
    // new size
    const nw = vw * scale + 16;
    const nh = vh * scale + 9;

    el.width = nw * 2;
    el.height = nh * 2;

    // scale to screen

    const marginh = (wh - nh) / 2;
    const marginw = (ww - nw) / 2;

    // center
    el.style.marginLeft = marginw;
    el.style.marginTop = marginh;

    // pan video left/right

    this.scale = { w: nw, h: nh };
    this.panVideo();
  }

  componentWillUnmount() {
    // console.log("debug unmount videoPlayer");
    destroyVideo(this.videoPlayer.current);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState !== this.state) {
    }

    if (this.props !== prevProps) {
      if (this.props.windowProps !== prevProps.windowProps) {
        this.onResize();
      }

      if (this.props.forceplay !== prevProps.forceplay) {
        this.videoPlayer.current.play();
      }

      if (this.props.forceHold !== prevProps.forceHold) {
        if (this.props.forceHold) {
          this.videoPlayer.current.pause();
        } else {
          this.videoPlayer.current.play();
        }
      }
      if (this.props.view !== prevProps.view) {
        // console.log(this.props.view);

        const curView = this.props.view;

        if (typeof curView !== "undefined") {
          this.curView = parseInt(curView, 10);
          // console.log(curView);
          this.panVideo();
        }
      }
    }
  }

  render() {
    return (
      <video
        onDurationChange={this.onDurationChange}
        onTimeUpdate={this.onTimeUpdate}
        onSuspend={this.onSuspend}
        style={{ position: "absolute" }}
        playsInline
        muted
        autoPlay
        loop
        ref={this.videoPlayer}
      ></video>
    );
  }
}
