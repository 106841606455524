import React from "react";

import ArtileEl, {
	Pink,
	White,
	Desc,
	Header,
	CustomImage,
} from "../../components/Elements/ArticleEl";

import cases from "../cases";

const componentName = "CampariFelliniForward";

const shorthand = cases.find((x) => x.component == componentName).shorthand;

if (typeof shorthand == "undefined")
	console.error("Component can't find shorthand: " + componentName);
export default () => (
	<>
		<div className="section-div" style={{}}>
			<div className="section-content">
				<div className="section-hero">
					<div
						className="section-title-sub grad-txt"
						style={{ paddingLeft: 0, paddingRight: 0 }}
					>
						"A filmmaking icon meets artificial intelligence"
					</div>
				</div>
			</div>
		</div>
		<div className="section-div no-p">
			<div
				className="section-img"
				style={{
					// maxWidth: "1920px",
					paddingTop: "0px",
					marginTop: "0px",
				}}
			>
				<img
					src={`/img/cases/${shorthand}/image/img_02.jpg`}
					style={{ marginTop: 40, borderRadius: 15, width: "100%" }}
				/>
			</div>
		</div>
		<div
			className="section-div"
			style={{
				// maxWidth: "1920px",
				// paddingTop: "0.8em",
				paddingTop: 0,
			}}
		>
			<div className="section-content">
				<div className="section-description">
					Fellini Forward is a unique art experiment and as part of
					the Campari Red Dairies series, it set out to create a short
					film in collaboration between human and AI, built upon the
					creative genius of one of the greatest filmmakers of the
					20th Century, Federico Fellini. It took two years of
					development and independent validation by AI experts and
					members of Fellini's original crew to finally be released
					during a screening in Venice.
				</div>
			</div>
		</div>
		<div className="section-div">
			<div className="section-content">
				<div className="section-hero">
					<div className="section-title-sub grad-txt">
						Ping-pong collaboration.
					</div>
				</div>
				<div className="section-description">
					The creation process involved a dynamic and collaborative
					exchange between human creativity and a specially developed
					AI toolkit. We were responsible to work alongside the AI to
					craft the script, capture hours of motion data to feed into
					the system, and finally direct the AI's decision-making
					during a live-action shoot in Rome.
				</div>
			</div>
			<div
				className="section-img"
				style={{
					// maxWidth: "1920px",
					paddingTop: "0px",
					marginTop: "0px",
				}}
			>
				<img
					src={`/img/cases/${shorthand}/image/img_03.jpg`}
					style={{ marginTop: 40, borderRadius: 15, width: "100%" }}
				/>
			</div>
		</div>
		<div className="section-div">
			<div className="section-content">
				<div className="section-hero">
					<div className="section-title-sub grad-txt">
						AI human collaboration.
						<br />
						Award winning.
						<br />
						2.1 Bln impressions.
					</div>
				</div>
				<div className="section-description">
					The project received a wide news echo and was multiple
					awarded for it's creative approach and innovative use of
					artificial intelligence.
				</div>
			</div>
			<div
				className="section-img"
				style={{
					// maxWidth: "1920px",
					paddingTop: "0px",
					marginTop: "0px",
				}}
			>
				<img
					src={`/img/cases/${shorthand}/image/img_04.jpg`}
					style={{ marginTop: 40, borderRadius: 15, width: "100%" }}
				/>
			</div>
		</div>
	</>
);
