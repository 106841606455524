const solutions = [
	{
		name: "Stunnıng. 'Introduction'",
		shorthand: "stunning-introduction",
		description: "Test",
		component: "StunningIntroduction",
		type: "work",
		tags: "unreal engine,motion capture,AI,realtime,#xsens,#audio2face,#lumen",
		"search-tags": "",
		"vid-title-00": "",
		"vid-title-01": "",
		"vid-title-02": "",
		"full-access": "",
		framerate: "",
		"custom-loop": "",
		"poster-portrait-900p": "stunning-introduction-poster-portrait-900p",
		"poster-portrait-2160p": "",
		"poster-landscape-1600p":
			"stunning-introduction-poster-landscape-1600p",
		"poster-landscape-2160p": "",
		"vimeo-portrait-pr": "https://vimeo.com/799402675",
		"portrait-pr-720p":
			"https://player.vimeo.com/progressive_redirect/download/799402675/rendition/720p/stunning-introduction_-_portrait_-_preview%20%28720p%29.mp4?loc=external&signature=76cfb5779c63d73268392abc8c8ce35b9ac6e8e9d70672b629e77033348549e1",
		"portrait-pr-1080p":
			"https://player.vimeo.com/progressive_redirect/download/799402675/rendition/1080p/stunning-introduction_-_portrait_-_preview%20%281080p%29.mp4?loc=external&signature=0de4fc2d4e4a34eaafccad1b1855ab0fe9b14ccb8a148cda9942a5e686d0c5c2",
		"portrait-pr-1440p":
			"https://player.vimeo.com/progressive_redirect/download/799402675/rendition/1440p/stunning-introduction_-_portrait_-_preview%20%281440p%29.mp4?loc=external&signature=f1dc59b2458fc07f5c0806a77197feef941c76eb43e8b877635d243a6e5c59a8",
		"portrait-pr-2160p":
			"https://player.vimeo.com/progressive_redirect/download/799402675/rendition/2160p/stunning-introduction_-_portrait_-_preview%20%282160p%29.mp4?loc=external&signature=73619de3f783d48a8d19b2814e450523423ad77782363d44deb8b246933aaa42",
		"vimeo-portrait": "",
		"portrait-720p": "",
		"portrait-1080p": "",
		"portrait-1440p": "",
		"portrait-2160p": "",
		"vimeo-landscape-pr": "https://vimeo.com/799126696",
		"landscape-pr-720p":
			"https://player.vimeo.com/progressive_redirect/download/799126696/rendition/720p/stunning-introduction_-_landscape_-_preview%20%28720p%29.mp4?loc=external&signature=c69502874af6429d2ec1306af31ddddbb17e3453c199d2d4d16268d2a4c7f35f",
		"landscape-pr-1080p":
			"https://player.vimeo.com/progressive_redirect/download/799126696/rendition/1080p/stunning-introduction_-_landscape_-_preview%20%281080p%29.mp4?loc=external&signature=d9260a03e5a1fdd0148c65db4c0546cf889e4037d292db7ffc3f035d4cd1e2cf",
		"landscape-pr-1440p":
			"https://player.vimeo.com/progressive_redirect/download/799126696/rendition/1440p/stunning-introduction_-_landscape_-_preview%20%281440p%29.mp4?loc=external&signature=e4eca766748ddb6f4f1ca31f041d66a55a2ce75a0ea027d9f3a87d436624e4b4",
		"landscape-pr-2160p":
			"https://player.vimeo.com/progressive_redirect/download/799126696/rendition/2160p/stunning-introduction_-_landscape_-_preview%20%282160p%29.mp4?loc=external&signature=083806f9c0095aa434933494a02fa89d613ef4f0a171124eeac1d66f8f28473b",
		"vimeo-landscape": "",
		"landscape-720p": "",
		"landscape-1080p": "",
		"landscape-1440p": "",
		"landscape-2160p": "",
		"vimeo-film": "https://vimeo.com/797750493",
		"film-720p":
			"https://player.vimeo.com/progressive_redirect/download/797750493/rendition/720p/stunn%C4%B1ng._%27introduction%27%20%28720p%29.mp4?loc=external&signature=99c94913b5b4cde9d4292f241835f9eeed7128fe83ac9d603f518bf6ff66271d",
		"film-1080p":
			"https://player.vimeo.com/progressive_redirect/download/797750493/rendition/1080p/stunn%C4%B1ng._%27introduction%27%20%281080p%29.mp4?loc=external&signature=f15e4b8ecd4c3318e887c536c13c45ec9f5e3f102f77f64a8e2f731e8a6ab532",
		"film-1440p":
			"https://player.vimeo.com/progressive_redirect/download/797750493/rendition/1440p/stunn%C4%B1ng._%27introduction%27%20%281440p%29.mp4?loc=external&signature=570fba14dbcc4fe181e1addd6278df734ef93b4cafc1309bed04fad401c5a4b1",
		"film-2160p":
			"https://player.vimeo.com/progressive_redirect/download/797750493/rendition/2160p/stunn%C4%B1ng._%27introduction%27%20%282160p%29.mp4?loc=external&signature=c1ced81eb6daaea8eb1d120b1ce3b9e1d169f7be9a38d57a5501443466858135",
		url: "",
	},
	{
		name: "Vodafone 'Elton John'",
		shorthand: "vodafone-elton-john",
		description: "Test2",
		component: "VodafoneEltonJohn",
		type: "work",
		tags: "live event, music, unreal engine, #AR, #VR, #dance, #concert",
		"search-tags": "",
		"vid-title-00": "",
		"vid-title-01": "",
		"vid-title-02": "",
		"full-access": "full-access",
		framerate: "",
		"custom-loop": "",
		"poster-portrait-900p": "vodafone-elton-john-poster-portrait-900p",
		"poster-portrait-2160p": "",
		"poster-landscape-1600p": "vodafone-elton-john-poster-landscape-1600p",
		"poster-landscape-2160p": "",
		"vimeo-portrait-pr": "https://vimeo.com/799992973",
		"portrait-pr-720p":
			"https://player.vimeo.com/progressive_redirect/download/799992973/rendition/720p/eltonjohn_-_portrait_-_preview%20%28720p%29.mp4?loc=external&signature=6d89cf0fb254597848d7760a65b0da5038f56c4233bc2e207d05270dd279fbed",
		"portrait-pr-1080p":
			"https://player.vimeo.com/progressive_redirect/download/799992973/rendition/1080p/eltonjohn_-_portrait_-_preview%20%281080p%29.mp4?loc=external&signature=0925427dd97dd584c9dba5d05ce85b54b3b6ce215b2fc6e110c035b2edb926dc",
		"portrait-pr-1440p":
			"https://player.vimeo.com/progressive_redirect/download/799992973/rendition/1440p/eltonjohn_-_portrait_-_preview%20%281440p%29.mp4?loc=external&signature=12b6aace2f6e5c8fc7605406dd53728c1a49911822afdc6f1f514526f9aaaff4",
		"portrait-pr-2160p":
			"https://player.vimeo.com/progressive_redirect/download/799992973/rendition/2160p/eltonjohn_-_portrait_-_preview%20%282160p%29.mp4?loc=external&signature=8798ad5c7c8ecfe5c2f3fa2dad69ff31592eeb7c4cc83a50e69e58f226cb1739",
		"vimeo-portrait": "",
		"portrait-720p": "",
		"portrait-1080p": "",
		"portrait-1440p": "",
		"portrait-2160p": "",
		"vimeo-landscape-pr": "https://vimeo.com/799992955",
		"landscape-pr-720p":
			"https://player.vimeo.com/progressive_redirect/download/799992955/rendition/720p/eltonjohn_-_landscape_-_preview%20%28720p%29.mp4?loc=external&signature=91b5a460e171b68380b4fd5571260d3d6f32bdda9ba900ed18a8d2bf6de94624",
		"landscape-pr-1080p":
			"https://player.vimeo.com/progressive_redirect/download/799992955/rendition/1080p/eltonjohn_-_landscape_-_preview%20%281080p%29.mp4?loc=external&signature=5447fa35eaf715f84c3169a51f43e0e95bda3371a438b1a298e27d339152a58b",
		"landscape-pr-1440p":
			"https://player.vimeo.com/progressive_redirect/download/799992955/rendition/1440p/eltonjohn_-_landscape_-_preview%20%281440p%29.mp4?loc=external&signature=7a5fa2929328ba671fab7672c86856ec42e5745c3c0a33b91be72e2b7458e187",
		"landscape-pr-2160p":
			"https://player.vimeo.com/progressive_redirect/download/799992955/rendition/2160p/eltonjohn_-_landscape_-_preview%20%282160p%29.mp4?loc=external&signature=4b6b7dfc655b8b3e238aca2680c46c07228cb4eb36c6996a4b62c01b26acdf25",
		"vimeo-landscape": "",
		"landscape-720p": "",
		"landscape-1080p": "",
		"landscape-1440p": "",
		"landscape-2160p": "",
		"vimeo-film": "https://vimeo.com/810852247",
		"film-720p":
			"https://player.vimeo.com/progressive_redirect/download/810852247/rendition/720p/vodafone-elton-john_%7C_full_length_uhd%20%28720p%29.mp4?loc=external&signature=d0fccb22eb1383c126b038676faf72517885755d1cfea1c91105997e951eb248",
		"film-1080p":
			"https://player.vimeo.com/progressive_redirect/download/810852247/rendition/1080p/vodafone-elton-john_%7C_full_length_uhd%20%281080p%29.mp4?loc=external&signature=dfc54105662ae1944aad840ac74ef5a7883f8a173dec5bf38e4b5208b6a0b963",
		"film-1440p":
			"https://player.vimeo.com/progressive_redirect/download/810852247/rendition/1440p/vodafone-elton-john_%7C_full_length_uhd%20%281440p%29.mp4?loc=external&signature=586074522ccb1ea9cd9d117212c0ef8e5873e67b47b8fb9cbee26cfdbcb3e396",
		"film-2160p":
			"https://player.vimeo.com/progressive_redirect/download/810852247/rendition/2160p/vodafone-elton-john_%7C_full_length_uhd%20%282160p%29.mp4?loc=external&signature=d6e5d9e67593c59132d795571c40c8751e50a265db2520cc645d03e52d8738cd",
		url: "",
	},
	{
		name: "Motion Capture",
		shorthand: "motion-capture",
		description:
			"A fancy game of dress-up for grown-ups, where actors wear sensors that record their every move - transforming them into virtual superheroes.",
		component: "MotionCapture",
		type: "solution",
		tags: "Hyper-realistc, Xsens, Animation,real-time,shooting,#in-house,#dance,#performance,#location independent",
		"search-tags": "animation,realtime,shooting",
		"vid-title-00": "Motion Capture",
		"vid-title-01": "Animatic",
		"vid-title-02": "Final Result",
		"full-access": "full-access",
		framerate: 30,
		"custom-loop": 113,
		"poster-portrait-900p": "motion-capture-poster-portrait-900p",
		"poster-portrait-2160p": "",
		"poster-landscape-1600p": "motion-capture-poster-landscape-1600p",
		"poster-landscape-2160p": "",
		"vimeo-portrait-pr": "",
		"portrait-pr-720p": "",
		"portrait-pr-1080p": "",
		"portrait-pr-1440p": "",
		"portrait-pr-2160p": "",
		"vimeo-portrait": "https://vimeo.com/789972428",
		"portrait-720p":
			"https://player.vimeo.com/progressive_redirect/playback/789972428/rendition/720p/file.mp4?loc=external&signature=985d5d46ef09d1064f7a65a074bb7da0bbadcf046cee86e4b2ba799725c5f268",
		"portrait-1080p":
			"https://player.vimeo.com/progressive_redirect/download/789972428/rendition/1080p/motion-capture-portrait%20%281080p%29.mp4?loc=external&signature=7843d53cbfbf1713d57ef23d684bd7d3cf0e580e4d83f34cf2a433c4262f72ef",
		"portrait-1440p":
			"https://player.vimeo.com/progressive_redirect/download/789972428/rendition/1440p/motion-capture-portrait%20%281440p%29.mp4?loc=external&signature=2487cc53c4cd7afa16445b346556443aa22095ece3f2eece34f7867356518326",
		"portrait-2160p":
			"https://player.vimeo.com/progressive_redirect/download/789972428/rendition/2160p/motion-capture-portrait%20%282160p%29.mp4?loc=external&signature=c2ff57731dc70576ec032f0a75139d4777aae9cc2546addb45466146f4e73e86",
		"vimeo-landscape-pr": "https://vimeo.com/792048890",
		"landscape-pr-720p":
			"https://player.vimeo.com/progressive_redirect/download/792048890/rendition/720p/motion-captur-landscape-preview_v06%20%28720p%29.mp4?loc=external&signature=8cd61e064638b89fb426af91e847a3e9b85290215bd8d3c5f5821a2d9863b614",
		"landscape-pr-1080p":
			"https://player.vimeo.com/progressive_redirect/download/792048890/rendition/1080p/motion-captur-landscape-preview_v06%20%281080p%29.mp4?loc=external&signature=27e8d65defcec4c423dd697b243c97ac240e1ea5925e27b68e94c0a2b668f10a",
		"landscape-pr-1440p":
			"https://player.vimeo.com/progressive_redirect/download/792048890/rendition/1440p/motion-capture-landscape-preview%20%281440p%29.mp4?loc=external&signature=760397cb3edc90c0b2bc08aa6a041237de72b02b88fc2642043d30dab0c2d8b7",
		"landscape-pr-2160p":
			"https://player.vimeo.com/progressive_redirect/download/792048890/rendition/2160p/motion-captur-landscape-preview_v06%20%282160p%29.mp4?loc=external&signature=d2bd1796c67069f01e12f88795081305b32f0200bba47fc6f1d035cff2bad241",
		"vimeo-landscape": "https://vimeo.com/791171806",
		"landscape-720p":
			"https://player.vimeo.com/progressive_redirect/download/791171806/rendition/720p/motion-capture-landscape-v06%20%28720p%29.mp4?loc=external&signature=43f43a00df4d5292f5fdd6d1ca7eb7b35d6013b63b30830a1ed78ff7f13fde2b",
		"landscape-1080p":
			"https://player.vimeo.com/progressive_redirect/download/791171806/rendition/1080p/motion-capture-landscape-v06%20%281080p%29.mp4?loc=external&signature=bc00a7938b8a0d9374116ff20a0a022bf6a2789c56d6227ab76ca0513e881f10",
		"landscape-1440p":
			"https://player.vimeo.com/progressive_redirect/download/791171806/rendition/1440p/motion-capture-landscape-v06%20%281440p%29.mp4?loc=external&signature=de28039f5e4d602a5c31efa6a1cce105a2ec7951c6e8dc468eb60c7157e2cc08",
		"landscape-2160p":
			"https://player.vimeo.com/progressive_redirect/download/791171806/rendition/2160p/motion-capture-landscape-v06%20%282160p%29.mp4?loc=external&signature=998c34c91ddc21989b9cc32c51b2e1f399c1c7b7d11cc84fe77955cfd597ffd3",
		"vimeo-film": "",
		"film-720p": "",
		"film-1080p": "",
		"film-1440p": "",
		"film-2160p": "",
		url: "",
	},
	{
		name: "Nike 'Virtual Influencer'",
		shorthand: "nike-virtual-influencer",
		description: "Test3",
		component: "ShowcaseVirtualInfluencer",
		type: "work",
		tags: "social media, trends, unreal engine,#motion capture,#realtime,#advertising",
		"search-tags": "",
		"vid-title-00": "",
		"vid-title-01": "",
		"vid-title-02": "",
		"full-access": "",
		framerate: "",
		"custom-loop": "",
		"poster-portrait-900p": "nike-virtual-influencer-poster-portrait-900p",
		"poster-portrait-2160p": "",
		"poster-landscape-1600p":
			"nike-virtual-influencer-poster-landscape-1600p",
		"poster-landscape-2160p": "",
		"vimeo-portrait-pr": "https://vimeo.com/799470451",
		"portrait-pr-720p":
			"https://player.vimeo.com/progressive_redirect/download/799470451/rendition/720p/showcase-virtual-influencer_-_portrait_-_preview%20%28720p%29.mp4?loc=external&signature=b53291322ed27dddc92e52234d25b93de5c883a7e4212c0dec4704e3a112c8af",
		"portrait-pr-1080p":
			"https://player.vimeo.com/progressive_redirect/download/799470451/rendition/1080p/showcase-virtual-influencer_-_portrait_-_preview%20%281080p%29.mp4?loc=external&signature=ca18b64fc90719f6c65109fea29565d5f0ff6276b43f950dc929a6103ec60193",
		"portrait-pr-1440p":
			"https://player.vimeo.com/progressive_redirect/download/799470451/rendition/1440p/showcase-virtual-influencer_-_portrait_-_preview%20%281440p%29.mp4?loc=external&signature=5a081c3b167b4e2a629f7fc902914d537d0bfdcec67084a0b5bba03e3ace9687",
		"portrait-pr-2160p":
			"https://player.vimeo.com/progressive_redirect/download/799470451/rendition/2160p/showcase-virtual-influencer_-_portrait_-_preview%20%282160p%29.mp4?loc=external&signature=227175e3b2c14862c99dee5ececcdf2663ec4b3ee9d2de39ca9c527996d11af9",
		"vimeo-portrait": "",
		"portrait-720p": "",
		"portrait-1080p": "",
		"portrait-1440p": "",
		"portrait-2160p": "",
		"vimeo-landscape-pr": "https://vimeo.com/799470347",
		"landscape-pr-720p":
			"https://player.vimeo.com/progressive_redirect/download/799470347/rendition/720p/showcase-virtual-influencer_-_landscape_-_preview%20%28720p%29.mp4?loc=external&signature=9bcca8bbd4479973a6020bd86cf4f2c69060b14bcee70c2626a323c4c0563d39",
		"landscape-pr-1080p":
			"https://player.vimeo.com/progressive_redirect/download/799470347/rendition/1080p/showcase-virtual-influencer_-_landscape_-_preview%20%281080p%29.mp4?loc=external&signature=9ff9a8f3c479b572a4f2964d1401bb01b7a428524e8a5d9a1afd7c86646b0a6d",
		"landscape-pr-1440p":
			"https://player.vimeo.com/progressive_redirect/download/799470347/rendition/1440p/showcase-virtual-influencer_-_landscape_-_preview%20%281440p%29.mp4?loc=external&signature=27731b79a73ea6ae0bf2b979ea7afe51c365364cd51421e8f5d990bdc0fc06d6",
		"landscape-pr-2160p":
			"https://player.vimeo.com/progressive_redirect/download/799470347/rendition/2160p/showcase-virtual-influencer_-_landscape_-_preview%20%282160p%29.mp4?loc=external&signature=140a0a498218d3e812badfdb21e9291f3f18a6447f7bce9780120fd33927bd65",
		"vimeo-landscape": "",
		"landscape-720p": "",
		"landscape-1080p": "",
		"landscape-1440p": "",
		"landscape-2160p": "",
		"vimeo-film": "https://vimeo.com/799470108",
		"film-720p":
			"https://player.vimeo.com/progressive_redirect/download/799470108/rendition/720p/showcase_%27virtual_influencer%27_-_film%20%28720p%29.mp4?loc=external&signature=92612fb2980b014b1c5ff7c5c3f525c06dfe79f181367506293efa54fe5cd687",
		"film-1080p":
			"https://player.vimeo.com/progressive_redirect/download/799470108/rendition/1080p/showcase_%27virtual_influencer%27_-_film%20%281080p%29.mp4?loc=external&signature=fc4e7d3c5d5f523f57231eb6cf30edc579e09823b9041f303bc29704043e3762",
		"film-1440p":
			"https://player.vimeo.com/progressive_redirect/download/799470108/rendition/1440p/showcase_%27virtual_influencer%27_-_film%20%281440p%29.mp4?loc=external&signature=df9c03489f3dc750c28c0ad57b85a41e0e7cbf350fa5a605b0c064289bce6e2d",
		"film-2160p":
			"https://player.vimeo.com/progressive_redirect/download/799470108/rendition/2160p/showcase_%27virtual_influencer%27_-_film%20%282160p%29.mp4?loc=external&signature=58a4ed0e41635eb6659400614c276c8c14f6e14e8034ed47ebbbeba16083bf15",
		url: "",
	},
	{
		name: "Social Turnaround",
		shorthand: "social-turnaround",
		description:
			"Boost your online presence. Think of it as a virtual facelift for your online presence, without the needles and pain. Revive your online presence like a phoenix rising from the ashes and watch your followers multiply faster than bunnies on Easter!",
		component: "SocialTurnaround",
		type: "solution",
		tags: "Fast, social,trends,influencer,#all platforms,#hype,#engagement",
		"search-tags": "social,trends,influencer",
		"vid-title-00": "Briefing",
		"vid-title-01": "Motion Capture",
		"vid-title-02": "Final Result",
		"full-access": "",
		framerate: 30,
		"custom-loop": 218,
		"poster-portrait-900p": "social-turnaround-poster-portrait-900p",
		"poster-portrait-2160p": "",
		"poster-landscape-1600p": "social-turnaround-poster-landscape-1600p",
		"poster-landscape-2160p": "",
		"vimeo-portrait-pr": "",
		"portrait-pr-720p": "",
		"portrait-pr-1080p": "",
		"portrait-pr-1440p": "",
		"portrait-pr-2160p": "",
		"vimeo-portrait": "https://vimeo.com/790406261",
		"portrait-720p":
			"https://player.vimeo.com/progressive_redirect/download/790406261/rendition/720p/social-media-portrait%20%28720p%29.mp4?loc=external&signature=14caec531a15ec4cfabf87dcad4679fd63a92b6feb6cb18c3ad94ba19fdd7d95",
		"portrait-1080p":
			"https://player.vimeo.com/progressive_redirect/download/790406261/rendition/1080p/social-media-portrait%20%281080p%29.mp4?loc=external&signature=b31518560ce7278a8a90bdd668956dbbe609fb9d111f3b5f64cb4a233db382b8",
		"portrait-1440p":
			"https://player.vimeo.com/progressive_redirect/download/790406261/rendition/1440p/social-media-portrait%20%281440p%29.mp4?loc=external&signature=db82369f7d8c907f4a08487b1f8502796b2110520f02bd617f23e6f671231180",
		"portrait-2160p":
			"https://player.vimeo.com/progressive_redirect/download/790406261/rendition/2160p/social-media-portrait%20%282160p%29.mp4?loc=external&signature=00025fb50d7e0e83bf87abdf459bde9aaad6f5190e2ce4262b6684a747ff4035",
		"vimeo-landscape-pr": "https://vimeo.com/792048851",
		"landscape-pr-720p":
			"https://player.vimeo.com/progressive_redirect/download/792048851/rendition/720p/social-media-landscape-preview%20%28720p%29.mp4?loc=external&signature=a1ee863fbdf38d3a935bc5dad1c7a47dcac15fe449a0d731e61e25b663f4316e",
		"landscape-pr-1080p":
			"https://player.vimeo.com/progressive_redirect/download/792048851/rendition/1080p/social-media-landscape-preview%20%281080p%29.mp4?loc=external&signature=ffd0195c03df2b65be521e54043fd3238b3b73519a6e8b311bce4a3a46d473f0",
		"landscape-pr-1440p":
			"https://player.vimeo.com/progressive_redirect/download/792048851/rendition/1440p/social-media-landscape-preview%20%281440p%29.mp4?loc=external&signature=c8e3c7bfe0ef9cbda83694357e5e9149eb8b3ed8ca651e663e691d8b4f58c872",
		"landscape-pr-2160p":
			"https://player.vimeo.com/progressive_redirect/download/792048851/rendition/2160p/social-media-landscape-preview%20%282160p%29.mp4?loc=external&signature=3f1f72108bd6b81c3409dad54b940af69326f6879e7a652aadaedec951df458a",
		"vimeo-landscape": "https://vimeo.com/792048789",
		"landscape-720p":
			"https://player.vimeo.com/progressive_redirect/download/792048789/rendition/720p/social-turnaround-landscape%20%28720p%29.mp4?loc=external&signature=2489cf8277b64865e317a3535c2d3ce9b965b81f4c65f188d424d9e7f9ce65dc",
		"landscape-1080p":
			"https://player.vimeo.com/progressive_redirect/download/792048789/rendition/1080p/social-turnaround-landscape%20%281080p%29.mp4?loc=external&signature=e2106b9f5e996a0f8596e0c1f62505644d841084502b9eed0f8f8eba46166b2c",
		"landscape-1440p":
			"https://player.vimeo.com/progressive_redirect/download/792048789/rendition/1440p/social-turnaround-landscape%20%281440p%29.mp4?loc=external&signature=4f21886ba12bd81dc8695b6ff464242d6f46a78935e0a3adedea5640c77d6648",
		"landscape-2160p":
			"https://player.vimeo.com/progressive_redirect/download/792048789/rendition/2160p/social-turnaround-landscape%20%282160p%29.mp4?loc=external&signature=a2bc847420f6806cc997f8d3f6443d4810d74ff7a8e8e1706a56d882d6ebf292",
		"vimeo-film": "",
		"film-720p": "",
		"film-1080p": "",
		"film-1440p": "",
		"film-2160p": "",
		url: "",
	},
	{
		name: "Audio2Face",
		shorthand: "audio2face",
		description:
			"Converting sound waves into facial animations in realtime! Just like magic, machine learning algorithms analyze speech patterns and turn them into corresponding facial expressions.",
		component: "Audio2Face",
		type: "solution",
		tags: "Ai,Facial Animation,Character,#Emotions,#Realtime,#Machine Learning",
		"search-tags": "",
		"vid-title-00": "Voice Recording",
		"vid-title-01": "Data Processing",
		"vid-title-02": "Final Result",
		"full-access": "",
		framerate: 30,
		"custom-loop": 0,
		"poster-portrait-900p": "audio2face-poster-portrait-900p",
		"poster-portrait-2160p": "",
		"poster-landscape-1600p": "audio2face-poster-landscape-1600p",
		"poster-landscape-2160p": "",
		"vimeo-portrait-pr": "",
		"portrait-pr-720p": "",
		"portrait-pr-1080p": "",
		"portrait-pr-1440p": "",
		"portrait-pr-2160p": "",
		"vimeo-portrait": "https://vimeo.com/795021813",
		"portrait-720p":
			"https://player.vimeo.com/progressive_redirect/download/795021813/rendition/720p/audio-2-face_-_portrait%20%28720p%29.mp4?loc=external&signature=ad73e3a66ad297a137e4f564cd8522b5c9477e2a8792b3ef00634e1902d8003d",
		"portrait-1080p":
			"https://player.vimeo.com/progressive_redirect/download/795021813/rendition/1080p/audio-2-face_-_portrait%20%281080p%29.mp4?loc=external&signature=78f78bcac8461a1f01a2e36cacf4cf29a12adc2cb74edb6f98c9ca1cac266940",
		"portrait-1440p":
			"https://player.vimeo.com/progressive_redirect/download/795021813/rendition/1440p/audio-2-face_-_portrait%20%281440p%29.mp4?loc=external&signature=c19fc3edbcd9d7ef5cd7774bc46de6d6656932b9218e5e7d95a02743b64aeac2",
		"portrait-2160p":
			"https://player.vimeo.com/progressive_redirect/download/795021813/rendition/2160p/audio-2-face_-_portrait%20%282160p%29.mp4?loc=external&signature=6202dd06ef988890706fef7a52ea53214bae6b2d0896eb9d2f03dfa54efc7557",
		"vimeo-landscape-pr": "https://vimeo.com/795021940",
		"landscape-pr-720p":
			"https://player.vimeo.com/progressive_redirect/download/795021940/rendition/720p/audio-2-face_-_landscape_-_preview%20%28720p%29.mp4?loc=external&signature=3a2e898729c94e8a77e6cc81fe4c8f22f51166db91c25dc43bed061b5aee4522",
		"landscape-pr-1080p":
			"https://player.vimeo.com/progressive_redirect/download/795021940/rendition/1080p/audio-2-face_-_landscape_-_preview%20%281080p%29.mp4?loc=external&signature=542b1bc9af2ca7e6966217a4a4b913dffc600c817d8bcad37ac07a91108aa890",
		"landscape-pr-1440p":
			"https://player.vimeo.com/progressive_redirect/download/795021940/rendition/1440p/audio-2-face_-_landscape_-_preview%20%281440p%29.mp4?loc=external&signature=338eb27b56cc82a0acbd4bd17ead1688c2a02253b8b4560815f59bb71ad3ce79",
		"landscape-pr-2160p":
			"https://player.vimeo.com/progressive_redirect/download/795021940/rendition/2160p/audio-2-face_-_landscape_-_preview%20%282160p%29.mp4?loc=external&signature=a7b68c2e92f645df0a4608c2e2af0cea3f021d45570287267e868c8e57a1cdae",
		"vimeo-landscape": "https://vimeo.com/795021884",
		"landscape-720p":
			"https://player.vimeo.com/progressive_redirect/download/795021884/rendition/720p/audio-2-face_-_landscape%20%28720p%29.mp4?loc=external&signature=d8d33339746129dfa5aff55c5f3faf1aa155571b6cb2fd208e2b77ef797b5a39",
		"landscape-1080p":
			"https://player.vimeo.com/progressive_redirect/download/795021884/rendition/1080p/audio-2-face_-_landscape%20%281080p%29.mp4?loc=external&signature=b355c5878353f1da6f1b9093ace07abb049d527f016e8a2d45c7acfcb5ffae6f",
		"landscape-1440p":
			"https://player.vimeo.com/progressive_redirect/download/795021884/rendition/1440p/audio-2-face_-_landscape%20%281440p%29.mp4?loc=external&signature=53d6c488024d1248a539c96642be0e3b38bd3bc7391e3441ee67d846b145b8c5",
		"landscape-2160p":
			"https://player.vimeo.com/progressive_redirect/download/795021884/rendition/2160p/audio-2-face_-_landscape%20%282160p%29.mp4?loc=external&signature=a576acec0c2a23d1f814aeaeaa92543c049f66a14965847e082d86ce4bfaaadf",
		"vimeo-film": "",
		"film-720p": "",
		"film-1080p": "",
		"film-1440p": "",
		"film-2160p": "",
		url: "",
	},
	{
		name: "AR VR XR",
		shorthand: "ar-vr-xr",
		description:
			"Augmented Reality, Virtual Reality, and Extended Reality are cutting-edge technologies that are changing the way we interact with the world. They offer a new level of immersion and engagement, allowing us to experience digital content in entirely new ways.",
		component: "ArVrXr",
		type: "solution",
		tags: "360° brand moments,immersive,experience,live,#personalized content, #sharebility, #gamification",
		"search-tags": "app,immersive,experience,live",
		"vid-title-00": "AR Live Experience",
		"vid-title-01": "WIP",
		"vid-title-02": "VR Experience",
		"full-access": "",
		framerate: 30,
		"custom-loop": 46,
		"poster-portrait-900p": "ar-vr-xr-poster-portrait-900p",
		"poster-portrait-2160p": "",
		"poster-landscape-1600p": "ar-vr-xr-poster-landscape-1600p",
		"poster-landscape-2160p": "",
		"vimeo-portrait-pr": "",
		"portrait-pr-720p": "",
		"portrait-pr-1080p": "",
		"portrait-pr-1440p": "",
		"portrait-pr-2160p": "",
		"vimeo-portrait": "https://vimeo.com/796269766",
		"portrait-720p":
			"https://player.vimeo.com/progressive_redirect/download/796269766/rendition/720p/ar-vr-xr-portrait%20%28720p%29.mp4?loc=external&signature=eb9b95b99e37c6c16fb787df63885367a5e6cff2d694a4c659c4b6ae3ee79ec2",
		"portrait-1080p":
			"https://player.vimeo.com/progressive_redirect/download/796269766/rendition/1080p/ar-vr-xr-portrait%20%281080p%29.mp4?loc=external&signature=8217c2c3ce8d4f7b3edffce65d1caedd17f7793ff8685a1fc12c29b9ab27cd18",
		"portrait-1440p":
			"https://player.vimeo.com/progressive_redirect/download/796269766/rendition/1440p/ar-vr-xr-portrait%20%281440p%29.mp4?loc=external&signature=44024b399a11d0d709db8765f640a7a053db4888b486bacafeb720aa17a7e02d",
		"portrait-2160p":
			"https://player.vimeo.com/progressive_redirect/download/796269766/rendition/2160p/ar-vr-xr-portrait%20%282160p%29.mp4?loc=external&signature=c99dfbfe479f124d364bb213e251ac86c5dd7b941e7a9412e1468010bb6a189d",
		"vimeo-landscape-pr": "https://vimeo.com/796329679",
		"landscape-pr-720p":
			"https://player.vimeo.com/progressive_redirect/download/796329679/rendition/720p/ar-vr-xr-landscape_-_preview%20%28720p%29.mp4?loc=external&signature=8c9a48c88e07cd3c69085e70003589d3d2db238236320660f1762e37b9e19ae4",
		"landscape-pr-1080p":
			"https://player.vimeo.com/progressive_redirect/download/796329679/rendition/1080p/ar-vr-xr-landscape_-_preview%20%281080p%29.mp4?loc=external&signature=19a90f1f23ea2baa909533bf0538ae9d4e0434d21f3db7ae51ca5790e4421e4c",
		"landscape-pr-1440p":
			"https://player.vimeo.com/progressive_redirect/download/796329679/rendition/1440p/ar-vr-xr-landscape_-_preview%20%281440p%29.mp4?loc=external&signature=6d9e038e32c7a95ca27cf7ebba7c5db389defcb6ce9e7dfa6b788eff43f1e6f0",
		"landscape-pr-2160p":
			"https://player.vimeo.com/progressive_redirect/download/796329679/rendition/2160p/ar-vr-xr-landscape_-_preview%20%282160p%29.mp4?loc=external&signature=724947a374fe99b2e97e112221fd266cf8c651b98047961bece564bbaeed4c4d",
		"vimeo-landscape": "https://vimeo.com/796269725",
		"landscape-720p":
			"https://player.vimeo.com/progressive_redirect/download/796269725/rendition/720p/ar-vr-xr-landscape%20%28720p%29.mp4?loc=external&signature=3b5694031ac1f730b04ef6ee15b62e51d782960abcaf85f6f8550370ced06be7",
		"landscape-1080p":
			"https://player.vimeo.com/progressive_redirect/download/796269725/rendition/1080p/ar-vr-xr-landscape%20%281080p%29.mp4?loc=external&signature=c5dd678444b2052b082352aba9f6afc56f7ea20f6fad44c66a755614cdb35763",
		"landscape-1440p":
			"https://player.vimeo.com/progressive_redirect/download/796269725/rendition/1440p/ar-vr-xr-landscape%20%281440p%29.mp4?loc=external&signature=3ea853564cb55c3a918a4873964db46fa604de322d57fd650c1b5a9720cef959",
		"landscape-2160p":
			"https://player.vimeo.com/progressive_redirect/download/796269725/rendition/2160p/ar-vr-xr-landscape%20%282160p%29.mp4?loc=external&signature=a9ec7df2ff2afdb4ff38ac1316bd252eb557f4a425afb6d5a0faa4e3a0f1c411",
		"vimeo-film": "",
		"film-720p": "",
		"film-1080p": "",
		"film-1440p": "",
		"film-2160p": "",
		url: "",
	},
	{
		name: "Amazon 'Take a seat'",
		shorthand: "amazon-take-a-seat",
		description: "Test6",
		component: "AmazonTakeASeat",
		type: "work",
		tags: "unreal engine,realtime,architecture,#raytracing,#virtual camera",
		"search-tags": "",
		"vid-title-00": "",
		"vid-title-01": "",
		"vid-title-02": "",
		"full-access": "",
		framerate: "",
		"custom-loop": "",
		"poster-portrait-900p": "amazon-take-a-seat-poster-portrait-900p",
		"poster-portrait-2160p": "",
		"poster-landscape-1600p": "amazon-take-a-seat-poster-landscape-1600p",
		"poster-landscape-2160p": "",
		"vimeo-portrait-pr": "https://vimeo.com/799436652",
		"portrait-pr-720p":
			"https://player.vimeo.com/progressive_redirect/download/799436652/rendition/720p/amazon-take_a_seat_-_portrait_-_preview%20%28720p%29.mp4?loc=external&signature=a86ac136d96a2084b63e66529a64f10aa95d16973b9a82d1cf855d522f45e33e",
		"portrait-pr-1080p":
			"https://player.vimeo.com/progressive_redirect/download/799436652/rendition/1080p/amazon-take_a_seat_-_portrait_-_preview%20%281080p%29.mp4?loc=external&signature=2338e7b2a9da71a3418413ca959db0c4dfaf0d9c143a52da6c419727a8e83570",
		"portrait-pr-1440p":
			"https://player.vimeo.com/progressive_redirect/download/799436652/rendition/1440p/amazon-take_a_seat_-_portrait_-_preview%20%281440p%29.mp4?loc=external&signature=15604c1d00a63e7223a77bbb260e3396bdd73d0b7a6219cb7e214b95e8a8846f",
		"portrait-pr-2160p":
			"https://player.vimeo.com/progressive_redirect/download/799436652/rendition/2160p/amazon-take_a_seat_-_portrait_-_preview%20%282160p%29.mp4?loc=external&signature=fda96f51249397a96761a8f15771e34cf9845d4bd49a45731f22a564321bd373",
		"vimeo-portrait": "",
		"portrait-720p": "",
		"portrait-1080p": "",
		"portrait-1440p": "",
		"portrait-2160p": "",
		"vimeo-landscape-pr": "https://vimeo.com/799436715",
		"landscape-pr-720p":
			"https://player.vimeo.com/progressive_redirect/download/799436715/rendition/720p/amazon-take_a_seat_-_landscape_-_preview%20%28720p%29.mp4?loc=external&signature=9552b9ddfa3a46e718181d609c02ed6c1be8849c348d60b05f9bd41cf87c4f69",
		"landscape-pr-1080p":
			"https://player.vimeo.com/progressive_redirect/download/799436715/rendition/1080p/amazon-take_a_seat_-_landscape_-_preview%20%281080p%29.mp4?loc=external&signature=1f3ab849d5408fe8264b5326cd11555361e90a6d36e60486cece51bfa592a712",
		"landscape-pr-1440p":
			"https://player.vimeo.com/progressive_redirect/download/799436715/rendition/1440p/amazon-take_a_seat_-_landscape_-_preview%20%281440p%29.mp4?loc=external&signature=b5cd913aaba4b73aa7026a4b4fc1eda2709860d6271b2a701d0226e9a0ed713b",
		"landscape-pr-2160p":
			"https://player.vimeo.com/progressive_redirect/download/799436715/rendition/2160p/amazon-take_a_seat_-_landscape_-_preview%20%282160p%29.mp4?loc=external&signature=0986374daefe69b0720670242b159ef3e4707935cf5133176f58db8e11008665",
		"vimeo-landscape": "",
		"landscape-720p": "",
		"landscape-1080p": "",
		"landscape-1440p": "",
		"landscape-2160p": "",
		"vimeo-film": "https://vimeo.com/799436372",
		"film-720p":
			"https://player.vimeo.com/progressive_redirect/download/799436372/rendition/720p/amazon_-_take_a_seat_-_film%20%28720p%29.mp4?loc=external&signature=3710d4f6b389179ffbfb0a32d37d2e9d39aa42d2e72becf4dc411d8df88ab969",
		"film-1080p":
			"https://player.vimeo.com/progressive_redirect/download/799436372/rendition/1080p/amazon_-_take_a_seat_-_film%20%281080p%29.mp4?loc=external&signature=df9444095ffcdf508102bf62a9d93a35b19d21cb4e03a83bb4aa08457229bb61",
		"film-1440p":
			"https://player.vimeo.com/progressive_redirect/download/799436372/rendition/1440p/amazon_-_take_a_seat_-_film%20%281440p%29.mp4?loc=external&signature=f32f6316fd3623b1046b39f77f4ebb982e7f68a5788d5868fa6ade0d68612ff9",
		"film-2160p":
			"https://player.vimeo.com/progressive_redirect/download/799436372/rendition/2160p/amazon_-_take_a_seat_-_film%20%282160p%29.mp4?loc=external&signature=4bcd0b55d9776fcc9b79f06b8267739e45d74f1417322832ae094a27bf29c6f0",
		url: "",
	},
	{
		name: "Mercedes 'New Frontiers'",
		shorthand: "mercedes-new-frontiers",
		description: "Test4",
		component: "MercedesNewFrontiers",
		type: "work",
		tags: "unreal engine,motion capture,#maya,#raytracing,#realtime,#virtual camera",
		"search-tags": "",
		"vid-title-00": "",
		"vid-title-01": "",
		"vid-title-02": "",
		"full-access": "",
		framerate: "",
		"custom-loop": "",
		"poster-portrait-900p": "mercedes-new-frontiers-poster-portrait-900p",
		"poster-portrait-2160p": "",
		"poster-landscape-1600p":
			"mercedes-new-frontiers-poster-landscape-1600p",
		"poster-landscape-2160p": "",
		"vimeo-portrait-pr": "https://vimeo.com/799408866",
		"portrait-pr-720p":
			"https://player.vimeo.com/progressive_redirect/download/799408866/rendition/720p/moon_portrait_preview_v02.mp4%20%28720p%29.mp4?loc=external&signature=8f1bf6216c91e1fa4ddf6df7e17865d72e13179bef820be63a2c1d28e02380fb",
		"portrait-pr-1080p":
			"https://player.vimeo.com/progressive_redirect/download/799408866/rendition/1080p/moon_portrait_preview_v02.mp4%20%281080p%29.mp4?loc=external&signature=8eeede2139018fddcf64e7454f2f02b2999f7878bdfaf847130065bc8f3bb1f2",
		"portrait-pr-1440p":
			"https://player.vimeo.com/progressive_redirect/download/799408866/rendition/1440p/moon_portrait_preview_v02.mp4%20%281440p%29.mp4?loc=external&signature=536bd29acf7d5c7a2e212edee0d47dcb4e9d7cb6827823004d72d46c1b0eb1b3",
		"portrait-pr-2160p":
			"https://player.vimeo.com/progressive_redirect/download/799408866/rendition/2160p/moon_portrait_preview_v02.mp4%20%282160p%29.mp4?loc=external&signature=e578ed3e2f62ecfda7e05040eecf6159696ee5ccf5c71e486a674f3acb0c1705",
		"vimeo-portrait": "",
		"portrait-720p": "",
		"portrait-1080p": "",
		"portrait-1440p": "",
		"portrait-2160p": "",
		"vimeo-landscape-pr": "https://vimeo.com/799262511",
		"landscape-pr-720p":
			"https://player.vimeo.com/progressive_redirect/download/799262511/rendition/720p/mercedes-moon_-_landscape_-_preview%20%28720p%29.mp4?loc=external&signature=1680cb827ab02e8a5387971c96fc8a9f5c88bf8353047805b192491f6b8193c9",
		"landscape-pr-1080p":
			"https://player.vimeo.com/progressive_redirect/download/799262511/rendition/1080p/mercedes-moon_-_landscape_-_preview%20%281080p%29.mp4?loc=external&signature=cca446259586386cfbed7d17caa586faa892ed0cd9bfdf886b9ca919a15d8ece",
		"landscape-pr-1440p":
			"https://player.vimeo.com/progressive_redirect/download/799262511/rendition/1440p/mercedes-moon_-_landscape_-_preview%20%281440p%29.mp4?loc=external&signature=2b7e1f8bb78ef50f333031621e4488d73a88c6030a78f551b0abaaaa3e565e91",
		"landscape-pr-2160p":
			"https://player.vimeo.com/progressive_redirect/download/799262511/rendition/2160p/mercedes-moon_-_landscape_-_preview%20%282160p%29.mp4?loc=external&signature=68756fd3807bfe12d96593f60615d2ba6007277216dd14a983e1a841e65d6cf0",
		"vimeo-landscape": "",
		"landscape-720p": "",
		"landscape-1080p": "",
		"landscape-1440p": "",
		"landscape-2160p": "",
		"vimeo-film": "https://vimeo.com/799261682",
		"film-720p":
			"https://player.vimeo.com/progressive_redirect/download/799261682/rendition/720p/mercedes-moon_-_film%20%28720p%29.mp4?loc=external&signature=37ebc46e10b9fcf51f5d95614dd46fc69d08bcb2afb34efc90a13c2f817c4b4c",
		"film-1080p":
			"https://player.vimeo.com/progressive_redirect/download/799261682/rendition/1080p/mercedes-moon_-_film%20%281080p%29.mp4?loc=external&signature=ab8a81f5515c3ca0b704c5357fe34ee72a0b86c37a00f68f8e62660a12e88d0c",
		"film-1440p":
			"https://player.vimeo.com/progressive_redirect/download/799261682/rendition/1440p/mercedes-moon_-_film%20%281440p%29.mp4?loc=external&signature=4437bcef54b9fd5464c7c45bc49fad1039108f5ff70bc806d4ece30e2f8466d5",
		"film-2160p":
			"https://player.vimeo.com/progressive_redirect/download/799261682/rendition/2160p/mercedes-moon_-_film%20%282160p%29.mp4?loc=external&signature=9a2b32349bf0d2a96fb483c86588838b748600e1647e4d17115dbce1181f773e",
		url: "",
	},
	{
		name: "Virtual Car Shoot",
		shorthand: "virtual-car-shoot",
		description: "Test7",
		component: "VirtualCarShoot",
		type: "solution",
		tags: "Real-time, Collaboration, Accurate physic,#No Limits,#human-operated,#live,#future-proof",
		"search-tags": "",
		"vid-title-00": "location bridge",
		"vid-title-01": "location beach",
		"vid-title-02": "location architecture",
		"full-access": "",
		framerate: "",
		"custom-loop": "",
		"poster-portrait-900p": "virtual-car-shoot-poster-portrait-900p",
		"poster-portrait-2160p": "",
		"poster-landscape-1600p": "virtual-car-shoot-poster-landscape-1600p",
		"poster-landscape-2160p": "",
		"vimeo-portrait-pr": "",
		"portrait-pr-720p": "",
		"portrait-pr-1080p": "",
		"portrait-pr-1440p": "",
		"portrait-pr-2160p": "",
		"vimeo-portrait": "https://vimeo.com/799789738",
		"portrait-720p":
			"https://player.vimeo.com/progressive_redirect/download/799789738/rendition/720p/virtual-car-shoot_portrait_v01%20%28720p%29.mp4?loc=external&signature=86128925512147087821dbf68b6a5407e48e3e3c7f15cc2fa5a83d241d6468b0",
		"portrait-1080p":
			"https://player.vimeo.com/progressive_redirect/download/799789738/rendition/1080p/virtual-car-shoot_portrait_v01%20%281080p%29.mp4?loc=external&signature=64ab540ecb58d8fbc87d291a06670e3b4a861cc2f8276ac996a07958741d8ab3",
		"portrait-1440p":
			"https://player.vimeo.com/progressive_redirect/download/799789738/rendition/1440p/virtual-car-shoot_portrait_v01%20%281440p%29.mp4?loc=external&signature=2ea9a5eb245939e08fec0829912f1030f540619d768456783940aa414a864bac",
		"portrait-2160p":
			"https://player.vimeo.com/progressive_redirect/download/799789738/rendition/2160p/virtual-car-shoot_portrait_v01%20%282160p%29.mp4?loc=external&signature=9ff54decd6f68c240c7e7fb40e459a510089d825e9007967e514f867e83b94eb",
		"vimeo-landscape-pr": "https://vimeo.com/799796148",
		"landscape-pr-720p":
			"https://player.vimeo.com/progressive_redirect/download/799796148/rendition/720p/virtual-car-shoot_landscape_preview_v01%20%28720p%29.mp4?loc=external&signature=5c5978024318272884343c780b44c5802e8fe14314d433f40514775573bee0d7",
		"landscape-pr-1080p":
			"https://player.vimeo.com/progressive_redirect/download/799796148/rendition/1080p/virtual-car-shoot_landscape_preview_v01%20%281080p%29.mp4?loc=external&signature=192a016d0d34bb49a27b1953d6d2481a9eaef991973174000b83a8e2cefaaca5",
		"landscape-pr-1440p":
			"https://player.vimeo.com/progressive_redirect/download/799796148/rendition/1440p/virtual-car-shoot_landscape_preview_v01%20%281440p%29.mp4?loc=external&signature=22bfdf42e125dd74d1b47df19cb7d4202813502eab16cdd0828c5fd1ea3e1c2c",
		"landscape-pr-2160p":
			"https://player.vimeo.com/progressive_redirect/download/799796148/rendition/2160p/virtual-car-shoot_landscape_preview_v01%20%282160p%29.mp4?loc=external&signature=a02d9e4b1efef0c8f4ae600d19cffd8eb537e4fa2e6103281ded9f5d44bba666",
		"vimeo-landscape": "https://vimeo.com/799789707",
		"landscape-720p":
			"https://player.vimeo.com/progressive_redirect/download/799789707/rendition/720p/virtual-car-shoot_-_landscape%20%28720p%29.mp4?loc=external&signature=959e422c497f3d519a6e12924bdb2a07cd078bcc4745f5f6a057a313eae0416b",
		"landscape-1080p":
			"https://player.vimeo.com/progressive_redirect/download/799789707/rendition/1080p/virtual-car-shoot_-_landscape%20%281080p%29.mp4?loc=external&signature=a02d365c3c9fb4bb5766bccb6253a9be7576e8b2c7d4ff8f2575ded6f515736b",
		"landscape-1440p":
			"https://player.vimeo.com/progressive_redirect/download/799789707/rendition/1440p/virtual-car-shoot_-_landscape%20%281440p%29.mp4?loc=external&signature=7cbd0a12fefa2a0ea05969594dab5d3355ff4d84a7efac31399ea79bbcb8b7bb",
		"landscape-2160p":
			"https://player.vimeo.com/progressive_redirect/download/799789707/rendition/2160p/virtual-car-shoot_-_landscape%20%282160p%29.mp4?loc=external&signature=3bbec670eb46671354c7753fa26ae36df592297d43d2695961a8f19050800ecf",
		"vimeo-film": "",
		"film-720p": "",
		"film-1080p": "",
		"film-1440p": "",
		"film-2160p": "",
		url: "",
	},
	{
		name: "Campari 'Fellini Forward'",
		shorthand: "campari-fellini-forward",
		description: "-",
		component: "CampariFelliniForward",
		type: "work",
		tags: "AI, Motion Capture, Previz, #Award-winning,#Unreal Engine, #Live Action, #Motion Capture, #Filmmaking",
		"search-tags": "",
		"vid-title-00": "",
		"vid-title-01": "",
		"vid-title-02": "",
		"full-access": "",
		framerate: "",
		"custom-loop": "",
		"poster-portrait-900p": "campari-fellini-forward-poster-portrait-900p",
		"poster-portrait-2160p": "",
		"poster-landscape-1600p":
			"campari-fellini-forward-poster-landscape-1600p",
		"poster-landscape-2160p": "",
		"vimeo-portrait-pr": "https://vimeo.com/810311531",
		"portrait-pr-720p":
			"https://player.vimeo.com/progressive_redirect/download/810311531/rendition/720p/fellini_portrait_preview%20%28720p%29.mp4?loc=external&signature=0af0405985cd90e53e942ad68c6819d858244592cc74081f3953e753e0fee160",
		"portrait-pr-1080p":
			"https://player.vimeo.com/progressive_redirect/download/810311531/rendition/1080p/fellini_portrait_preview%20%281080p%29.mp4?loc=external&signature=9bd230542e05634079d04b2e95ab3de0f2d617ddb1e3667202d049dbf3798c09",
		"portrait-pr-1440p":
			"https://player.vimeo.com/progressive_redirect/download/810311531/rendition/1440p/fellini_portrait_preview%20%281440p%29.mp4?loc=external&signature=f83d2bb3a56fb6fd6c738d25f7d88db1d4caf9f3cece1a745a160faa5e700453",
		"portrait-pr-2160p": "",
		"vimeo-portrait": "",
		"portrait-720p": "",
		"portrait-1080p": "",
		"portrait-1440p": "",
		"portrait-2160p": "",
		"vimeo-landscape-pr": "https://vimeo.com/manage/videos/809909483",
		"landscape-pr-720p":
			"https://player.vimeo.com/progressive_redirect/download/809909483/rendition/720p/fellini_forward_landscape%20%28720p%29.mp4?loc=external&signature=456057930ca5068ff92769f9df9783295e545fc959352f5fec68177ccefcd2c1",
		"landscape-pr-1080p":
			"https://player.vimeo.com/progressive_redirect/download/809909483/rendition/1080p/fellini_forward_landscape%20%281080p%29.mp4?loc=external&signature=73d66498f2bf2ff0e7f84639f44a11e96b796ca49cc88c0e60ec88f4e4d4f7c9",
		"landscape-pr-1440p":
			"https://player.vimeo.com/progressive_redirect/download/809909483/rendition/1440p/fellini_forward_landscape%20%281440p%29.mp4?loc=external&signature=c3255f101bb6e86d75d4a07b497a96c2f500a28285dc3c12b600c7d4b40e6b6d",
		"landscape-pr-2160p":
			"https://player.vimeo.com/progressive_redirect/download/809909483/rendition/2160p/fellini_forward_landscape%20%282160p%29.mp4?loc=external&signature=8d6362009ff028a2768221fa13a1792edc088f4997ea84a80bb47b83d280292d",
		"vimeo-landscape": "",
		"landscape-720p": "",
		"landscape-1080p": "",
		"landscape-1440p": "",
		"landscape-2160p": "",
		"vimeo-film": "https://vimeo.com/manage/videos/781957565/settings",
		"film-720p":
			"https://player.vimeo.com/progressive_redirect/playback/781957565/rendition/720p/file.mp4?loc=external&signature=628f106d46744ca47d59db1d377a1a1e712a4d3bca672a846b5008d37f2577f8",
		"film-1080p":
			"https://player.vimeo.com/progressive_redirect/playback/781957565/rendition/1080p/file.mp4?loc=external&signature=022abfe02d7ff1f7ca31b126d67d7172485e9524718565b06b10b881fc75da03",
		"film-1440p":
			"https://player.vimeo.com/progressive_redirect/playback/781957565/rendition/1080p/file.mp4?loc=external&signature=022abfe02d7ff1f7ca31b126d67d7172485e9524718565b06b10b881fc75da03",
		"film-2160p":
			"https://player.vimeo.com/progressive_redirect/playback/781957565/rendition/1080p/file.mp4?loc=external&signature=022abfe02d7ff1f7ca31b126d67d7172485e9524718565b06b10b881fc75da03",
		url: "",
	},
	{
		name: "Kontra K 'Next to you'",
		shorthand: "kontra-k-next-to-you",
		description: "Test9",
		component: "KontraKNextToYou",
		type: "work",
		tags: "Music Video, Interactive Experience,#Award-winning,#Mobile-first,#Immersive,#Engaging",
		"search-tags": "",
		"vid-title-00": "",
		"vid-title-01": "",
		"vid-title-02": "",
		"full-access": "",
		framerate: "",
		"custom-loop": "",
		"poster-portrait-900p": "kontra-k-next-to-you-poster-portrait-900p",
		"poster-portrait-2160p": "",
		"poster-landscape-1600p": "kontra-k-next-to-you-poster-landscape-1600p",
		"poster-landscape-2160p": "",
		"vimeo-portrait-pr": "https://vimeo.com/802700251",
		"portrait-pr-720p":
			"https://player.vimeo.com/progressive_redirect/download/802700251/rendition/720p/kontra_k_%27next_to_you%27_-_portrait_-_preview%20%28720p%29.mp4?loc=external&signature=aac8b26428e72031607566a9b873ad678f528a641f6df19c56f699f83434d707",
		"portrait-pr-1080p":
			"https://player.vimeo.com/progressive_redirect/download/802700251/rendition/1080p/kontra_k_%27next_to_you%27_-_portrait_-_preview%20%281080p%29.mp4?loc=external&signature=5db4c9791e11e05a2dfd71ca444461df1d5dbe548e96a004be45227f8134ee90",
		"portrait-pr-1440p":
			"https://player.vimeo.com/progressive_redirect/download/802700251/rendition/1440p/kontra_k_%27next_to_you%27_-_portrait_-_preview%20%281440p%29.mp4?loc=external&signature=e3f0cc481d10b5ef36f518761ea6c350f82a009fe006c92916962a85f5c9341d",
		"portrait-pr-2160p":
			"https://player.vimeo.com/progressive_redirect/download/802700251/rendition/2160p/kontra_k_%27next_to_you%27_-_portrait_-_preview%20%282160p%29.mp4?loc=external&signature=5a0bbc31c4fef5910004a26d1e56aadb4ec5135e7600ef8945060090a15e0ce0",
		"vimeo-portrait": "",
		"portrait-720p": "",
		"portrait-1080p": "",
		"portrait-1440p": "",
		"portrait-2160p": "",
		"vimeo-landscape-pr": "https://vimeo.com/802700100",
		"landscape-pr-720p":
			"https://player.vimeo.com/progressive_redirect/download/802700100/rendition/720p/kontra_k_%27next_to_you%27_-_landscape_-_preview%20%28720p%29.mp4?loc=external&signature=0b82cb8ba490954257da665ee09826e91721c6b2f0366c39a8a05433aaa25179",
		"landscape-pr-1080p":
			"https://player.vimeo.com/progressive_redirect/download/802700100/rendition/1080p/kontra_k_%27next_to_you%27_-_landscape_-_preview%20%281080p%29.mp4?loc=external&signature=98e41ccfae37735aa8955c8b8ff14543178dcbd3d1396d2d57105c82cd3c285b",
		"landscape-pr-1440p":
			"https://player.vimeo.com/progressive_redirect/download/802700100/rendition/1440p/kontra_k_%27next_to_you%27_-_landscape_-_preview%20%281440p%29.mp4?loc=external&signature=0d7ef1a5b066d8bef667ea73e4cff971d32ecdbbbd071ffef2e182a072108863",
		"landscape-pr-2160p":
			"https://player.vimeo.com/progressive_redirect/download/802700100/rendition/2160p/kontra_k_%27next_to_you%27_-_landscape_-_preview%20%282160p%29.mp4?loc=external&signature=114c76bee6f5d2a7aa96d9bd1e94ec4de2116a9dbb9d6f397af42474e6c63658",
		"vimeo-landscape": "",
		"landscape-720p": "",
		"landscape-1080p": "",
		"landscape-1440p": "",
		"landscape-2160p": "",
		"vimeo-film": "https://vimeo.com/612630075",
		"film-720p":
			"https://player.vimeo.com/progressive_redirect/download/612630075/rendition/720p/kontra_k_%27next_to_you%27_-_film%20%28720p%29.mp4?loc=external&signature=65a4fe803c20cabd53f9457115794a81c961752647f1ae0e4c88a2a69c2f19f3",
		"film-1080p":
			"https://player.vimeo.com/progressive_redirect/download/612630075/rendition/1080p/kontra_k_%27next_to_you%27_-_film%20%281080p%29.mp4?loc=external&signature=1bb98cdf2b4c503f5e7f13b13ba61c15fd0fa35307995768c3609ccb13a33419",
		"film-1440p":
			"https://player.vimeo.com/progressive_redirect/download/612630075/rendition/1440p/kontra_k_%27next_to_you%27_-_film%20%281440p%29.mp4?loc=external&signature=6e1dea5b2e0d6f2ed98896ad1c36912ac6293cf5bd8452fd6c7bb05515e918b2",
		"film-2160p":
			"https://player.vimeo.com/progressive_redirect/download/612630075/rendition/2160p/kontra_k_%27next_to_you%27_-_film%20%282160p%29.mp4?loc=external&signature=e833a00983ba7e60a48a73a78f36386fdf8028048b273266c94d7ce57a9296e8",
		url: "https://andeinerseite.stunning.studio",
	},
	{
		name: "G-SHOCK 'Five Minutes'",
		shorthand: "g-shock-five-minutes",
		description: "Test8",
		component: "GShockFiveMinutes",
		type: "work",
		tags: "Interactive Shortfilm,Immersive,Engaging,#Award-winning,#Mobile-first,#Zombie Apocalypse",
		"search-tags": "",
		"vid-title-00": "",
		"vid-title-01": "",
		"vid-title-02": "",
		"full-access": "",
		framerate: "",
		"custom-loop": "",
		"poster-portrait-900p": "g-shock-five-minutes-poster-portrait-900p",
		"poster-portrait-2160p": "",
		"poster-landscape-1600p": "g-shock-five-minutes-poster-landscape-1600p",
		"poster-landscape-2160p": "",
		"vimeo-portrait-pr": "https://vimeo.com/801210145",
		"portrait-pr-720p":
			"https://player.vimeo.com/progressive_redirect/download/801210145/rendition/720p/g-shock_%27fiveminutes%27_-_portrait_-_preview%20%28720p%29.mp4?loc=external&signature=4642f17977f835d269dcbf8e3cb987143bbd94ffe0ed27bc7149307f546583d1",
		"portrait-pr-1080p":
			"https://player.vimeo.com/progressive_redirect/download/801210145/rendition/1080p/g-shock_%27fiveminutes%27_-_portrait_-_preview%20%281080p%29.mp4?loc=external&signature=971f5b45dcaabd30865ba789bdcfe93562b863c0f0ff8ed9304f5f8105cf135e",
		"portrait-pr-1440p":
			"https://player.vimeo.com/progressive_redirect/download/801210145/rendition/1440p/g-shock_%27fiveminutes%27_-_portrait_-_preview%20%281440p%29.mp4?loc=external&signature=4d91bcd77763d35388f298efab75886105211b50472c407fd371b0cfad23d796",
		"portrait-pr-2160p":
			"https://player.vimeo.com/progressive_redirect/download/801210145/rendition/2160p/g-shock_%27fiveminutes%27_-_portrait_-_preview%20%282160p%29.mp4?loc=external&signature=54bf9cd18fa6290ab0e44495e58785f05d1ba04e6b39b45ded29480eeeafa3f8",
		"vimeo-portrait": "",
		"portrait-720p": "",
		"portrait-1080p": "",
		"portrait-1440p": "",
		"portrait-2160p": "",
		"vimeo-landscape-pr": "https://vimeo.com/801210103",
		"landscape-pr-720p":
			"https://player.vimeo.com/progressive_redirect/download/801210103/rendition/720p/g-shock_%27fiveminutes%27_-_landscape_-_preview%20%28720p%29.mp4?loc=external&signature=69adc890d0273edd4610eca5f27d355f3bd56daf912347e074ca2de985d8ce32",
		"landscape-pr-1080p":
			"https://player.vimeo.com/progressive_redirect/download/801210103/rendition/1080p/g-shock_%27fiveminutes%27_-_landscape_-_preview%20%281080p%29.mp4?loc=external&signature=73c2d7791b2863fe35bd1240f76130a5558b56c7915e3da043fb8c52674d231c",
		"landscape-pr-1440p":
			"https://player.vimeo.com/progressive_redirect/download/801210103/rendition/1440p/g-shock_%27fiveminutes%27_-_landscape_-_preview%20%281440p%29.mp4?loc=external&signature=16ac1bc4954f5da79e87ba5828097238982062c8afb36a561956e5e9b6d3ae5f",
		"landscape-pr-2160p":
			"https://player.vimeo.com/progressive_redirect/download/801210103/rendition/2160p/g-shock_%27fiveminutes%27_-_landscape_-_preview%20%282160p%29.mp4?loc=external&signature=84e75968612838eca0ae2e5107a16b814f5ba474bf16e30ecab6c885c0bbdca5",
		"vimeo-landscape": "",
		"landscape-720p": "",
		"landscape-1080p": "",
		"landscape-1440p": "",
		"landscape-2160p": "",
		"vimeo-film": "https://vimeo.com/801141573",
		"film-720p":
			"https://player.vimeo.com/progressive_redirect/download/801141573/rendition/720p/g-shock_%27five_minutes%27_-_showcase.mp4%20%28720p%29.mp4?loc=external&signature=d45fd96e268c7a758336c9a22fe2be37868b3c627fb08c7ae1d1e17565dc11c8",
		"film-1080p":
			"https://player.vimeo.com/progressive_redirect/download/801141573/rendition/1080p/g-shock_%27five_minutes%27_-_showcase.mp4%20%281080p%29.mp4?loc=external&signature=1119e10bffdfa84fb6830608c09dcf26934c4353f3ade850994d98965cf69f23",
		"film-1440p":
			"https://player.vimeo.com/progressive_redirect/download/801141573/rendition/1080p/g-shock_%27five_minutes%27_-_showcase.mp4%20%281080p%29.mp4?loc=external&signature=1119e10bffdfa84fb6830608c09dcf26934c4353f3ade850994d98965cf69f23",
		"film-2160p":
			"https://player.vimeo.com/progressive_redirect/download/801141573/rendition/1080p/g-shock_%27five_minutes%27_-_showcase.mp4%20%281080p%29.mp4?loc=external&signature=1119e10bffdfa84fb6830608c09dcf26934c4353f3ade850994d98965cf69f23",
		url: "https://fiveminutes.stunning.studio",
	},
];

export default solutions;
