import React from "react";

import ArtileEl, {
	Pink,
	White,
	Desc,
	Header,
	CustomImage,
} from "../../components/Elements/ArticleEl";

import cases from "../cases";

const componentName = "MercedesNewFrontiers";

const shorthand = cases.find((x) => x.component == componentName).shorthand;

if (typeof shorthand == "undefined")
	console.error("Component can't find shorthand: " + componentName);
export default () => (
	<>
		<div className="section-div" style={{}}>
			<div className="section-content">
				<div className="section-hero">
					<div
						className="section-title-sub grad-txt"
						style={{ paddingLeft: 0, paddingRight: 0 }}
					>
						"Achievements are a matter of perspective"
					</div>
				</div>
			</div>
		</div>
		<div className="section-div no-p">
			<div
				className="section-img"
				style={{
					// maxWidth: "1920px",
					paddingTop: "0px",
					marginTop: "0px",
				}}
			>
				<img
					src={`/img/cases/${shorthand}/image/img_01.jpg`}
					style={{ marginTop: 40, borderRadius: 15, width: "100%" }}
				/>
			</div>
		</div>
		<div
			className="section-div"
			style={{
				// maxWidth: "1920px",
				// paddingTop: "0.8em",
				paddingTop: 0,
			}}
		>
			<div className="section-content">
				<div className="section-description">
					The all-new AMG G63, will make you experience the thrill of
					adventure and the rush of success, as you explore new
					frontiers and push yourself to be the best you can be. Don't
					settle for mediocrity. By joining the ranks of those who
					have achieved extraordinary feats, you can be a part of this
					legacy of excellence.
				</div>
			</div>
		</div>
		<div className="section-div">
			<div className="section-content">
				<div className="section-hero">
					<div className="section-title-sub grad-txt">
						Unreal Engine.
						<br />
						Automotive.
						<br />
						Motion Capture.
					</div>
				</div>
				<div className="section-description">
					The film showcases the quiet but undeniable joy that can be
					experienced when we push ourselves to achieve greatness.
					<br />
					<br />
					We invited a professional dancer to record his signature
					moves with our motion capture system and seamlessly
					integrated the captured data with Unreal Engine's physically
					accurate car simulation. This resulted in the G63
					realistically responding to each movement, while a
					human-operated virtual camera captured the cinematic
					experience. Thanks to the nature of Real-time, we were able
					to achieve fast turnaround times and maintain the creative
					working environment.
				</div>
			</div>
			<div
				className="section-img"
				style={{
					// maxWidth: "1920px",
					paddingTop: "0px",
					marginTop: "0px",
				}}
			>
				<img
					src={`/img/cases/${shorthand}/image/img_02.jpg`}
					style={{ marginTop: 40, borderRadius: 15, width: "100%" }}
				/>
			</div>
		</div>
	</>
);
