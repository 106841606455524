import React from "react";

import ArtileEl, {
	Pink,
	White,
	Desc,
	Header,
	CustomImage,
} from "../../components/Elements/ArticleEl";

import cases from "../cases";

const componentName = "AmazonTakeASeat";

const shorthand = cases.find((x) => x.component == componentName).shorthand;

if (typeof shorthand == "undefined")
	console.error("Component can't find shorthand: " + componentName);
export default () => (
	<>
		<div className="section-div" style={{}}>
			<div className="section-content">
				<div className="section-hero">
					<div
						className="section-title-sub grad-txt"
						style={{ paddingLeft: 0, paddingRight: 0 }}
					>
						"Lightning-fast delivery meets Real-time Filmmaking"
					</div>
				</div>
			</div>
		</div>
		<div className="section-div no-p">
			<div
				className="section-img"
				style={{
					// maxWidth: "1920px",
					paddingTop: "0px",
					marginTop: "0px",
				}}
			>
				<img
					src={`/img/cases/${shorthand}/image/img_01.jpg`}
					style={{ marginTop: 40, borderRadius: 15, width: "100%" }}
				/>
			</div>
		</div>
		<div
			className="section-div"
			style={{
				// maxWidth: "1920px",
				// paddingTop: "0.8em",
				paddingTop: 0,
			}}
		>
			<div className="section-content">
				<div className="section-description">
					A funky beat and flying chairs set the tone for a unique and
					exciting delivery experience. Customers watch in amazement
					as their furniture floats effortlessly into place in a
					stylish, lofty apartment and a cozy kitchen. The delivery
					process is precise and harmonious, creating a sense of
					satisfaction and comfort.
				</div>
			</div>
		</div>
		<div className="section-div">
			<div className="section-content">
				<div className="section-hero">
					<div className="section-title-sub grad-txt">
						Unreal Engine.
						<br />
						Virtual Camera.
						<br />
						Real-time Filmmaking.
					</div>
				</div>
				<div className="section-description">
					The film emphasizes Amazon's satisfactional delivery speed
					and reliability.
					<br /> <br />
					With its unique real-time capabilities Unreal Engine
					provided the perfect working environment for fast turnaround
					times and a playful creation approach.
					<br /> <br />
					After an intense digital location scouting through various
					high-quality asset libraries, the perfect location was found
					to showcase the products in the best possible light. To
					bring the film to life, the flying and floating furniture
					animation was carefully crafted by hand and combined with
					our human-operated virtual camera system, creating an
					emotionally graceful viewing experience for every frame.
				</div>
			</div>
			<div
				className="section-img"
				style={{
					// maxWidth: "1920px",
					paddingTop: "0px",
					marginTop: "0px",
				}}
			>
				<img
					src={`/img/cases/${shorthand}/image/img_02.jpg`}
					style={{ marginTop: 40, borderRadius: 15, width: "100%" }}
				/>
			</div>
		</div>
	</>
);
