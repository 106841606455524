import React, { useRef, useContext, useState, useEffect } from "react";
import { gsap } from "gsap";

import { Route, useLocation, Routes } from "react-router-dom";

import Home from "./Home";

import Contact from "./Contact";
import PrivacyPolicy from "./PrivacyPolicy";
import Solutions from "./Solutions";

import CookieConsent from "./components/CookieConsent";
import Header from "./components/Header";
import SEO from "./components/SEO";

import { Link } from "react-router-dom";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { deviceType } from "detect-it";

import { GlobalContext } from "./index";

import { useEffectOnce } from "./hooks/useEffectOnce";
import { disableScroll, enableScroll } from "./hooks/preventScroll";
import useLocalStorage from "./hooks/useLocalStorage";
import useImage from "./hooks/useImage";
import { sendGAPageView, sendGACustomEvent } from "./hooks/googleAnalytics";

import caseData from "./data/cases";

gsap.registerPlugin(DrawSVGPlugin);

gsap.config({
  // autoSleep: 60,
  force3D: true,
  nullTargetWarn: true,
  trialWarn: false,
  // units: { left: "%", top: "%", rotation: "rad" },
});

const App = React.forwardRef((props, ref) => {
  let location = useLocation();

  const solutionsRef = useRef(null);

  const prevRoute = useRef(["", Date.now()]);

  const routes = [
    {
      path: "/get-in-touch",
      name: "Get in Touch",
      Component: Contact,
    },
    {
      path: "/privacy",
      name: "Privacy Policy",
      Component: PrivacyPolicy,
    },
    {
      path: "/cases/*",
      name: "Cases",
      Component: Solutions,
      ref: solutionsRef,
    },
    { path: "/*", name: "Landing", Component: Home },
  ];

  const [globalContext, setGlobalContext] = useContext(GlobalContext);

  const [windowBounds, setWindowBounds] = useState({ ww: -1, wh: -1 });

  const [appView, setAppView] = useState(null);

  const circleRef = useRef(null);
  const headerRef = useRef(null);
  // const asideRef = useRef();
  // const footerRef = useRef();

  const initLoad = useRef(false);
  const initLoadCallback = useRef(null);

  const circleRefSVG = useRef();
  const mousePos = useRef({ x: 0, y: 0 });

  const [fullAccess, setFullAccess] = useLocalStorage("full-access", false);

  const [SEOInfo, setSEOInfo] = useState({
    title: "Stunnıng.",
    description:
      "The next generation content studio. We craft tomorrow's ads, social and events in real-time today. Stunning real-time studio - Hamburg, Germany.",
    creator: "",
    type: "",
    image: process.env.PUBLIC_URL + "/img/seo/default.jpg",
  });

  const handleUpdateSEO = (location) => {
    const locationPath = location?.pathname || "";
    // Check if starts with cases
    const isCase = locationPath.startsWith("/cases");

    const isSolutions = locationPath === "/cases/solutions";
    const isWork = locationPath === "/cases/work";
    const isCases = locationPath === "/cases";

    const specificRoute = routes.find((route) => route.path === locationPath);
    let newName = "";
    if (specificRoute) {
      newName = specificRoute.name;
    } else if (isCase) {
      const getShorthand = locationPath.split("/")?.at(-1);

      const curCase = caseData.find(
        (caseItem) => caseItem?.shorthand === getShorthand
      );
      //console.log(curCase);
      newName = curCase?.name;
      if (!newName?.length) {
        if (isSolutions) {
          newName = "Solutions";
        } else if (isWork) {
          newName = "Work";
        } else if (isCases) {
          newName = "Cases";
        }
      }
    }

    setSEOInfo({
      ...SEOInfo,
      ...{ title: `${newName?.length ? newName + " - " : ""}Stunnıng.` },
    });
  };

  useEffect(() => {
    if (location.pathname == "/welcome") {
      setFullAccess(true);
    } else if (location.pathname.startsWith("/welcome-")) {
      setFullAccess(true);
      window.scrollTo({
        top: -500,
        behavior: "smooth",
      });
      disableScroll();
      initLoadCallback.current = () => {
        document
          .querySelector("#welcome-video")
          .scrollIntoView({ behavior: "smooth", block: "center" });
        enableScroll();
      };
    }
    handleUpdateSEO(location);

    //sendGAPageView(globalContext, location.pathname);

    sendGACustomEvent(globalContext, "p", location.pathname);
  }, [location]);

  useEffectOnce(() => {
    console.log("mount app");
    const setPosition = (e) => {
      const xPosition = e.clientX;
      const yPosition = e.clientY;

      mousePos.current = { x: xPosition, y: yPosition };
    };
    window.addEventListener("mousemove", setPosition);
    window.addEventListener("resize", onResize);
    onResize();

    gsap.set(circleRef.current, { opacity: 0 });

    //Every tick
    gsap.ticker.add(updatePosition);
    gsap.from(circleRefSVG.current, {
      stroke: "rgba(255, 255, 255,0)",
      drawSVG: "0%",
      duration: 2,
      overwrite: true,
      delay: 0.5,
    });

    //fix for preview browsers e.g. instagram
    setTimeout(() => {
      onResize();
    }, 300);

    /*    setGlobalContext({
      window: windowBounds,
      initLoad: initLoading,
      changeCursorState: changeCursorState,
      changeHeaderState: changeHeaderState,
      changeView: changeView,
      appView: appView,
      deviceType: deviceType,
    });
*/
    return () => {
      window.removeEventListener("mousemove", setPosition);
      window.removeEventListener("resize", onResize);
      console.log("unmount app");
    };
  });

  const initLoading = () => {
    if (!initLoad.current) {
      console.log("loooad");

      initLoad.current = true;

      initLoadCallback.current?.();
      // gsap.to(footerRef.current, 0.5, {
      //   opacity: 1,
      //   startAt: { yPercent: 100 },
      //   yPercent: 0,
      //   delay: 1.5,
      //   onComplete: () => {
      //     initLoadCallback.current?.();
      //   },
      // });

      // gsap.to(asideRef.current, 0.5, {
      //   opacity: 1,
      //   startAt: { xPercent: 100 },
      //   xPercent: 0,
      //   delay: 1.5,
      // });
    }
  };

  const changeCursorState = (state) => {
    gsap.killTweensOf(circleRefSVG.current);
    switch (state) {
      case "active":
        gsap.to(circleRefSVG.current, {
          stroke: "rgba(241, 3, 136,1)",
          drawSVG: "100% 0%",
          duration: 0.25,
          overwrite: true,
        });
        break;
      case "idle":
        gsap.to(circleRefSVG.current, {
          stroke: "rgba(255, 255, 255,1)",
          drawSVG: "100% 0%",

          duration: 0.25,
          overwrite: true,
        });
        break;
      case "none":
        gsap.to(circleRefSVG.current, {
          stroke: "rgba(255, 255, 255,1)",
          drawSVG: "0% 0%",
          duration: 0.25,
          overwrite: true,
        });
        break;
      case "load":
        gsap.to(circleRefSVG.current, {
          stroke: "rgba(241, 3, 136,1)",

          drawSVG: "0% 0%",
          duration: 0.15,
          overwrite: true,

          onComplete: () => {
            gsap.to(circleRefSVG.current, {
              duration: 1.75,
              ease: "ease.out",
              drawSVG: "100% 0%",
              onComplete: () => {
                gsap.to(circleRefSVG.current, {
                  stroke: "rgba(255, 255, 255,1)",
                  duration: 2,
                  ease: "back.out(4)",
                });
              },
            });
          },
        });

        break;
      default:
        break;
    }
  };

  const changeHeaderState = (state, data = null) => {
    headerRef.current?.changeHeaderState?.(state, data);
  };

  const closeSearch = () => {
    headerRef.current?.closeSearch?.();
  };

  const isSearchOpen = () => {
    return headerRef.current?.isSearchOpen?.();
  };

  const setFilter = (filter) => {
    solutionsRef.current?.setFilter?.(filter);
  };

  const changeView = (view) => {
    // console.log("NEW VIEW", view);
    setAppView(view);
  };

  const updatePosition = () => {
    if (mousePos.current.x === 0 && mousePos.current.y === 0) return;
    gsap.to(circleRef.current, {
      x: mousePos.current.x - 20,
      y: mousePos.current.y - 20,
      opacity: 1,
      duration: 0.3,
    });
  };

  const onResize = () => {
    let vh = window.innerHeight * 0.01;

    document.documentElement.style.setProperty("--vh", `${vh}px`);
    const ww = window.innerWidth;
    const wh = window.innerHeight;

    setWindowBounds({ ww: ww, wh: wh });
  };

  if (
    (prevRoute.current?.[0].startsWith("/cases") &&
      !location.pathname.startsWith("/cases")) ||
    (!prevRoute.current?.[0].startsWith("/cases") &&
      location.pathname.startsWith("/cases"))
  ) {
    prevRoute.current[0] = location.pathname;
    prevRoute.current[1] = Date.now();
  }

  return (
    <div className="body">
      <SEO
        title={SEOInfo.title}
        description={SEOInfo.description}
        creator={SEOInfo.creator}
        type={SEOInfo.type}
        image={SEOInfo.image}
      />
      <div className="grid">
        <div className="grid-line _4"></div>
        <div className="grid-line _3"></div>
        <div className="grid-line _2"></div>
        <div className="grid-line _1"></div>
        <div className="grid-line _0"></div>
      </div>

      {/*      <div className="footer" style={{ opacity: 0 }} ref={footerRef}>
        <Link
          to="/privacy"
          className={
            location.pathname === "/privacy" ? "disabled-link" : "cookie"
          }
        >
          Legal Notice
        </Link>
      </div>*/}
      <div
        className="progress-wrap"
        style={{ display: deviceType === "mouseOnly" ? "block" : "none" }}
        ref={circleRef}
      >
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-8 -8 116 116"
        >
          <path
            ref={circleRefSVG}
            d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
          />
        </svg>
      </div>
      <Header ref={headerRef} setFilter={setFilter} />
      <Routes>
        {routes.map(({ path, Component, ref }) => (
          <Route
            key={path}
            path={path}
            element={
              <Component
                globalContext={globalContext}
                key={path}
                cKey={path}
                currentPathname={location.pathname}
                window={windowBounds}
                initLoad={initLoading}
                changeCursorState={changeCursorState}
                changeHeaderState={changeHeaderState}
                closeSearch={closeSearch}
                isSearchOpen={isSearchOpen}
                changeView={changeView}
                appView={appView}
                deviceType={deviceType}
                ref={ref}
              />
            }
          />
        ))}
      </Routes>
      <CookieConsent debug={false} />
    </div>
  );
});
export default App;
