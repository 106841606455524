import React from "react";
import { Helmet } from "react-helmet-async";
import defaultSEOImage from "../img/landing-router-work.png";
export default function SEO({ title, description, creator, type, image }) {
	return (
		<Helmet>
			{/* Standard metadata tags */}
			<title>{title}</title>
			<meta name="description" content={description} />
			<meta property="og:image" content={image} />
			{/* End standard metadata tags */}
			{/* Facebook tags */}
			<meta property="og:type" content={type} />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />
			{/* End Facebook tags */}
			{/* Twitter tags */}
			<meta name="twitter:creator" content={creator} />}
			<meta name="twitter:card" content={"summary_large_image"} />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
			{/* End Twitter tags */}
			<meta property="og:site_name" content="Stunnıng." />
		</Helmet>
	);
}
