const data = [
	{
		name: "",
		shorthand: "default",
		"poster-landscape-1600p": "default-poster-landscape-1600p",
		"vimeo-landscape-pr-720p": "",
		"landscape-pr-720p":
			"https://player.vimeo.com/progressive_redirect/download/799126696/rendition/720p/stunning-introduction_-_landscape_-_preview%20%28720p%29.mp4?loc=external&signature=c69502874af6429d2ec1306af31ddddbb17e3453c199d2d4d16268d2a4c7f35f",
		"vimeo-film1800p": "https://vimeo.com/797750493",
		"film-1080p":
			"https://player.vimeo.com/progressive_redirect/download/797750493/rendition/1080p/stunning-introduction_-_film%20%281080p%29.mp4?loc=external&signature=77c89f0a4c7a9b377ede3a4f8b1937af2da534df325e3f817ad3a1d6a0b9f702",
	},
	{
		name: "jonas",
		shorthand: "welcome-jonas",
		"poster-landscape-1600p": "welcome-jonas-poster-landscape-1600p",
		"vimeo-landscape-pr-720p": "",
		"landscape-pr-720p":
			"https://player.vimeo.com/progressive_redirect/download/799436372/rendition/720p/amazon_%27take_a_seat%27_-_film%20%28720p%29.mp4?loc=external&signature=35917bd2ac2ba9ae5b51857b4d6664a17e282909285f9717791bf21f3d521400",
		"vimeo-film1800p": "",
		"film-1080p":
			"https://player.vimeo.com/progressive_redirect/download/799436372/rendition/1080p/amazon_%27take_a_seat%27_-_film%20%281080p%29.mp4?loc=external&signature=b8b61d806db49c6516b847a63b8db6407fa5cd25a296f8db61083c89bed1a7f0",
	},
	{
		name: "max",
		shorthand: "welcome-max",
		"poster-landscape-1600p": "welcome-max-poster-landscape-1600p",
		"vimeo-landscape-pr-720p": "",
		"landscape-pr-720p":
			"https://player.vimeo.com/progressive_redirect/download/799261682/rendition/720p/mercedes-moon_-_film%20%28720p%29.mp4?loc=external&signature=37ebc46e10b9fcf51f5d95614dd46fc69d08bcb2afb34efc90a13c2f817c4b4c",
		"vimeo-film1800p": "",
		"film-1080p":
			"https://player.vimeo.com/progressive_redirect/download/799261682/rendition/1080p/mercedes-moon_-_film%20%281080p%29.mp4?loc=external&signature=ab8a81f5515c3ca0b704c5357fe34ee72a0b86c37a00f68f8e62660a12e88d0c",
	},
];

export default data;
