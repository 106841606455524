import React, { useRef, useEffect, useState, useContext } from "react";
import { useCookies } from "react-cookie";

import { gsap, Power2, Power0 } from "gsap";

import { GlobalContext } from "../../index";

import { isTouchDevice } from "../../hooks/utils";

const BtnVideoSelectorParallax = React.forwardRef((props, ref) => {
	const [context, setContext] = useContext(GlobalContext);

	const circleRefSVG = useRef(null);
	const circleRefSVGWhite = useRef(null);
	const circleHalfDots = useRef(null);
	const svgContainer = useRef(null);

	const autoplayState = useRef(null);
	const activeState = useRef(null);
	const hoverState = useRef(null);

	const parallaxIt = (e, parent, target, movement) => {
		var boundingRect = parent.getBoundingClientRect();
		const pageX = e.clientX || e.touches?.[0]?.clientX || e.pageX;
		const pageY = e.clientY || e.touches?.[0]?.clientY || e.pageY;
		var relX = pageX - boundingRect.left;
		var relY = pageY - boundingRect.top;

		var movementX =
			((relX - boundingRect.width / 2) / boundingRect.width) * movement;
		var movementY =
			((relY - boundingRect.height / 2) / boundingRect.height) * movement;
		gsap.to(target, {
			duration: 0.3,
			x: movementX,
			y: movementY,
			rotationX: (movementY / 3) * 2.5,
			rotationY: (-movementX / 3) * 2.5,
			ease: Power2.easeOut,
		});

		const comp_vid = document.querySelectorAll(`#comp-vid-${props.index}`);

		if (comp_vid.length > 0) {
			gsap.to("#comp-vid-" + props.index, {
				duration: 0.3,
				x: movementX,
				y: movementY,
				// rotationX: (movementY / 3) * 2.5,
				// rotationY: (-movementX / 3) * 2.5,
				ease: "none",
			});
		}

		// gsap.to(".complication-title-cnt", {
		// 	duration: 0.3,
		// 	x: movementX * 0.1,
		// 	y: movementY * 0.1,
		// 	rotationX: (movementY / 3) * 2.5,
		// 	rotationY: (-movementX / 3) * 2.5,
		// 	ease: "none",
		// });
	};

	const callParallax = (e, parent) => {
		parallaxIt(e, parent, parent, 20);
	};

	const mouseEnterBtn = (e) => {
		e.preventDefault();

		// this.props.changeCursorState("active");
		// this.activeHover = true;
		// this.activeHoverEl = e.target;

		//get all comp-vid-0 children

		props.onmousemove(e);
		const targetQuery = e.target;

		gsap.to(targetQuery, {
			duration: 0.3,
			scale: 1.1,
			overwrite: true,
			opacity: 1,
			x: 1,
			y: 1,
			rotationX: 1,
			rotationY: 1,
			overwrite: "auto",
		});
	};

	const mouseLeaveBtn = (e) => {
		e.preventDefault();
		props.onmouseleave(e);
		const targetQuery = e.target;
		gsap.to(targetQuery, {
			duration: 0.3,
			rotationX: 0,
			rotationY: 0,
			x: 0,
			y: 0,
			scale: 1,
			overwrite: "auto",
		});
	};

	const mouseMoveBtn = (e) => {
		e.preventDefault();

		callParallax(e, e.target);
	};

	const killAllTweens = () => {
		gsap.killTweensOf(circleRefSVGWhite.current);
		gsap.killTweensOf(circleHalfDots.current);
		gsap.killTweensOf(svgContainer.current);
	};
	const animateAutoplay = (duration) => {
		gsap.to(circleRefSVG.current, {
			drawSVG: "0% 50%",
			duration: 0.15,
		});

		gsap.to(circleRefSVGWhite.current, {
			stroke: "#fffcfa",
			drawSVG: "50% 100%",
			duration: 0.5,
		});

		//gsap.to(svgContainer.current, { rotation: 0, duration: 0.15 });
		gsap.to(circleHalfDots.current, {
			rotation: gsap.getProperty(svgContainer.current, "rotation"),
			autoAlpha: 1,
			duration: 0.15,
		});

		gsap.to(circleRefSVGWhite.current, {
			duration: 2,

			ease: "none",
			repeat: -1,
			delay: 0.15,
		});

		gsap.to([circleHalfDots.current, svgContainer.current], {
			rotation: "-=360",
			duration: 2,
			ease: "none",
			repeat: -1,

			delay: 0.15,
		});
	};

	const animateToWhite = (duration) => {
		gsap.to(circleHalfDots.current, {
			rotation: 0,
			autoAlpha: 0,
			duration: 0.15,
			force3D: true,
		});

		gsap.to(circleRefSVGWhite.current, {
			stroke: "#fffcfa",
			drawSVG: "100% 0%",
			duration: duration,

			ease: Power0,
		});
	};

	const animateToPink = (duration) => {
		gsap.to(circleHalfDots.current, {
			rotation: 0,
			autoAlpha: 0,
			duration: 0.15,
			force3D: true,
		});

		gsap.to(circleRefSVGWhite.current, {
			stroke: "rgba(241, 3, 136,1)",
			drawSVG: "100% 0%",
			duration: duration,

			ease: Power0,
		});
	};

	const isActiveVideo = () => {
		return (
			props.activeVideo == props.index ||
			(props.index == 2 && props.activeVideo == -1)
		);
	};

	const isHovered = () => {
		return props.hovered;
	};

	const refreshBtn = () => {
		killAllTweens();
		if (autoplayState.current) {
			if (isActiveVideo()) {
				if (props.hovered) {
					animateToPink(0.2);
				} else {
					animateAutoplay(3);
				}
			} else {
				animateToWhite(1);
			}
		} else {
			if (isActiveVideo()) {
				if (props.hovered) {
					animateToPink(0.2);
				} else {
					animateToWhite(0.2);
				}
			} else {
				animateToWhite(0.25);
			}
		}
	};

	useEffect(() => {
		if (
			autoplayState.current !== props.autoplay ||
			props.activeVideo !== activeState.current ||
			props.hovered !== hoverState.current
		) {
			hoverState.current = props.hovered;
			autoplayState.current = props.autoplay;
			activeState.current = props.activeVideo;
			refreshBtn();
		}
	}, [props.autoplay, props.activeVideo, props.hovered, props.activeSlide]);

	return (
		<div
			className="sol-fs-btn-parallax-cnt"
			style={{
				touchAction: "none",
				pointerEvents: "auto",
				cursor: "none",
				visibility: "hidden",
				opacity: 0,
			}}
			onMouseMove={isTouchDevice() ? null : mouseMoveBtn}
			onMouseEnter={isTouchDevice() ? null : mouseEnterBtn}
			onMouseLeave={isTouchDevice() ? null : mouseLeaveBtn}
			onClick={props.onclick}
		>
			<div
				className={`sol-fs-btn-hover${
					isActiveVideo() && isHovered() ? " active" : ""
				}`}
				style={{ pointerEvents: "none" }}
			>
				<div
					className="circle-dots"
					ref={circleHalfDots}
					style={{ pointerEvents: "none" }}
				/>
				<svg
					ref={svgContainer}
					id="svgCircleProgress1"
					className="svgloader"
					height="32"
					width="32"
					style={{
						position: "absolute",
					}}
					viewBox="0 0 100 100"
				>
					<circle
						ref={circleRefSVGWhite}
						id={"svgCircleProgressPath2" + props.index}
						strokeLinecap="round"
						cx={50}
						cy={50}
						r={45}
						stroke="#fffcfa"
						strokeWidth={9}
						fill="transparent"
						transform="rotate(-90, 50, 50)"
					/>
				</svg>
				<div
					className={`hover-btn-circle hide${
						isActiveVideo() && isHovered() ? " active" : ""
					}`}
					style={{ pointerEvents: "none" }}
				>
					{[...Array(props.index + 1)].map((x, i) => (
						<div
							className={`sol-fs-btn-line${
								isActiveVideo() && isHovered() ? " active" : ""
							}`}
							style={{ pointerEvents: "none" }}
							key={"line_" + props.index + "_" + i}
						/>
					))}
				</div>
			</div>
		</div>
	);
});

export default BtnVideoSelectorParallax;
