import React from "react";

import ArtileEl, {
	Pink,
	White,
	Desc,
	Header,
	CustomImage,
} from "../../components/Elements/ArticleEl";

import cases from "../cases";

const componentName = "ArVrXr";

const shorthand = cases.find((x) => x.component == componentName).shorthand;

if (typeof shorthand == "undefined")
	console.error("Component can't find shorthand: " + componentName);
export default () => (
	<>
		<div className="section-div">
			<div className="section-content">
				<div className="section-hero">
					<div
						className="section-title-sub grad-txt"
						style={{ paddingLeft: 0, paddingRight: 0 }}
					>
						"Augmented and Virtual Reality are changing the way we
						interact with the world."
					</div>
				</div>
			</div>
		</div>

		<ArtileEl type={"text-left-onethird"}>
			<Header customType="header">
				Virtual Reality.
				<br />
				Unforgettable brand moments.
			</Header>
			<Desc customType="desc">
				VR offers an unparalleled immersive experience that can
				captivate the audience. It can transport viewers to uncharted
				territories or provide the them with a comprehensive view of a
				product or service.{" "}
				<White>
					VR enables you to create unforgettable 360° brand moments
					that leave a lasting impression
				</White>
				.
			</Desc>
			<CustomImage
				customType="image"
				src={`/img/cases/${shorthand}/image/img_01.png`}
			/>
		</ArtileEl>

		<ArtileEl type={"text-right-half"}>
			<Header customType="header">
				Augmented Reality. <br />
				Amplified Engagement.
			</Header>
			<Desc customType="desc">
				AR allows the integration of content into the everyday life of
				the costumers.{" "}
				<White>
					Personalized content is frequently shared by users with
					their friends and social networks, resulting in higher
					engagement rates
				</White>{" "}
				compared to traditional content formats. This increased
				engagement amplifies the reach and impact of the brand's
				message.
			</Desc>
			<CustomImage
				customType="image"
				src={`/img/cases/${shorthand}/image/img_04.png`}
			/>
		</ArtileEl>

		<ArtileEl type={"text-left-half"}>
			<Header customType="header">AR Gamification.</Header>
			<Desc customType="desc">
				Unlike traditional video games that take place in a virtual
				environment, AR games incorporate your surroundings as the
				backdrop. This allows for a unusual dynamic experience, as{" "}
				<White>
					the player's environment becomes a part of the game itself
				</White>
				. Imagine turning your grocery shopping into a treasure hunt or
				transform the boring waiting for the next train into a high
				octane exit game.
			</Desc>
			<CustomImage
				customType="image"
				src={`/img/cases/${shorthand}/image/img_02.png`}
			/>
		</ArtileEl>
		<ArtileEl type={"text-right-half"}>
			<Header customType="header">
				Live Experience. <br />
				In snyc.
			</Header>
			<Desc customType="desc">
				Empower your customers to produce their own AR music video
				during a live concert or allow them to sit in the driver's seat
				of a race car during the grand prix.{" "}
				<White>
					By synchronizing content to live events, you can enable a
					massive audience to share the same experience simultaneously
				</White>
				, live and in sync. It's the perfect opportunity to convert the
				positive vibe of the event into brand awareness.
			</Desc>
			<CustomImage
				customType="image"
				src={`/img/cases/${shorthand}/image/img_05.png`}
			/>
		</ArtileEl>
		<ArtileEl type={"text-left-half"}>
			<Header customType="header">It's your turn.</Header>
			<Desc customType="desc">
				With the rise of AR, VR, and XR technologies, the digital world
				is now more accessible and interactive than ever before. The
				possibilities are endless, as these advancements allow us to{" "}
				<White>
					explore new digital experiences and environments with ease
				</White>
				.
			</Desc>
			<CustomImage
				customType="image"
				src={`/img/cases/${shorthand}/image/img_03.png`}
			/>
		</ArtileEl>
	</>
);
