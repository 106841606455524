import React, { useRef, useEffect, useState, useContext } from "react";
import { useCookies } from "react-cookie";

import { gsap, Power2 } from "gsap";

import { GlobalContext } from "../../index";
import { isTouchDevice } from "../../hooks/utils";
const BtnParallax = React.forwardRef((props, ref) => {
	const btnOverlay = useRef(null);
	const [context, setContext] = useContext(GlobalContext);

	const parallaxIt = (e, parent, target, movement) => {
		var boundingRect = parent.getBoundingClientRect();
		const pageX = e.clientX || e.touches?.[0]?.clientX || e.pageX;
		const pageY = e.clientY || e.touches?.[0]?.clientY || e.pageY;
		var relX = pageX - boundingRect.left;
		var relY = pageY - boundingRect.top;

		var movementX =
			((relX - boundingRect.width / 2) / boundingRect.width) * movement;
		var movementY =
			((relY - boundingRect.height / 2) / boundingRect.height) * movement;
		gsap.to(target, {
			duration: 0.3,
			overwrite: "auto",
			x: movementX,
			y: movementY,
			rotationX: (movementY / 3) * 2.5,
			rotationY: (-movementX / 3) * 2.5,
			ease: Power2.easeOut,
		});
	};

	const callParallax = (e, parent) => {
		parallaxIt(e, parent, parent, 20);
	};

	const mouseEnterBtn = (e) => {
		e.preventDefault();

		// this.props.changeCursorState("active");
		// this.activeHover = true;
		// this.activeHoverEl = e.target;

		const targetQuery = e.target.querySelector(".btn-parallax");

		gsap.to(targetQuery, {
			backgroundColor: "rgba(241,3,136,1)",

			...(props.invert ? { borderColor: "rgba(241,3,136,1)" } : {}),
			duration: 0.3,
			scale: 1.1,
			overwrite: "auto",
			opacity: 1,
			x: 1,
			y: 1,
			rotationX: 1,
			rotationY: 1,
		});
		if (btnOverlay.current) {
			gsap.to(btnOverlay.current, {
				duration: 0.3,
				overwrite: "auto",
				autoAlpha: 1,
			});
		}
	};

	const mouseLeaveBtn = (e) => {
		e.preventDefault();
		const targetQuery = e.target.querySelector(".btn-parallax");

		gsap.to(targetQuery, {
			backgroundColor: "rgba(241,3,136,0)",
			...(props.invert
				? { borderColor: "rgba(255, 252, 250, .25)" }
				: {}),
			duration: 0.3,
			rotationX: 0,
			rotationY: 0,
			x: 0,
			y: 0,
			scale: 1,
		});
		if (btnOverlay.current) {
			gsap.to(btnOverlay.current, {
				duration: 0.3,

				autoAlpha: 0,
			});
		}
	};

	const mouseMoveBtn = (e) => {
		e.preventDefault();

		callParallax(e, e.target.querySelector(".btn-parallax"));
	};

	const preventAction = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	return (
		<div
			className={`btn-parallax-container${
				props.small ? " small-p" : ""
			} ${props.nav ? " nav" : ""}`}
			onTouchMove={preventAction}
			onMouseMove={isTouchDevice() ? preventAction : mouseMoveBtn}
			onMouseEnter={isTouchDevice() ? preventAction : mouseEnterBtn}
			onMouseLeave={isTouchDevice() ? preventAction : mouseLeaveBtn}
			onClick={props.onclick}
			style={{
				pointerEvents: props.disabled ? "none" : "auto",
			}}
		>
			{
				{
					default: (
						<div
							className={`btn-parallax${
								props.addclass ? " " + props.addclass : ""
							}`}
							style={{
								opacity: props.defaulthide ? "0" : "1",
								visibility: props.defaulthide
									? "hidden"
									: "inherit",
								borderColor: props.nooutline
									? "rgba(255,252,250,0)"
									: props.invert
									? "rgba(255, 252, 250, .25)"
									: "f10388",
								borderWidth: props.invert ? 1 : 3,
							}}
						>
							{props.filmicon ? (
								<div className="circle-x play-film-icon">
									<div
										className="circle-x play-film-icon overlay"
										style={{
											opacity: 0,
											visibility: "hidden",
										}}
										ref={btnOverlay}
									/>
								</div>
							) : null}
							<div className="btn-parallax-text">
								{props.text}
							</div>
						</div>
					),
					backArrow: (
						<div
							className="btn-parallax"
							style={{
								borderColor: "rgba(0,0,0,0)",
								opacity: props.defaulthide ? "0" : "1",
								visibility: props.defaulthide
									? "hidden"
									: "inherit",
							}}
						>
							<div className="back-icon arrow" />
							<div className="back-txt-cnt">
								<div className="back-txt transparent alt">
									{props.text}
								</div>
							</div>
						</div>
					),
				}[props.variant || "default"]
			}
			{/*			{props.alt ? (
				<div
					className="btn-parallax"
					style={{
						borderColor: "rgba(0,0,0,0)",
						opacity: props.defaulthide ? "0" : "1",
						visibility: props.defaulthide ? "hidden" : "inherit",
					}}
				>
					<div className="back-icon arrow" />
					<div className="back-txt-cnt">
						<div className="back-txt transparent alt">
							{props.text}
						</div>
					</div>
				</div>
			) : (
				<div
					className={`btn-parallax${
						props.addclass ? " " + props.addclass : ""
					}`}
					style={{
						opacity: props.defaulthide ? "0" : "1",
						visibility: props.defaulthide ? "hidden" : "inherit",
						borderColor: props.invert
							? "rgba(255, 252, 250, 1)"
							: "f10388",
						borderWidth: props.invert ? 3 : 3,
					}}
				>
					{props.filmicon ? (
						<div className="circle-x play-film-icon">
							<div
								className="circle-x play-film-icon overlay"
								style={{ opacity: 0, visibility: "hidden" }}
								ref={btnOverlay}
							/>
						</div>
					) : null}
					<div className="btn-parallax-text">{props.text}</div>
				</div>
			)}*/}
		</div>
	);
});

export default React.memo(BtnParallax);
