import React, {
	useImperativeHandle,
	useLayoutEffect,
	useEffect,
	useRef,
	useState,
} from "react";
import { gsap } from "gsap";

import {
	useEffectOnce,
	destroyVideo,
	destroyEl,
} from "../../../hooks/useEffectOnce";

const CompSocialMessage = React.forwardRef((props, ref) => {
	const complicationsRef = useRef(null);
	const animationTimeline = useRef(null);

	const curTime = useRef(null);
	const MsgHeyThere = useRef(null);
	const MsgVideo = useRef(null);
	const MsgVideoNow = useRef(null);

	const MsgLetsTakeLoader = useRef(null);
	const MsgLetsTakeLoaderDotContainer = useRef(null);
	const animationTimelineMsgLetsTakeLoader = useRef(null);
	const MsgLetsTake = useRef(null);
	const MsgLetsTakeEmoji = useRef(null);
	const MsgLetsTakeNow = useRef(null);

	const MsgSureThing = useRef(null);
	const MsgSureThingLoader = useRef(null);
	const MsgSureThingLoaderDotContainer = useRef(null);
	const animationTimelineMsgSureThingLoader = useRef(null);
	const MsgSureThingNow = useRef(null);

	const MsgEpic = useRef(null);
	const MsgEpicEmoji = useRef(null);
	const MsgEpicNow = useRef(null);

	const vidRef = useRef(null);

	const reframe = () => {
		gsap.set(complicationsRef.current, {
			x: props.offsetX || 0,
			y: props.offsetY || 0,
			scale: props.isSmallScreen ? 0.75 : 1,
		});
		// gsap.set(complicationsRef.current, { y: props.offsetY || 0 });
	};

	const seek = (e) => {
		const seekTime = e?.detail?.playSecond;

		if (seekTime) {
			animationTimeline.current?.seek?.(seekTime);
			animationTimeline.current?.play?.();
		}
	};
	const restart = () => {
		animationTimeline.current?.restart?.();
	};

	const pause = () => {
		animationTimeline.current?.pause?.();
	};
	useLayoutEffect(() => {
		const parent = complicationsRef.current?.parentNode || null;

		if (parent) {
			parent?.addEventListener("onRestart", restart);
			parent?.addEventListener("onSeek", seek);
			parent?.addEventListener("onPause", pause);
		}
		return () => {
			parent?.removeEventListener("onRestart", restart);
			parent?.removeEventListener("onSeek", seek);
			parent?.removeEventListener("onPause", pause);
		};
	}, [complicationsRef.current]);

	useEffectOnce(() => {
		return () => {
			destroyVideo(vidRef.current);
		};
	}, []);

	//reframe Function

	useEffect(() => {
		reframe();
	}, [props.offsetX, props.offsetY, props.isSmallScreen]);

	const addOpenMessage = (
		msgRef,
		time = ">",
		transformOrigin = "left top"
	) => {
		animationTimeline.current.add(
			gsap.fromTo(
				msgRef,
				{ scale: 0, autoAlpha: 1 },
				{
					scale: 1,
					duration: 0.5,
					transformOrigin: transformOrigin,
					stagger: 0.1,
					ease: "back.out(1)",
				}
			),
			time
		);
	};

	const fadeOutMessage = (
		msgRef,
		time = ">",
		transformOrigin = "left top"
	) => {
		animationTimeline.current.add(
			gsap.to(
				msgRef,

				{
					scale: 0,
					duration: 0.5,
					transformOrigin: transformOrigin,

					ease: "back.in(1)",
				}
			),
			time
		);
	};

	const fadeOutElement = (elementRef, time = ">") => {
		animationTimeline.current.add(
			gsap.to(
				elementRef,

				{
					autoAlpha: 0,
					duration: 0.25,
					ease: "back.in(1)",
				}
			),
			time
		);
	};
	const fadeInElement = (elementRef, time = ">") => {
		animationTimeline.current.add(
			gsap.fromTo(
				elementRef,

				{ autoAlpha: 0 },
				{
					autoAlpha: 1,
					duration: 0.5,
					ease: "back.out(1)",
				}
			),
			time
		);
	};

	const fadeInEmoji = (emojiRef, time = ">", transformOrigin = "center") => {
		animationTimeline.current.add(
			gsap.fromTo(
				emojiRef,
				{ scale: 0 },
				{
					scale: 1,
					duration: 0.5,
					transformOrigin: transformOrigin,

					ease: "back.out(1)",
				}
			),
			time
		);
	};

	const createLoaderTimeline = (loaderDotContainer, maxAlpha = 1) => {
		const loaderDots = loaderDotContainer.children;
		const tl = gsap.timeline({
			repeat: -1,
		});
		tl.add("start");
		tl.add(
			gsap.fromTo(
				loaderDots[0],
				{ autoAlpha: 0 },
				{ duration: 0.33, autoAlpha: maxAlpha, repeat: 1, yoyo: true }
			),
			"-=75%"
		);
		tl.add(
			gsap.fromTo(
				loaderDots[1],
				{ autoAlpha: 0 },
				{ duration: 0.33, autoAlpha: maxAlpha, repeat: 1, yoyo: true }
			),
			"-=75%"
		);
		tl.add(
			gsap.fromTo(
				loaderDots[2],
				{ autoAlpha: 0 },
				{ duration: 0.33, autoAlpha: maxAlpha, repeat: 1, yoyo: true }
			),
			"-=75%"
		);
		return tl;
	};
	useEffect(() => {
		if (props.duration) {
			animationTimeline.current = gsap.timeline({ repeat: -1 });

			animationTimelineMsgLetsTakeLoader.current = createLoaderTimeline(
				MsgLetsTakeLoaderDotContainer.current,
				0.5
			);
			animationTimelineMsgSureThingLoader.current = createLoaderTimeline(
				MsgSureThingLoaderDotContainer.current
			);

			animationTimeline.current.add("start");
			animationTimeline.current.add(
				gsap.fromTo(
					curTime,
					{ current: 0 },
					{
						duration: props.duration,
						ease: "none",
						current: props.duration,
					}
				)
			);

			animationTimeline.current.call(
				() => {
					vidRef.current.currentTime = 0;
				},
				null,
				"start"
			);
			gsap.set(complicationsRef.current, { yPercent: 10 });

			addOpenMessage([MsgHeyThere.current, MsgVideo.current], 0);
			fadeInElement(MsgVideoNow.current, 0);

			addOpenMessage(MsgLetsTakeLoader.current, 0.2, "left bottom");

			fadeOutElement(MsgLetsTakeLoader.current, 1.26);

			fadeOutElement(MsgVideoNow.current, 1.56);
			addOpenMessage(MsgLetsTake.current, 1.56);

			fadeInElement(MsgLetsTakeNow.current, 1.56);

			fadeInEmoji(MsgLetsTakeEmoji.current, 1.8);

			animationTimeline.current.add(
				gsap.to(complicationsRef.current, 0.5, {
					yPercent: 0,
					ease: "Power2.out",
				}),
				2.2
			);

			addOpenMessage(MsgSureThingLoader.current, 2.3, "right bottom");

			fadeOutElement(MsgSureThingLoader.current, 3.8);

			fadeInElement(MsgSureThingNow.current, 4.1);
			addOpenMessage(MsgSureThing.current, 4.1, "right top");

			animationTimeline.current.add(
				gsap.to(complicationsRef.current, 0.5, {
					yPercent: -10,
					ease: "Power2.out",
				}),
				5.1
			);

			fadeOutElement(MsgLetsTakeNow.current, 5.2);
			fadeInElement(MsgEpicNow.current, 5.2);
			addOpenMessage(MsgEpic.current, 5.2);

			fadeInEmoji(MsgEpicEmoji.current, 5.44);

			fadeOutMessage(
				[
					MsgHeyThere.current,
					MsgVideo.current,

					MsgLetsTake.current,

					MsgEpic.current,
				],
				7.2,
				"left center"
			);

			fadeOutMessage(
				[MsgSureThing.current, MsgEpic.current],
				7.2,
				"right center"
			);
		}
		return () => {
			if (animationTimeline.current) {
				animationTimeline.current.getChildren().forEach((x) => {
					x.kill();
				});
			}
			if (animationTimelineMsgLetsTakeLoader.current) {
				animationTimelineMsgLetsTakeLoader.current
					.getChildren()
					.forEach((x) => {
						x.kill();
					});
			}
			if (animationTimelineMsgSureThingLoader.current) {
				animationTimelineMsgSureThingLoader.current
					.getChildren()
					.forEach((x) => {
						x.kill();
					});
			}
		};
	}, [props.duration]);

	return (
		<div
			className="complication-el full-transparent msg"
			ref={complicationsRef}
		>
			<div className="compilation-row msg">
				<div className="complication-message">
					<div className="message-main" ref={MsgHeyThere}>
						<div className="message-title">
							Tobias (Awesome Agency)
						</div>

						<div className="message-txt">
							Hey there, have you already seen this?
						</div>
					</div>
				</div>
				<div className="complication-message img">
					<div
						className="message-main img"
						style={{
							paddingTop: 0,
							backgroundImage: "none",
							backgroundColor: "black",
						}}
						ref={MsgVideo}
					>
						<video
							// style={{ visibility: isVisible ? "visible" : "hidden" }}
							style={{
								borderRadius: 15,
								width: "100%",
								height: "100%",

								maxHeight: "100vh",
								maxWidth: "100vw",
								minHeight: "100%",
								minWidth: "100%",
								objectFit: "cover",
							}}
							preload="metadata"
							// poster={props.poster || null}
							muted
							playsInline
							autoPlay
							loop
							src={
								"https://player.vimeo.com/progressive_redirect/download/808481312/rendition/360p/gesture_v01.mp4%20%28360p%29.mp4?loc=external&signature=61f8fee6f8ba75fb553528bff8c5dbc15eaff8e616dbcc3061332e745aa83ecb"
							}
							ref={vidRef}
						></video>
						<div className="message-sub" ref={MsgVideoNow}>
							now
						</div>
					</div>
				</div>
				<div className="complication-message">
					<div className="message-main" ref={MsgLetsTake}>
						<div className="message-txt">
							Let's take the challenge!
						</div>
						<div className="message-sub" ref={MsgLetsTakeNow}>
							now
						</div>
						<div className="msg-emoji" ref={MsgLetsTakeEmoji} />
					</div>
					<div className="message-main round" ref={MsgLetsTakeLoader}>
						<div
							className="msg-loader"
							ref={MsgLetsTakeLoaderDotContainer}
						>
							<div className="msg-loader-dot" />
							<div className="msg-loader-dot" />
							<div className="msg-loader-dot" />
						</div>
					</div>
				</div>
			</div>
			<div className="compilation-row msg right">
				<div className="complication-message right">
					<div className="message-main right" ref={MsgSureThing}>
						<div className="message-title right">
							Christine (Stunning.)
						</div>
						<div className="message-sub" ref={MsgSureThingNow}>
							now
						</div>
						<div className="message-txt right">
							Sure thing! We will share a version this evening.
						</div>
					</div>
					<div
						className="message-main round loader-right"
						ref={MsgSureThingLoader}
					>
						<div
							className="msg-loader"
							ref={MsgSureThingLoaderDotContainer}
						>
							<div className="msg-loader-dot right" />
							<div className="msg-loader-dot right" />
							<div className="msg-loader-dot right" />
						</div>
					</div>
				</div>
			</div>
			<div className="compilation-row msg">
				<div className="complication-message">
					<div className="message-main" ref={MsgEpic}>
						<div className="message-txt">Sounds epic. </div>
						<div className="message-sub" ref={MsgEpicNow}>
							now
						</div>
						<div
							className="msg-emoji lightning"
							ref={MsgEpicEmoji}
						/>
						<div className="message-title">
							Tobias (Awesome Agency)
						</div>
					</div>
				</div>
			</div>
		</div>
	);
});

export default CompSocialMessage;
