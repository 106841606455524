import React, {
	useRef,
	useEffect,
	useState,
	useContext,
	useImperativeHandle,
} from "react";

import useOnScreen from "../../hooks/useOnScreen";
import { useEffectOnce, destroyVideo } from "../../hooks/useEffectOnce";

const VideoElement = React.forwardRef((props, ref) => {
	const vidRef = useRef(null);
	const isVisible = useOnScreen(vidRef);

	useEffectOnce(() => {
		const vidEl = vidRef.current;
		return () => {
			destroyVideo(vidEl);
		};
	});

	useEffect(() => {
		if (vidRef.current) {
			if (isVisible) {
				const playPromise = vidRef.current.play();

				if (playPromise !== undefined) {
					playPromise
						.then((_) => {
							// Automatic playback started!
							// Show playing UI.
						})
						.catch((error) => {
							// Auto-play was prevented
							// Show paused UI.
						});
				}
			} else {
				vidRef.current.pause();
			}
		}
	}, [isVisible]);
	return (
		<video
			// style={{ visibility: isVisible ? "visible" : "hidden" }}
			className={`article-video${props.round ? " round" : ""}`}
			poster={props.poster || null}
			muted
			playsInline
			autoPlay
			loop
			src={props.src}
			ref={vidRef}
		></video>
	);
});

export default React.memo(VideoElement);
