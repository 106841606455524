import React, { createRef, Component } from "react";
import { gsap, Power2, Power0, Expo, TimelineMax } from "gsap";
import { Link } from "react-router-dom";

import Videoplayer from "./components/videoPlayer";
import LandingBtn from "./components/landingBtn";
import { viewsEnum } from "./enums";

// import { Helmet } from "react-helmet-async";

export default class LandingPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      view: -1,
      forceplay: -1,
      window: { ww: -1, wh: -1 },
      startTime: Date.now(),
      isMobile: false,
      appView: viewsEnum.LANDING,
      preloadFinished: false,
      preloadAnimFinished: false,
    };

    this.mouseMoveEl = this.mouseMoveEl.bind(this);
    this.mouseLeaveEl = this.mouseLeaveEl.bind(this);
    this.mouseEnterEl = this.mouseEnterEl.bind(this);

    this.mouseEnterBtn = this.mouseEnterBtn.bind(this);
    this.mouseLeaveBtn = this.mouseLeaveBtn.bind(this);
    this.mouseMoveBtn = this.mouseMoveBtn.bind(this);

    this.callParallaxBtn = this.callParallaxBtn.bind(this);

    this.callParallax = this.callParallax.bind(this);
    this.parallaxIt = this.parallaxIt.bind(this);

    this.initPlay = this.initPlay.bind(this);
    this.preloadDone = this.preloadDone.bind(this);

    this.checkIdle = this.checkIdle.bind(this);

    this.mousePos = { x: 0, y: 0 };
    this.lastMove = Date.now();
    this.activeHover = false;
    this.hideTrans = false;

    this.pageContent = createRef();
    this.vidContainer = createRef();
    this.vidPlayer = createRef();

    this.landingBtnRef = createRef();
    this.headerBtnRef = createRef();

    this.preloaderLogoCover = createRef();
    this.preloaderMain = createRef();

    this.refAdIndex = createRef();
    this.refAds = createRef();

    this.refSocialIndex = createRef();
    this.refSocial = createRef();

    this.refEventAnd = createRef();
    this.refEventIndex = createRef();
    this.refEvent = createRef();

    this.refRealtimeIndex = createRef();
    this.refRealtime = createRef();

    this.refAwardContainer = createRef();

    this.landingBtnRefLink = createRef();

    this.activeHoverEl = null;
    this.btnActive = true;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props !== this.prevProps) {
      //HIDE VIEW
      if (
        prevProps.appView !== this.props.appView &&
        this.props.appView !== this.state.appView
      ) {
        this.hideTrans = true;
        gsap.killTweensOf([
          this.vidContainer.current,
          this.allRefs,
          this.vidContainer.current,
          this.allAwards.current,
          this.landingBtnRef.current,
          this.preloaderMain.current.parentElement,
          this.preloaderMain.current,
          this.preloaderLogoCover.current,
        ]);
        // console.log(this.state.appView, "hide");
        gsap.to(this.vidContainer.current, { duration: 0.75, opacity: 0 });

        gsap.to([this.allRefs, this.preloaderMain.current.parentElement], {
          duration: 0.4,
          opacity: 0,
          // yPercent: -40,
          // stagger: 0.025,
          delay: 0,
          overwrite: true,
        });

        gsap.to(this.allAwards, {
          duration: 0.5,
          opacity: 0,
          // yPercent: -40,
          // stagger: 0.025,
          delay: 0,
          overwrite: true,
        });

        gsap.to(this.landingBtnRef.current, {
          onStart: () => {
            this.btnActive = false;
          },
          duration: 0.5,
          opacity: 0,
          // yPercent: -40,
          overwrite: true,
        });
      }

      if (this.state.window !== this.props.window) {
        this.setState({
          window: this.props.window,
          isMobile: this.props.window.ww <= 767,
        });
      }
    }
  }

  componentWillUnmount() {
    console.log("debug unmount landing");
    gsap.ticker.remove(this.checkIdle);
    this.autoPlayTimeline.kill();
    clearTimeout(this.fallbackTimeout);
  }
  componentDidMount() {
    console.log("debug mount landing");
    this.props.changeView(this.state.appView);
    this.allRefs =
      this.pageContent.current.querySelectorAll(".landing-text-el");

    this.allAwards = this.pageContent.current.querySelectorAll(".aw_base");

    this.mainEls = this.pageContent.current.querySelectorAll(".main-el");

    gsap.set(this.allRefs, { opacity: 0, yPercent: -50 });
    gsap.set(this.allAwards, { opacity: 0, yPercent: 35 });
    gsap.set(this.landingBtnRef.current, {
      opacity: 0,
      yPercent: 35,
    });

    this.allIndex = this.pageContent.current.querySelectorAll(".index");
    gsap.set(this.allIndex, { opacity: 0, yPercent: -35 });

    //set preloader
    gsap.set(this.preloaderLogoCover.current, { yPercent: 100 });

    this.autoPlayTimeline = gsap
      .timeline({
        paused: true,
        repeat: -1,
        delay: 2,
        ease: Power0,
        onStart: () => {
          gsap.set([this.mainEls, this.allIndex], {
            backgroundImage: "linear-gradient(white,white)",
          });
        },
      })
      .call(() => {
        this.setView(1, true);
        gsap.set([this.refAdIndex.current, this.refAds.current], {
          backgroundPosition: "0% 0%",
          backgroundImage:
            "linear-gradient(270deg,rgba(241, 3, 136, 1) 51%,rgba(255, 255, 255, 1) 51.1%)",
        });
      })
      .to(this.refAdIndex.current, 0.3, {
        backgroundPosition: "-100% 0%",
        ease: Power0,
      })
      .to(this.refAds.current, 3, {
        backgroundPosition: "-100% 0%",
        ease: Power0,
      })
      .to(this.refAdIndex.current, 0.025, {
        backgroundPosition: "-200% 0%",
        ease: Power0,
      })
      .to(this.refAds.current, 0.2, {
        backgroundPosition: "-200% 0%",
        ease: Power0,
      })
      .call(() => {
        this.setView(2, true);
        gsap.set([this.refAdIndex.current, this.refAds.current], {
          backgroundImage: "linear-gradient(white,white)",
        });
        gsap.set([this.refSocialIndex.current, this.refSocial.current], {
          backgroundPosition: "0% 0%",
          backgroundImage:
            "linear-gradient(270deg,rgba(241, 3, 136, 1) 51%,rgba(255, 255, 255, 1) 51.1%)",
        });
      })
      .to(this.refSocialIndex.current, 0.3, {
        backgroundPosition: "-100% 0%",
        ease: Power0,
      })
      .to(this.refSocial.current, 3, {
        backgroundPosition: "-100% 0%",
        ease: Power0,
      })
      .to(this.refSocialIndex.current, 0.025, {
        backgroundPosition: "-200% 0%",
        ease: Power0,
      })
      .to(this.refSocial.current, 0.2, {
        backgroundPosition: "-200% 0%",
        ease: Power0,
      })
      .call(() => {
        this.setView(3, true);
        gsap.set([this.refSocialIndex.current, this.refSocial.current], {
          backgroundImage: "linear-gradient(white,white)",
        });
        gsap.set(
          [
            this.refEventIndex.current,
            this.refEvent.current,
            this.refEventAnd.current,
          ],
          {
            backgroundPosition: "0% 0%",
            backgroundImage:
              "linear-gradient(270deg,rgba(241, 3, 136, 1) 51%,rgba(255, 255, 255, 1) 51.1%)",
          }
        );
      })
      .to(this.refEventAnd.current, 0.3, {
        backgroundPosition: "-100% 0%",
        ease: Power0,
      })
      .to(this.refEventIndex.current, 0.3, {
        backgroundPosition: "-100% 0%",
        ease: Power0,
      })
      .to(this.refEvent.current, 3, {
        backgroundPosition: "-100% 0%",
        ease: Power0,
      })
      .to(this.refEventAnd.current, 0.025, {
        backgroundPosition: "-200% 0%",
        ease: Power0,
      })

      .to(this.refEventIndex.current, 0.025, {
        backgroundPosition: "-200% 0%",
        ease: Power0,
      })
      .to(this.refEvent.current, 0.2, {
        backgroundPosition: "-200% 0%",
        ease: Power0,
      })
      .call(() => {
        this.setView(4, true);
        gsap.set(
          [
            this.refEventIndex.current,
            this.refEvent.current,
            this.refEventAnd.current,
          ],
          {
            backgroundImage: "linear-gradient(white,white)",
          }
        );
        gsap.set([this.refRealtimeIndex.current, this.refRealtime.current], {
          backgroundPosition: "0% 0%",
          backgroundImage:
            "linear-gradient(270deg,rgba(241, 3, 136, 1) 51%,rgba(255, 255, 255, 1) 51.1%)",
        });
      })
      .to(this.refRealtimeIndex.current, 0.3, {
        backgroundPosition: "-100% 0%",
        ease: Power0,
      })
      .to(this.refRealtime.current, 3, {
        backgroundPosition: "-100% 0%",
        ease: Power0,
      })
      .to(this.refRealtimeIndex.current, 0.025, {
        backgroundPosition: "-200% 0%",
        ease: Power0,
      })
      .to(this.refRealtime.current, 0.2, {
        backgroundPosition: "-200% 0%",
        ease: Power0,
      })
      .call(() => {
        gsap.set([this.refRealtimeIndex.current, this.refRealtime.current], {
          backgroundImage: "linear-gradient(white,white)",
        });
      });
    this.autoPlayTimeline.play();

    // this.props.changeHeaderState("typo");
    gsap.ticker.add(this.checkIdle);

    this.fallbackTimeout = setTimeout(() => {
      if (!this.state.preloadFinished) {
        // console.log("preload Failed");
        this.initPlay();
      } else {
        // console.log("preload already Done");
      }
    }, 3000);
  }

  isTouchDevice() {
    return (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  }

  checkIdle() {
    const now = Date.now();

    if (
      (!this.activeHover && now - this.lastMove > 1800) ||
      (!this.activeHover && this.props.deviceType) === "touchOnly"
    ) {
      this.startAutoPlay();
    } else if (this.activeHover) {
      this.lastMove = now;
    }
  }

  startAutoPlay() {
    if (this.autoPlayTimeline.paused()) {
      this.autoPlayTimeline.restart();
    }
  }

  stopAutoPlay() {
    this.lastMove = Date.now();
    this.autoPlayTimeline.pause();

    gsap.set(
      [
        this.refAdIndex.current,
        this.refAds.current,
        this.refSocialIndex.current,
        this.refSocial.current,
        this.refEventIndex.current,
        this.refEvent.current,
        this.refEventAnd.current,
        this.refRealtimeIndex.current,
        this.refRealtime.current,
      ],
      { backgroundImage: "linear-gradient(white,white)" }
    );
  }

  preloadDone() {
    gsap.to(this.allIndex, {
      opacity: 1,
      duration: 2,
      yPercent: 0,
      delay: 1,
      stagger: 0.05,
      ease: Expo.easeOut,
      onStart: () => {
        this.setState({ preloadAnimFinished: true });
      },
    });

    gsap.to([this.allRefs, this.landingBtnRef.current], {
      duration: 2,
      opacity: 1,
      yPercent: 0,
      stagger: 0.05,
      delay: 0,
      ease: Expo.easeOut,
    });
    gsap.to([this.allAwards], {
      duration: 2,
      opacity: 1,
      yPercent: 0,
      stagger: 0.05,
      delay: 0,
      ease: Expo.easeOut,
    });
  }

  mouseMoveBtn(e) {
    e.preventDefault();
    if (!this.state.preloadAnimFinished || this.hideTrans || !this.btnActive)
      return;

    const targetQuery = this.landingBtnRef.current;
    gsap.to(targetQuery, {
      backgroundColor: "rgba(241,3,136,1)",
      duration: 0.3,
      scale: 1.1,
      overwrite: true,
      opacity: 1,
    });
    if (this.isTouchDevice()) return;
    this.callParallaxBtn(e, e.target);
  }

  mouseMoveEl(e) {
    e.preventDefault();
    if (
      !this.state.preloadAnimFinished ||
      this.hideTrans ||
      this.activeHoverEl !== e.target ||
      this.props.deviceType === "touchOnly"
    )
      return;

    this.lastMove = Date.now();
    if (this.isTouchDevice()) return;
    this.callParallax(e, e.target);
  }

  initPlay() {
    // console.log("playing");
    clearTimeout(this.fallbackTimeout);

    if (this.state.preloadFinished) return;
    this.setState({ preloadFinished: true });

    gsap.to(this.preloaderLogoCover.current, 0.5, {
      yPercent: 0,
      delay: 0.3,
      onComplete: () => {
        if (this.preloaderMain.current) {
          gsap.to(this.preloaderMain.current, 0.5, {
            yPercent: 100,
            onComplete: () => {
              if (this.preloaderMain.current) {
                gsap.set(this.preloaderMain.current.parentElement, {
                  display: "none",
                });
                gsap.set(".preloader", { opacity: 0 });
                this.preloadDone();
                this.props.initLoad();
              }
            },
          });
        }
      },
    });
  }

  mouseLeaveEl(e) {
    e.preventDefault();
    if (!this.state.preloadAnimFinished || this.hideTrans) return;

    this.activeHover = false;
    this.activeHoverEl = null;
    this.props.changeCursorState("load");
    const targetQuery = e.target.querySelector(".landing-text-el");
    gsap.to(targetQuery, {
      //color: "#fff",
      duration: 0.3,
      x: 0,
      y: 0,
      scale: 1,
    });

    gsap.to(targetQuery.querySelectorAll(".main-el,.index"), {
      duration: 0.3,
      backgroundImage: "linear-gradient(white,white)",
    });

    gsap.to(this.vidContainer.current, {
      opacity: 0,
      duration: 0.15,
      delay: 0.3,
    });
    gsap.to(this.allRefs, { opacity: 1 });

    //reset bar overlay
    if (this.props.deviceType !== "touchOnly") this.btnActive = true;
    gsap.to(this.landingBtnRef.current, {
      backgroundColor: "rgba(241,3,136,0)",
      opacity: 1,
      duration: 0.3,
      rotationX: 0,
      rotationY: 0,
      x: 0,
      y: 0,
      scale: 1,
      overwrite: true,
      onComplete: () => {
        if (this.props.deviceType === "touchOnly") this.btnActive = true;
      },
    });

    gsap.to(this.refAwardContainer.current, {
      duration: 0.3,
      opacity: 1,
      rotationX: 0,
      rotationY: 0,
      x: 0,
      y: 0,
      scale: 1,
      overwrite: true,
    });
  }

  mouseEnterBtn(e) {
    e.preventDefault();
    if (!this.state.preloadAnimFinished || this.hideTrans || !this.btnActive)
      return;
    // console.log("mouseEnterBtn");

    this.props.changeCursorState("idle");
    const targetQuery = this.landingBtnRef.current;
    gsap.to(targetQuery, {
      backgroundColor: "rgba(241,3,136,1)",
      duration: 0.3,
      scale: 1.1,
      overwrite: true,
      opacity: 1,
    });
  }

  mouseLeaveBtn(e) {
    e.preventDefault();
    if (!this.state.preloadAnimFinished || this.hideTrans) return;
    // console.log("mouseLeaveBtn");

    gsap.to(this.landingBtnRef.current, {
      backgroundColor: "rgba(241,3,136,0)",
      duration: 0.3,
      rotationX: 0,
      rotationY: 0,
      x: 0,
      y: 0,
      scale: 1,
    });
  }
  mouseEnterEl(e) {
    e.preventDefault();
    if (!this.state.preloadAnimFinished || this.hideTrans) return;
    // console.log("mouseEnterEL");
    /*this.landingBtnRefLink.current.classList.add("disabled-link");*/
    this.props.changeCursorState("active");
    this.activeHover = true;
    this.activeHoverEl = e.target;

    this.stopAutoPlay();

    const targetQuery = e.target.querySelector(".landing-text-el");

    gsap.to(targetQuery, {
      duration: 0.3,
      scale: 1.1,
      overwrite: true,
      opacity: 1,
      x: 1,
      y: 1,
      rotationX: 1,
      rotationY: 1,
    });

    gsap.to(targetQuery.querySelectorAll(".main-el,.index"), {
      duration: 0.3,
      backgroundImage: "linear-gradient(rgb(241,3,136),rgb(241,3,136))",
    });

    this.allRefs.forEach((queryEl) => {
      if (targetQuery !== queryEl) {
        gsap.to(queryEl, {
          opacity: 0.1,
          scale: 1,
          x: 0,
          y: 0,
          overwrite: true,
        });
      }
    });

    gsap.to(this.landingBtnRef.current, {
      onStart: () => {
        this.btnActive = false;
      },
      duration: 0.3,
      opacity: 0,
      backgroundColor: "rgba(241,3,136,0)",

      rotationX: 0,
      rotationY: 0,
      x: 0,
      y: 0,
      overwrite: true,
    });

    gsap.to(this.refAwardContainer.current, {
      duration: 0.3,
      opacity: 0,
      backgroundColor: "rgba(241,3,136,0)",
      rotationX: 0,
      rotationY: 0,
      x: 0,
      y: 0,
    });

    this.setView(e.target.dataset.view);
  }

  setView(view, autoplay = false) {
    if (typeof view !== "undefined" && !this.hideTrans) {
      const opacity = autoplay ? 0.25 : 1;

      gsap.to(this.vidContainer.current, {
        opacity: opacity,
        duration: 0.25,
        overwrite: true,
      });
    } else {
      gsap.to(this.vidContainer.current, {
        opacity: 0,
        duration: 0.25,
        overwrite: true,
      });
    }
    this.setState({
      view: view,
      forceplay: Date.now(),
    });
  }

  callParallax(e, parent) {
    this.parallaxIt(e, parent, parent.querySelector(".landing-text-el"), 20);
  }

  callParallaxBtn(e, parent) {
    this.parallaxIt(e, parent, this.landingBtnRef.current, 20);
  }

  parallaxIt(e, parent, target, movement) {
    var boundingRect = parent.getBoundingClientRect();
    const pageX = e.clientX || e.touches?.[0]?.clientX || e.pageX;
    const pageY = e.clientY || e.touches?.[0]?.clientY || e.pageY;
    var relX = pageX - boundingRect.left;
    var relY = pageY - boundingRect.top;

    var movementX =
      ((relX - boundingRect.width / 2) / boundingRect.width) * movement;
    var movementY =
      ((relY - boundingRect.height / 2) / boundingRect.height) * movement;
    gsap.to(target, {
      duration: 0.3,
      x: movementX,
      y: movementY,
      rotationX: (movementY / 3) * 2.5,
      rotationY: (-movementX / 3) * 2.5,
      ease: Power2.easeOut,
    });
  }

  render() {
    //console.log(this.props.currentPathname === "/get-in-touch");
    return (
      <React.Fragment>
        {/*        <Helmet>
          <title>home - Stunnıng.</title>

          <meta
            name="description"
            content="We craft tomorrow's ads, social and events in real-time today. Stunning real-time studio - Hamburg, Germany."
          />
        </Helmet>*/}

        <div className="preloader">
          <div className="s-logo" ref={this.preloaderMain}>
            <div className="s-logo-cover" ref={this.preloaderLogoCover}></div>
          </div>
        </div>
        <div ref={this.pageContent}>
          <div className="page-content-container">
            <div className="landing-text-el nohover left">We craft tmrws</div>
            <div className="landing-text-el spacer">/</div>
          </div>
          <div className="page-content-container">
            <div
              className="landing-text-el-wrap"
              onMouseEnter={this.mouseEnterEl}
              onMouseMove={this.mouseMoveEl}
              onMouseLeave={this.mouseLeaveEl}
              data-view={1}
            >
              <div className="landing-text-el">
                <div className="index" ref={this.refAdIndex}>
                  01
                </div>
                <div className="main-el" ref={this.refAds}>
                  ads
                </div>
              </div>
            </div>
            <div className="landing-text-el spacer">/</div>
            <div
              className="landing-text-el-wrap"
              onMouseEnter={this.mouseEnterEl}
              onMouseMove={this.mouseMoveEl}
              onMouseLeave={this.mouseLeaveEl}
              data-view={2}
            >
              <div className="landing-text-el">
                <div className="index" ref={this.refSocialIndex}>
                  02
                </div>
                <div className="main-el" ref={this.refSocial}>
                  social
                </div>
              </div>
            </div>
            <div className="landing-text-el spacer">/</div>

            <div
              className="landing-text-el-wrap"
              onMouseEnter={this.mouseEnterEl}
              onMouseMove={this.mouseMoveEl}
              onMouseLeave={this.mouseLeaveEl}
              data-view={3}
            >
              <div className="landing-text-el">
                <div className="main-el nohover" ref={this.refEventAnd}>
                  &#38;
                </div>
                <div className="index" ref={this.refEventIndex}>
                  03
                </div>
                <div className="main-el" ref={this.refEvent}>
                  events
                </div>
              </div>
            </div>
          </div>
          <div className="page-content-container">
            <div className="landing-text-el nohover left">in</div>
            <div
              className="landing-text-el-wrap"
              onMouseEnter={this.mouseEnterEl}
              onMouseMove={this.mouseMoveEl}
              onMouseLeave={this.mouseLeaveEl}
              data-view={4}
            >
              <div className="landing-text-el">
                <div className="index" ref={this.refRealtimeIndex}>
                  04
                </div>
                <div className="main-el" ref={this.refRealtime}>
                  real-time
                </div>
              </div>
            </div>
            <div className="landing-text-el spacer">/</div>

            <div className="landing-text-el">
              <div className="main-el higher">today.</div>
            </div>
          </div>
          <div className="page-content-container">
            <div
              className="lading-btn-container"
              onMouseEnter={this.mouseEnterBtn}
              onMouseMove={this.mouseMoveBtn}
              onMouseLeave={this.mouseLeaveBtn}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <Link
                ref={this.landingBtnRefLink}
                onClick={(e) => {
                  if (
                    this.props.currentPathname === "/cases" ||
                    !this.btnActive
                  )
                    e.preventDefault();
                  this.btnActive = true;
                }}
                to={"/cases"}
              >
                <LandingBtn ref={this.landingBtnRef} />
              </Link>
            </div>
          </div>
          <div className="page-content-container">
            <div className="award-container" ref={this.refAwardContainer}>
              <div className="aw_base aw_lovie"></div>
              <div className="aw_base aw_clio"></div>
              <div className="aw_base aw_adc"></div>
              <div className="aw_base aw_fwa"></div>
              <div className="aw_base aw_13th"></div>
              <div className="aw_base aw_nyf"></div>
              <div className="aw_base aw_newmedia"></div>
              <div className="aw_base aw_aww"></div>
              <div className="aw_base aw_dda"></div>
            </div>
          </div>
        </div>
        <div className="background-video">
          <div
            style={{
              opacity: 0,
              overflow: "hidden",
            }}
            className="virtual-influencer w-embed"
            ref={this.vidContainer}
          >
            <Videoplayer
              key={this.props.cKey + "current_videoPlayer"}
              forcehold={this.props.forcehold}
              windowProps={this.state.window}
              forceplay={this.state.forceplay}
              loop={this.state.vidLoop}
              view={this.state.view}
              ref={this.vidPlayer}
              initPlay={this.initPlay}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export const LandingPageMemo = React.memo(LandingPage);
