import React, { useRef, useEffect, useState, useImperativeHandle } from "react";

import { gsap } from "gsap";

import BtnParallax from "./Elements/BtnParallax";

import BurgerIcon from "./Elements/BurgerIcon";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const NavMenu = React.forwardRef((props, ref) => {
	const menuRef = useRef(null);
	const menuBtnCnt = useRef(null);
	const imprintBtn = useRef(null);
	const [windowHeight, setWindowHeight] = useState(-1);

	const [menuOpen, setMenuOpen] = useState(false);

	const navigate = useNavigate();

	useImperativeHandle(
		ref,
		() => {
			return {
				openMenu() {
					handleMenuOpen(true);
				},
				toogleMenu() {
					handleToogleMenu();
				},

				closeMenu() {
					handleMenuOpen(false);
				},
				isMenuOpen() {
					return menuOpen;
				},
			};
		},
		[menuOpen]
	);

	const handleEsc = (e) => {
		var isEscape = false;
		if ("key" in e) {
			isEscape = e.key === "Escape" || e.key === "Esc";
		} else {
			isEscape = e.keyCode === 27;
		}
		if (isEscape) {
			handleMenuOpen(false);
		}
	};

	const updateWindowSize = () => {
		setWindowHeight(window.innerHeight);
	};
	useEffect(() => {
		updateWindowSize();
		// Init menu position
		gsap.set(menuRef.current, { xPercent: -100 });

		const allMenuBtns = menuBtnCnt?.current?.children || [];

		allMenuBtns.forEach((menuBtn) => {
			gsap.set(menuBtn, {
				autoAlpha: 0,
				xPercent: -50,
			});
		});
		gsap.set(imprintBtn.current, { autoAlpha: 0 });

		window.addEventListener("resize", updateWindowSize);
		window.addEventListener("keydown", handleEsc);
		return () => {
			window.removeEventListener("keydown", handleEsc);
			window.removeEventListener("resize", updateWindowSize);
		};
	}, []);

	useEffect(() => {
		const menuDuration = 0.3;

		const allMenuBtns = menuBtnCnt?.current?.children || [];

		allMenuBtns.forEach((menuBtn) => {
			gsap.killTweensOf(menuBtn);
		});

		gsap.killTweensOf(menuRef.current);
		gsap.to(menuRef.current, {
			onStart: () => {
				gsap.set(menuRef.current, { display: "inherit" });
			},
			duration: menuDuration,
			xPercent: menuOpen ? 0 : -100,
			onComplete: () => {
				if (!menuOpen) gsap.set(menuRef.current, { display: "none" });
			},
		});

		if (menuOpen) {
			if (allMenuBtns.length) {
				gsap.to(allMenuBtns, {
					delay: menuDuration,
					xPercent: 0,
					autoAlpha: 1,
					stagger: 0.1,
					onComplete: () => {
						gsap.to(imprintBtn.current, {
							duration: menuDuration,
							autoAlpha: 1,
						});
					},
				});
			}
		} else {
			allMenuBtns.forEach((menuBtn) => {
				gsap.to(menuBtn, {
					autoAlpha: 0,
					duration: menuDuration / 2,
					xPercent: -50,
				});
			});
		}
	}, [menuOpen]);

	const handleMenuOpen = (open, instant = false) => {
		const dropDuration = 0.3;

		if (open) {
			// gsap.to(searchBarDropDownRef.current, {
			// 	duration: instant ? 0 : dropDuration,
			// 	autoAlpha: 1,
			// });
			setMenuOpen(true);
		} else {
			// gsap.to(searchBarDropDownRef.current, {
			// 	duration: instant ? 0 : dropDuration,
			// 	autoAlpha: 0,
			// });
			setMenuOpen(false);
		}
	};

	const handleToogleMenu = () => {
		handleMenuOpen(!menuOpen);
	};

	const navigateUrl = (url) => {
		navigate(url);
	};

	return (
		<>
			{/*			
				<BtnParallax
					text={"Menu"}
					style={{ zIndex: 1 }}
					nooutline
					nav
					onclick={handleToogleMenu}
					ref={ref}
				/>
			</div>*/}
			<div
				className="header-nav-cnt-v3"
				style={{ pointerEvents: "none" }}
				ref={props.reference}
			>
				<div
					className="header-burger-btn-insta-v3"
					onClick={handleToogleMenu}
					style={{
						// marginTop: 8,
						// marginRight: -5,
						// marginLeft: -10,
						pointerEvents: "auto",
						zIndex: 1,
					}}
				>
					<BurgerIcon open={menuOpen} />
				</div>
			</div>
			<div
				className="nav-menu-cnt"
				style={{
					zIndex: 0,
					height: windowHeight,
					pointerEvents: "all",
				}}
				ref={menuRef}
				onClick={() => handleMenuOpen(false)}
			>
				<div className="nav-menu-content" ref={menuBtnCnt}>
					<BtnParallax
						text={"Home"}
						nooutline
						nav
						onclick={() => navigateUrl("/")}
					/>
					<BtnParallax
						text={"Solutions"}
						nooutline
						nav
						onclick={() => {
							props.setFilter("solution");
							navigateUrl("/cases/solution");
						}}
					/>
					<BtnParallax
						text={"Work"}
						nooutline
						nav
						onclick={() => {
							props.setFilter("work");
							navigateUrl("/cases/work");
						}}
					/>
					<BtnParallax
						nav
						text={"Get in Touch"}
						onclick={() => navigateUrl("/get-in-touch")}
					/>
				</div>

				<div className="nav-menu-content bottom">
					<div
						// style={{ pointerEvents: "all", background: "red" }}
						className="back-txt transparent"
						ref={imprintBtn}
						onClick={() => navigateUrl("/privacy")}
					>
						Imprint & Privacy Policy
					</div>
				</div>
			</div>
		</>
	);
});

export default NavMenu;
