import React from "react";

import ArtileEl, {
	Pink,
	White,
	Desc,
	Header,
	CustomImage,
} from "../../components/Elements/ArticleEl";

import cases from "../cases";

const componentName = "GShockFiveMinutes";

const shorthand = cases.find((x) => x.component == componentName).shorthand;

if (typeof shorthand == "undefined")
	console.error("Component can't find shorthand: " + componentName);
export default () => (
	<>
		<div className="section-div" style={{}}>
			<div className="section-content">
				<div className="section-hero">
					<div
						className="section-title-sub grad-txt"
						style={{ paddingLeft: 0, paddingRight: 0 }}
					>
						"Memento meets The Last of Us"
					</div>
				</div>
			</div>
		</div>
		<div className="section-div no-p">
			<div
				className="section-img"
				style={{
					// maxWidth: "1920px",
					paddingTop: "0px",
					marginTop: "0px",
				}}
			>
				<img
					src={`/img/cases/${shorthand}/image/img_02.jpg`}
					style={{ marginTop: 40, borderRadius: 15, width: "100%" }}
				/>
			</div>
		</div>
		<div
			className="section-div"
			style={{
				// maxWidth: "1920px",
				// paddingTop: "0.8em",
				paddingTop: 0,
			}}
		>
			<div className="section-content">
				<div className="section-description">
					Five Minutes is a unique and immersive film experience that
					aims to engage both gamers and non-gamers alike. It offers
					an exciting blend of traditional film-making and interactive
					gameplay, which elevates the emotional impact of the story
					and makes the viewer an active participant in the narrative.
					This experience is primarily designed for mobile devices and
					has been optimized to run seamlessly in browsers using HTML5
					and Javascript.
				</div>
			</div>
		</div>
		<div className="section-div">
			<div className="section-content">
				<div className="section-hero">
					<div className="section-title-sub grad-txt">
						Award winning.
						<br />
						4.2 Mio views.
						<br />
						Accessible on all devices.
					</div>
				</div>
				<div className="section-description">
					For this interactive short film, we were responsible for the
					lead creative and lead development. This unique combination
					enabled us to combine emotion and technology right from the
					get-go. <br /> <br />
					Five Minutes has garnered a loyal following among gamers,
					filmlovers and zombie enthusiasts worldwide and has earned
					numerous accolades and awards for its innovative approach
					and compelling storytelling.
				</div>
			</div>
			<div
				className="section-img"
				style={{
					// maxWidth: "1920px",
					paddingTop: "0px",
					marginTop: "0px",
				}}
			>
				<img
					src={`/img/cases/${shorthand}/image/img_01.jpg`}
					style={{ marginTop: 40, borderRadius: 15, width: "100%" }}
				/>
			</div>
		</div>
	</>
);
