import React, { createRef, Component } from "react";
import { gsap } from "gsap";

import { viewsEnum } from "./enums";

import Obfuscate from "react-obfuscate";

export default class PrivacyPolicy extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = { appView: viewsEnum.COOKIE };
		this.hideTrans = false;

		this.refCookieContainer = createRef();
	}
	componentDidUpdate(prevProps, prevState, snapshot) {}

	componentDidMount() {
		this.props.changeView(this.state.appView);

		this.props.initLoad();
		this.props.changeHeaderState("logo");
	}
	render() {
		return (
			<div className="page-content cookie">
				<div className="section-div grow-universe">
					<div className="section-content">
						<div className="section-hero">
							<div className="section-title-sub">
								Imprint & Privacy Policy
							</div>
						</div>
						<div className="section-description">
							<span className="text-white">Represented by:</span>
							<br />
							<br />
							Maximilian Niemann
							<br />
							Zum Wachtelkönig 23a
							<br />
							21147 Hamburg
							<br />
							<br />
							<Obfuscate tel="+49 40 7291 62 31" />
							<br />
							<Obfuscate email="cheers@stunning.studio" />
							<br />
							<a
								href="https://policies.google.com/privacy?hl=en"
								target="_new"
							>
								<strong />
							</a>
						</div>
					</div>
				</div>
				<div className="section-div grow-universe">
					<div className="section-content">
						<div className="section-hero">
							<div className="section-title-sub">
								Data Protection
							</div>
						</div>
						<div className="section-description">
							The protection of your personal data is very
							important to us. We naturally comply with the
							provisions of the EU GDPR. The following information
							provides a simple overview of what happens to your
							personal data when you use our service. Personal
							data is all data with which you can be personally
							identified.
							<br />
							<br />
							<span className="text-white">
								Purpose and Legal Basis
							</span>
							<br />
							<br />
							In order to ensure the operation of our website and
							to inform you optimally, we use technical options to
							monitor the use of our website. This data collection
							and analysis services are explained separately in
							this document. In no case do we use the data
							collected here to draw conclusions about your
							person.
							<br />
							<br />
							The legal basis for this processing is the
							legitimate interest (Article 6 (1) lit.f GDPR) in
							operating our service professionally and securely.
							In addition, there are situations in which you want
							to provide us with personal data, such as when
							registering.In this case, we will ask for your
							consent to processing (Article 6 (1) lit.a GDPR).
							<br />
							<br />
							<span className="text-white">
								What do we use your data for?
							</span>
							<br />
							<br />
							Part of the data is collected to ensure the
							error-free provision of the service. Other data can
							be used to analyze your user behavior.
							<br />
							<br />
							<span className="text-white">
								Recipient or Disclosure of Personal Data
							</span>
							<br />
							<br />
							We do not disclose personal data to third parties
							without your express consent.
							<br />
							<br />
							<span className="text-white">
								Notes on Protecting the Rights of Data Subjects
								<br />
							</span>
							<br />
							a) Revocation of Consent
							<br />
							<br />
							Many data processing operations are only possible
							with your express consent. You can revoke your
							consent at any time. An informal email to us is
							sufficient. The legality of the data processing
							carried out before the revocation remains unaffected
							by the revocation.
							<br />
							<br />
							b) Right to Information of the Data Subject
							<br />
							<br />
							You have the right to free information about your
							stored personal data, their origin and recipient and
							the purpose of the data processing at any time. You
							also have the right to have this data corrected or
							deleted.
							<br />
							<br />
							c) Right to lodge a complaint
							<br />
							<br />
							According to Art. 77 of the GDPR, you have the right
							to lodge a complaint with the supervisory authority.
							<br />
							<br />
							<span className="text-white">
								Information on the right to object
							</span>
							<br />
							<br />
							We would like to inform you that, according to Art.
							21 of the GDPR, you have the right to object in
							cases where your personal data is being processed.
							The objection can be made in writing or by email to
							the address mentioned above.
							<br />
							<br />
							<span className="text-white">
								Right to lodge a complaint with the competent
								supervisory authority
							</span>
							<br />
							<br />
							If there is a violation of data protection laws, the
							data subject has the right to lodge a complaint with
							the competent supervisory authority. The competent
							supervisory authority in matters of data protection
							is:
							<br />
							<br />
							Der Hamburgische Beauftragte für Datenschutz und
							Informationsfreiheit
							<br />
							Ludwig-Erhard-Str 22, 7. OG
							<br />
							20459 Hamburg
							<br />
							<a
								href="https://www.datenschutz-berlin.de/"
								target="_new"
							>
								<strong>
									<br />
								</strong>
							</a>
							Telephone: 040 / 428 54 - 4040
							<br />
							Fax: 040 / 428 54 - 4000
							<br />
							<br />
							https://datenschutz-hamburg.de
							<br />
							mailbox@datenschutz.hamburg.de
							<a target="_new" href="#">
								<strong>
									<br />
									<br />
								</strong>
							</a>
							<span className="text-white">
								Right to data portability
							</span>
							<br />
							<br />
							You have the right to receive the personal data
							concerning you, which you have provided to us, in a
							structured, commonly used, and machine-readable
							format and have the right to transmit those data to
							another controller without hindrance from us, where
							the processing is based on consent or a contract.
							<br />
							<br />
							<span className="text-white">
								Information, blocking, deletion
							</span>
							<br />
							<br />
							You have the right to free information about your
							stored personal data, their origin and recipients,
							and the purpose of data processing at any time
							within the scope of applicable legal provisions, as
							well as the right to correct, block, or delete this
							data. Legal obligations to retain data must be
							observed. If you have any further questions
							regarding personal data, you can contact us at any
							time using the contact information provided.
							<br />
						</div>
					</div>
				</div>
				<div className="section-div grow-universe">
					<div className="section-content">
						<div className="section-hero">
							<div className="section-title-sub">Cookies</div>
						</div>
						<div className="section-description">
							This is the Cookie Policy for Stunning Studio,
							accessible from www.stunning.studio
							<br />
							<br />
							The website uses so-called cookies in some places.
							Cookies do not cause any damage to your computer and
							serve to make our offer more user-friendly,
							effective and secure. Cookies are small text files
							that are stored on your computer and saved by your
							browser. Most of the cookies we use are so-called
							"session cookies". <br />
							<br />
							They are automatically deleted at the end of your
							visit. Other cookies remain stored on your device
							until you delete them. These cookies enable us to
							recognize your browser on your next visit. You can
							set your browser to inform you about the setting of
							cookies and to allow cookies only in individual
							cases, to exclude the acceptance of cookies for
							certain cases or in general, and to activate the
							automatic deletion of cookies when closing the
							browser. <br />
							<br />
							If you deactivate cookies, the functionality of this
							website may be limited.Cookies that are necessary
							for the electronic communication process or for the
							provision of certain functions desired by you (e.g.
							shopping cart function) are stored on the basis of
							Art. 6 (1) lit. f DSGVO. <br />
							<br />
							The website operator has a legitimate interest in
							the storage of cookies for the technically
							error-free and optimized provision of its services.
							If other cookies (e.g. cookies for analyzing your
							browsing behavior) are stored, they are treated
							separately in this data protection declaration.
							<br />
							<br />
							<span className="text-white">Server log files</span>
							<br />
							The provider of the pages automatically collects and
							stores information in so-called server log files,
							which your browser automatically transmits to us.{" "}
							<br />
							<br />
							These are:
							<br />
							<br />
							Browser type and browser version
							<br />
							Operating system used
							<br />
							Referrer URL
							<br />
							Hostname of the accessing computer
							<br />
							Time of the server request
							<br />
							IP address
							<br />
							<br />
							These data will not be merged with other data
							sources. <br />
							<br />
							The legal basis for this processing is the
							legitimate interest (Article 6 (1) lit. f EU GDPR)
							of operating this website professionally and
							securely.
							<br />
						</div>
					</div>
				</div>
				<div className="section-div grow-universe">
					<div className="section-content">
						<div className="section-hero">
							<div className="section-title-sub">
								Plugins &amp; Tools
							</div>
						</div>
						<div className="section-description">
							<span className="text-white">Vimeo Videos</span>
							<br />
							<br />
							The legal basis for processing your personal data
							that is processed through the integration of Vimeo
							videos is Art. 6 para. 1 lit. a EU-GDPR.II. <br />
							<br />
							The processing of your personal data through the
							integration of Vimeo videos serves to provide an
							appealing, uniform and device-independent display of
							video content on our website.
							<br />
							<br />
							We use Vimeo videos in extended data protection
							mode. This mode ensures that no further information
							about your visit to our website is stored beyond the
							integration. Therefore, your personal data will be
							deleted as soon as they are no longer necessary to
							achieve the purpose of their processing. This is the
							case at the latest after the end of your visit to
							our website.
							<br />
							<br />
							You have the option to revoke the processing of your
							personal data in the context of the display of video
							content for the future at any time. You can make
							this revocation in particular by ending the
							application and/or reloading the website.We have no
							influence on the data processing by Vimeo. Further
							information on this can be found at:{" "}
							<a href="https://vimeo.com/privacy" target="_new">
								<strong className="text-white">
									https://vimeo.com/privacy
								</strong>
							</a>
							.<br />
							<br />
							<span className="text-white">Google Analytics</span>
							<br />
							<br />
							This website uses Google Analytics, a web analytics
							service provided by Google Inc. ("Google"). Google
							Analytics uses so-called "cookies", which are text
							files that are stored on your computer and enable an
							analysis of your use of the website.
							<br />
							<br />
							The information generated by the cookie about your
							use of the website will generally be transmitted to
							and stored by Google on servers in the United
							States. If IP anonymization is activated on this
							website, however, your IP address will be truncated
							beforehand by Google within member states of the
							European Union or in other contracting states of the
							Agreement on the European Economic Area.
							<br />
							<br />
							Only in exceptional cases will the full IP address
							be transmitted to a Google server in the USA and
							truncated there. On behalf of the operator of this
							website, Google will use this information to
							evaluate your use of the website, to compile reports
							on website activity and to provide the website
							operator with further services related to website
							and internet use. The IP address transmitted by your
							browser as part of Google Analytics will not be
							merged with other data from Google.
							<br />
							<br />
							You can prevent Google from collecting and
							processing the data generated by the cookie and
							related to your use of the website (including your
							IP address) by downloading and installing the
							browser plugin available at the following link.
							<br />
							<br />
							Link to browser add-on to deactivate Google
							Analytics:
							<br />
							<a
								href="http://tools.google.com/dlpage/gaoptout?hl=en"
								target="_new"
							>
								<strong className="text-white">
									http://tools.google.com/dlpage/gaoptout?hl=en
									<br />
									<br />
								</strong>
							</a>
							Link to Google Privacy Policy:
							<br />
							<a
								href="https://policies.google.com/privacy?hl=en"
								target="_new"
							>
								<strong className="text-white">
									https://policies.google.com/privacy?hl=en
								</strong>
							</a>
						</div>
					</div>
				</div>
				<div className="section-div grow-universe">
					<div className="section-content">
						<div className="section-hero">
							<div className="section-title-sub">
								More Information
							</div>
						</div>
						<div className="section-description">
							Hopefully that has clarified things for you and as
							was previously mentioned if there is something that
							you aren't sure whether you need or not it's usually
							safer to leave cookies enabled in case it does
							interact with one of the features you use on our
							site.
							<br />
							<br />
							However if you are still looking for more
							information then you can contact us through one of
							our preferred contact methods:
							<br />
							<br />
							Email: dsvgo@stunning.studio
						</div>
					</div>
				</div>
			</div>
		);
	}
}
