import React from "react";

import ArtileEl, {
	Pink,
	White,
	Desc,
	Header,
	CustomImage,
} from "../../components/Elements/ArticleEl";

import cases from "../cases";

const componentName = "SocialTurnaround";

const shorthand = cases.find((x) => x.component == componentName).shorthand;

if (typeof shorthand == "undefined")
	console.error("Component can't find shorthand: " + componentName);
export default () => (
	<>
		<div className="section-div">
			<div className="section-content">
				<div className="section-hero">
					<div
						className="section-title-sub grad-txt"
						style={{ paddingLeft: 0, paddingRight: 0 }}
					>
						"React swiftly to cultural or political trends and
						generate buzz on social media."
					</div>
				</div>
			</div>
		</div>

		<ArtileEl type={"text-left-half"}>
			<Header customType="header">Be the hype.</Header>
			<Desc customType="desc">
				At Stunnıng. we believe that brands should be able to create a
				positive influence on their audiences in real-time by leveraging
				the latest technologies to{" "}
				<White>craft content at the speed of culture.</White> Thanks to
				modern game engines, brands can become increasingly agile in
				volatile times so they can stay culturally relevant; setting
				trends before they happen.
			</Desc>
			<CustomImage
				customType="image"
				src={`/img/cases/${shorthand}/image/img_02.jpg`}
			/>
		</ArtileEl>

		<ArtileEl type={"text-right-half"}>
			<Header customType="header">Dance in everybody's feed.</Header>
			<Desc customType="desc">
				Virtual influencers are the future of social media and the best
				part is, they never have a bad hair day! If you want to enhance
				your social presence, these digital characters are an excellent
				starting point. Having their own unique personalities,
				backstories and followers, they can{" "}
				<White>
					set new trends and fire up challenges on any social
					platform.
				</White>{" "}
				The possibilities of content creation are endless. Why not
				creating your own mini series with your digital friends or
				invent a whole brand universe?
			</Desc>
			<CustomImage
				customType="image"
				src={`/img/cases/${shorthand}/image/img_05.jpg`}
			/>
		</ArtileEl>

		<ArtileEl type={"text-left-onethird"}>
			<Header customType="header">Pretty, simple.</Header>
			<Desc customType="desc">
				<White>Our process is simplified to the maximum.</White> Detect
				a trend on TikTok or any other social platform. Let us know and
				a few hours later, we’ll have an actor ready to shoot, and your
				virtual influencer will enter the social stage in the perfect
				moment.
			</Desc>
			<CustomImage
				customType="image"
				src={`/img/cases/${shorthand}/image/img_03.png`}
			/>
		</ArtileEl>
	</>
);
