import React from "react";

import ArtileEl, {
	Pink,
	White,
	Desc,
	Header,
	CustomImage,
} from "../../components/Elements/ArticleEl";

import cases from "../cases";

const componentName = "Audio2Face";

const shorthand = cases.find((x) => x.component == componentName).shorthand;

if (typeof shorthand == "undefined")
	console.error("Component can't find shorthand: " + componentName);
export default () => (
	<>
		<div className="section-div">
			<div className="section-content">
				<div className="section-hero">
					<div
						className="section-title-sub grad-txt"
						style={{ paddingLeft: 0, paddingRight: 0 }}
					>
						"Audio-to-Face technology is a cost-effective and
						efficient alternative to traditional facial performance
						capture."
					</div>
				</div>
			</div>
		</div>

		<ArtileEl type={"text-left-onethird"}>
			<Header customType="header">Real-time facial animation.</Header>
			<Desc customType="desc">
				By using deep neural networks, Audio-to-Face allows you to bring
				characters to life quickly and easily.{" "}
				<White>
					Record an audio track or use a live microphone to generate
					3D face animations
				</White>
				. We use various toolkits and AI algorithms to ensure a perfect
				match between the audio and the animation.
			</Desc>
			<CustomImage
				customType="image"
				src={`/img/cases/${shorthand}/image/img_01.png`}
			/>
		</ArtileEl>

		<ArtileEl type={"text-right-half"}>
			<Header customType="header">Synthesize Emotions.</Header>
			<Desc customType="desc">
				<White>
					Cutting-edge machine learning turns speech into facial
					animations
				</White>{" "}
				by analyzing speech patterns and transforming them into a series
				of keyframes, which represent the facial movements and
				expressions. These keyframes are then refined to create a final
				animation that captures the speaker's emotions and expressions.
			</Desc>
			<CustomImage
				customType="image"
				src={`/img/cases/${shorthand}/image/img_02.png`}
			/>
		</ArtileEl>
		<ArtileEl type={"text-left-half"}>
			<Header customType="header">Babel.</Header>
			<Desc customType="desc">
				It's possible to processes any language, making it a versatile
				tool for all. Whether you're working on a project that involves
				multilingual characters or need to quickly generate facial
				animations{" "}
				<White>
					for a variety of languages, this technology has got you
					covered
				</White>
				. An ideal choice for a wide range of applications, from video
				game development to animated film production
			</Desc>
			<CustomImage
				customType="image"
				src={`/img/cases/${shorthand}/image/img_03.png`}
			/>
		</ArtileEl>
		<ArtileEl type={"text-right-half"}>
			<Header customType="header">Built to scale.</Header>
			<Desc customType="desc">
				With the help of Audio-to-Face, we can bring entire scenes to
				life. Run multiple instances with as many characters as you
				want, each animated from a different audio track or all from
				one. Whether it's{" "}
				<White>
					a two-person dialogue, a musical showdown between a trio, or
					a synchronized mass performance, we bring your characters to
					life
				</White>{" "}
				with ease. And... action.
			</Desc>
			<CustomImage
				customType="image"
				src={`/img/cases/${shorthand}/image/img_04.png`}
			/>
		</ArtileEl>
	</>
);
