import React, {
	useImperativeHandle,
	useLayoutEffect,
	useEffect,
	useRef,
	useState,
} from "react";
import { gsap } from "gsap";

import waveform from "../../../img/waveform.png";
import waveformBaseline from "../../../img/waveform-baseline.png";

const CompShotTimeline = React.forwardRef((props, ref) => {
	const complicationsRef = useRef(null);
	const animationTimeline = useRef(null);

	const shotTrack = useRef(null);
	const musicTrack = useRef(null);

	const seek = (e) => {
		const seekTime = e?.detail?.playSecond;

		if (seekTime) {
			animationTimeline.current?.seek?.(seekTime);
			animationTimeline.current?.play?.();
		}
	};
	const restart = () => {
		animationTimeline.current?.restart?.();
	};

	const pause = () => {
		animationTimeline.current?.pause?.();
	};
	useLayoutEffect(() => {
		const parent = complicationsRef.current?.parentNode || null;

		if (parent) {
			parent?.addEventListener("onRestart", restart);
			parent?.addEventListener("onSeek", seek);
			parent?.addEventListener("onPause", pause);
		}
		return () => {
			parent?.removeEventListener("onRestart", restart);
			parent?.removeEventListener("onSeek", seek);
			parent?.removeEventListener("onPause", pause);
		};
	}, [complicationsRef.current]);

	//reframe Function
	const reframe = () => {
		gsap.set(complicationsRef.current, {
			x: props.offsetX || 0,
			y: props.offsetY || 0,
			scale: props.isSmallScreen ? 0.75 : 1,
		});
	};
	useEffect(() => {
		reframe();
	}, [props.offsetX, props.offsetY, props.isSmallScreen]);

	useEffect(() => {
		if (props.duration) {
			animationTimeline.current = gsap.timeline({ repeat: -1 });
			animationTimeline.current.add("start");

			animationTimeline.current.add(
				gsap.fromTo(
					[musicTrack.current, shotTrack.current],
					{ xPercent: 15 },
					{ xPercent: -5, duration: props.duration, ease: "none" }
				),
				"<"
			);
		}
		return () => {
			if (animationTimeline.current) {
				animationTimeline.current.getChildren().forEach((x) => {
					x.kill();
				});
			}
		};
	}, [props.duration]);

	const parentId = complicationsRef.current?.parentNode.id;
	return (
		<div
			className={`complication-el ${
				props.transparent ? " transparent" : ""
			}${props.align ? " align-" + props.align : ""}`}
			ref={complicationsRef}
		>
			<div className="complication-title-cnt">
				<div
					className={`complication-logo${
						props.secondary ? " loop" : " loop"
					}`}
				/>
				<div
					className={`complication-title${
						props.secondary ? " purple" : " pink"
					}`}
				>
					{props.title || "N/A"}
				</div>
			</div>
			<div className="compilation-row">
				<div className="complication-title">{props.track || "N/A"}</div>
			</div>

			<div className="compilation-row timeline">
				<div
					className="track-cnt"
					style={{ transform: "translateZ(0)" }}
				>
					<div className="comp-shot-track" ref={shotTrack}>
						<div className="comp-shot">
							{props.takeOneTxt || "shot_010_v01"}
						</div>
						<div className="comp-shot active">
							{props.takeTwoTxt || "sh_020_v10_final_2"}
						</div>
						<div className="comp-shot">
							{props.takeThreeTxt || "sh_030_v05"}
						</div>
					</div>
				</div>
				<div
					className="track-cnt"
					style={{ transform: "translateZ(0)" }}
				>
					<div className="comp-music-track" ref={musicTrack}>
						<div className="comp-shot active background" />
					</div>
				</div>
				<div className="comp-timeline-indicator" />
			</div>
		</div>
	);
});

export default CompShotTimeline;
